// Spanish language package
export const locale = {
  greet: ({ named }) => `Merhaba <span class="font-weight-bold">${named("userName")}!</span>`,
  spanish: "Spanish",
  german: "Germany",
  turkish: "Turkçe",
  english: "İngilizce",
  true: "Sí",
  false: "No",
  date: "Fecha",
  time: "Hora",
  dynamic: "Dinámico",
  static: "Estático",
  hi: "Hola",
  create: "Crear",
  update: "Actualizar",
  change: "Cambiar",
  template: "Plantilla",
  qr_list: "Lista de QR",
  QR_TITLE: "Título del Código QR",
  qr_code_list: "Lista de Códigos QR",
  qrcode_document: "Documento",
  qrcode_document_subtext: "Comparte tus documentos",
  qrcode_website: "Sitio web (Enlace)",
  qrcode_website_subtext: "Redirige tus enlaces",
  qrcode_vcard: "vCard Plus",
  qrcode_vcard_subtext: "Crea una v-card con información de contacto",
  qrcode_smsdynamic: "sms",
  qrcode_smsdynamic_subtext: "Crea un generador de mensajes SMS",
  qrcode_sms: "sms",
  qrcode_sms_subtext: "Crea un mensaje SMS",
  qrcode_business: "Tarjeta de Empresa",
  qrcode_business_subtext: "Crea una página corporativa",
  qrcode_socialmedia: "Redes sociales",
  qrcode_socialmedia_subtext: "Redirige a tus cuentas de redes sociales",
  qrcode_pdf: "PDF",
  qrcode_pdf_subtext: "Crea un visor de PDF",
  qrcode_restaurant: "Menú de Restaurante",
  qrcode_restaurant_subtext: "Crea un menú avanzado para tu restaurante",
  qrcode_pdfmenu: "Catálogo / Menú PDF",
  qrcode_pdfmenu_subtext: "Crea un catálogo o menú subiendo múltiples PDFs",
  qrcode_catalog: "Catálogo / Menú Online",
  qrcode_catalog_subtext: "Crea un catálogo a partir de tu PDF seleccionado",
  qrcode_equipment: "Tarjeta de Equipo",
  qrcode_equipment_subtext: "Sigue el estado de tu equipo",
  qrcode_staffcard: "Tarjeta de Empleado",
  qrcode_staffcard_subtext: "Crea tarjetas para tu personal",
  qrcode_emergency: "Emergencia",
  qrcode_emergency_subtext: "Tarjeta de información para emergencias",
  qrcode_event: "Evento",
  qrcode_event_subtext: "Invitaciones para conciertos, ferias, etc.",
  qrcode_location: "Ubicación",
  qrcode_location_subtext: "Crea rutas a las direcciones de tu evento",
  qrcode_music: "Música",
  qrcode_music_subtext: "Sube y reproduce una canción o grabación",
  qrcode_pet: "Mascota",
  qrcode_pet_subtext: "Crea una tarjeta de seguimiento para tu mascota",
  qrcode_museum: "Museo",
  qrcode_museum_subtext: "Crea una tarjeta informativa para lugares históricos",
  qrcode_coupon: "Cupón",
  qrcode_coupon_subtext: "Crea cupones para tus promociones",
  qrcode_rate: "Valoración",
  qrcode_rate_subtext: "Permite que los usuarios puntúen y dejen comentarios",
  qrcode_store: "Aplicaciones",
  qrcode_store_subtext: "Crea enlaces para tus aplicaciones en Apple Store o Google Play",
  qrcode_call: "Llamada",
  qrcode_call_subtext: "Crea una tarjeta para hacer llamadas telefónicas",
  qrcode_text: "Texto",
  qrcode_text_subtext: "Comparte un texto que se puede copiar",
  qrcode_mail: "Correo electrónico",
  qrcode_mail_subtext: "Crea una tarjeta de contacto por correo electrónico",
  qrcode_whatsapp: "Whatsapp",
  qrcode_whatsapp_subtext: "Crea un enlace de contacto de Whatsapp",
  qrcode_instagram: "Instagram",
  qrcode_instagram_subtext: "Crea un enlace de contacto de Instagram",
  qrcode_facebook: "Facebook",
  qrcode_facebook_subtext: "Crea un enlace de contacto de Facebook",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "Crea un enlace de contacto de X / Twitter",
  qrcode_linkedin: "LinkedIn",
  qrcode_linkedin_subtext: "Crea un enlace de contacto de LinkedIn",
  qrcode_youtube: "YouTube",
  qrcode_youtube_subtext: "Comparte un video de YouTube",
  qrcode_pinterest: "Pinterest",
  qrcode_pinterest_subtext: "Crea un enlace de contacto de Pinterest",
  qrcode_paypal: "Paypal",
  qrcode_paypal_subtext: "Crea un enlace de pago o donación de Paypal",
  qrcode_wifidynamic: "WiFi",
  qrcode_wifidynamic_subtext: "Comparte la información de tu conexión WiFi",
  qrcode_websitestatic: "Sitio web (estático)",
  qrcode_websitestatic_subtext: "Código QR estático con la URL de tu sitio web",
  qrcode_vcardstatic: "vCard (estático)",
  qrcode_vcardstatic_subtext: "Código QR estático para guardar como .vcf en el teléfono",
  qrcode_phone: "Teléfono",
  qrcode_phonestatic: "Teléfono (estático)",
  qrcode_phone_subtext: "Número de teléfono",
  qrcode_textstatic: "Texto (estático)",
  qrcode_textstatic_subtext: "Texto estático",
  qrcode_smsstatic: "SMS (estático)",
  qrcode_smsstatic_subtext: "SMS estático",
  qrcode_wifistatic: "WiFi (estático)",
  qrcode_wifistatic_subtext: "WiFi estático",
  qrcode_multiplelink: "Varios enlaces",
  qrcode_multiplelink_subtext: "Agrega múltiples enlaces con redirección aleatoria",
  qrcode_gallery: "Galería de Imágenes",
  qrcode_gallery_subtext: "Galería de Imágenes",
  qrcode_customerservice: "Contacto de Departamento",
  qrcode_customerservice_subtext: "Crea enlaces de contacto para departamentos",
  qrcode_title_wifi: "WiFi",
  qrcode_title_vcard: "vCard Plus",
  qrcode_title_sms: "SMS",
  qrcode_title_gallery: "Galería",
  qrcode_title_multiplelink: "Varios enlaces",
  qrcode_title_music: "Música",
  qrcode_title_website: "Sitio web",
  qrcode_title_whatsapp: "Whatsapp",
  qrcode_title_email: "Correo electrónico",
  qrcode_title_pdfmenu: "Catálogo / Menú PDF",
  qrcode_title_catalog: "Catálogo / Menú Online",
  qrcode_title_text: "Texto",
  qrcode_title_coupon: "Cupón",
  qrcode_title_pinterest: "Pinterest",
  qrcode_title_instagram: "Instagram",
  qrcode_title_twitter: "Twitter",
  qrcode_title_rate: "Valoración",
  qrcode_title_business: "Tarjeta de Empresa",
  qrcode_title_phone: "Teléfono",
  qrcode_title_staffcard: "Tarjeta de Empleado",
  qrcode_title_paypal: "Paypal",
  qrcode_title_store: "Aplicaciones",
  qrcode_title_call: "Llamada",
  qrcode_title_location: "Ubicación",
  qrcode_title_pdf: "PDF",
  qrcode_title_museum: "Museo",
  qrcode_title_linkedin: "LinkedIn",
  qrcode_title_youtube: "YouTube",
  qrcode_title_pet: "Mascota",
  qrcode_title_facebook: "Facebook",
  qrcode_title_event: "Evento",
  qrcode_title_equipment: "Tarjeta de Equipo",
  qrcode_title_emergency: "Tarjeta de Emergencia",
  qrcode_title_restaurant: "Restaurante",
  qrcode_title_socialmedia: "Redes Sociales",
  qrcode_all: "- Mostrar todo -",
  page_not_found_text: "¡Vaya! No se encontró el contenido que buscabas",
  go_home: "Inicio",
  link_is_copied_to_clipboard: "¡Enlace copiado al portapapeles!",
  select_pdf_by_clicking: "Haz clic en el archivo PDF para activarlo",
  select_content_by_clicking: "Haz clic en el archivo para activarlo",
  clear_all_fields: "Limpiar el formulario",
  search_type: "Selecciona el tipo de tarjeta",
  create_quickly: "Crear Rápidamente",
  empty: "Vacío",
  click_lock_to_unlock: "Haz clic en el ícono de candado para desbloquear este campo",
  user_folder: "Carpeta de usuario",
  this_field_is_required: "Este campo es obligatorio",
  you_dont_have_any_saved_design: "Guarda el diseño para usarlo en futuros códigos QR",
  you_can_save_your_current_design_by_clicking_button_below:
    "Puedes guardar este diseño y aplicarlo fácilmente en futuros QR sin seleccionar colores o formas",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature:
    "Debes guardar este código QR para poder usar esta función",
  SAVE_CURRENT_DESIGN: " + GUARDAR DISEÑO ACTUAL",
  you_have_to_update_current_changes_first: "Primero debes actualizar los cambios actuales",
  scan_result_good: "Lectura buena",
  scan_result_bad: "Lectura débil",
  LANGUAGE: "Seleccionar idioma",
  YES: "SÍ",
  ACTIVE_LANGUAGE: "Idioma activo",
  ADD_SOME_INFO: "Ingresa información",
  CONTACT_INFO: "Información de contacto",
  FULL_NAME: "Nombre completo",
  FIRSTNAME: "Nombre",
  LASTNAME: "Apellido",
  COMPANY_NAME: "Nombre de la empresa",
  COMPANY_ADDRESS: "Dirección de la empresa",
  JOBTITLE: "Título Profesional",
  DEPARTMENT: "Departamento",
  BLOOD_GROUP: "Grupo sanguíneo",
  FOLDER_NO: "Número de carpeta",
  DATE_OF_START: "Fecha de inicio",
  COMPANY: "Compañía",
  COUNTRY: "País",
  STATE: "Estado",
  MAIL: "Correo electrónico",
  WEBSITE: "Sitio web",
  PHONE: "Teléfono",
  SMS: "SMS",
  HOME: "Inicio",
  WHO_ARE_WE: "¿Quiénes somos?",
  icon: "Ícono",
  show_more: "Mostrar más",
  remove_this_song: "Eliminar esta canción",
  select_cover_image: "Seleccionar imagen de portada",
  select_audio_file: "Seleccionar archivo de audio",
  delete_all: "Eliminar todo",
  spotify_settings: "Configuraciones de Spotify",
  light_theme: "Tema claro",
  dark_theme: "Tema oscuro",
  you_can_paste_single_song_or_playlist:
    "Puedes pegar una sola canción o lista de reproducción. El enlace debe verse así:",
  add_songs_from_local_files: "Agregar canciones desde archivos locales",
  add_songs_from_spotify: "Agregar canciones desde Spotify",
  SETTINGS: "Configuraciones",
  APPLY: "Aplicar",

  crops_image: "Transferir imagen al diseño seleccionado",
  rotate_right: "Girar a la derecha",
  rotate_left: "Girar a la izquierda",
  upload_image: "Subir imagen desde tu computadora",
  reset: "Restablecer formulario",
  upload: "Subir desde PC",
  transfer: "Mostrar imagen",

  adress_icon: "Ícono de dirección",
  area_icon: "Ícono de área",

  awaiting: "Esperando",
  approved: "Aprobado",
  rejected: "Rechazado",
  download_excel: "Descargar Excel",
  excel_error: "Ocurrió un error al descargar el archivo Excel.",

  card_owner: "Propietario de la tarjeta",
  expires_at: "Fecha de expiración",
  mounth: "MES",
  years: "AÑOS",
  full_name: "Nombre completo",

  type_city_name: "Ingresa ciudad y distrito",
  your_zip_code: "Código postal",
  id_number: "Número de identidad (No obligatorio)",
  tax_number: "Número de impuesto",
  tax_office: "Oficina de impuestos",

  qr_code_type: "Puedes cambiar el tipo de código QR. (¡Importante!)",

  static_qr_code_why_delete:
    "Después de que termine su paquete, los códigos QR que creó se almacenarán solo durante 1 día. Después de eso, todos los datos relacionados con este código QR se eliminarán. Si desea imprimir el código QR que creó, debe descargarlo y guardarlo antes de que se elimine. Si desea mantener sus códigos QR estáticos, puede actualizar su paquete.",
  static_qr_code_why_title: "¿Por qué se eliminan mis códigos QR estáticos?",

  WHO_ARE_WE_1: `Nuestra empresa de tecnología de software, completamente equipada, fue fundada en 1997 con la idea de que "hay mucho trabajo por hacer, muchos códigos por escribir". Nuestro fundador soñó con una empresa de software que ofreciera servicios a sus clientes, brindando valores reales y resultados medibles. Hoy, continuamos brindando servicio, habiendo logrado mucho más de lo que imaginábamos.`,

  WHO_ARE_WE_2: `Hemos recorrido un largo camino hasta ahora. A lo largo de los años, hemos abierto nuestras puertas a genios del software talentosos y nos hemos fortalecido juntos. Combinamos la información que hemos acumulado con la tecnología actual, y seguimos adelante con esta travesía gracias a lo que hemos ganado haciendo lo que amamos.`,

  WHO_ARE_WE_3: `Con nuestro conocimiento y experiencia en la industria, abordamos los procesos de manera integral y potenciamos tu poder digital con tecnologías de software sostenibles. Con nuestros servicios de Gestión de Marca, Diseño y Desarrollo Web, Estrategias de Marketing Digital, Diseño de Redes Sociales, SEM, SEO, Sistemas de Comercio Electrónico y Proyectos de Software Personalizados, nos encargamos de todo el proceso de construcción de marca, desde la producción hasta las ventas, desde las finanzas hasta el marketing, desde I+D hasta compras.`,

  WHO_ARE_WE_4: `Más allá de los servicios que ofrecemos, prestamos el mismo nivel de atención y cuidado a cada proyecto, sin importar su tamaño o valor. Nuestra reputación se ha construido sobre clientes satisfechos que hemos visto crecer sus negocios gracias a los proyectos que hemos completado.`,

  WHO_ARE_WE_5: `Sabemos que el primer paso en cualquier nuevo proyecto es escuchar. Entendemos perfectamente lo que necesitas, los problemas que enfrentas y tus planes a futuro. Es así como diseñamos estrategias completas y efectivas.`,

  WHO_ARE_WE_6: `Con nuestra experiencia de muchos años, sabemos que podemos ayudarte, sin importar la escala de tu proyecto o el tamaño de tu empresa. Nuestros principios guía son el desarrollo continuo, la empatía, la atención a los detalles, la honestidad y la motivación. La razón por la que nuestros clientes siguen con nosotros desde que empezamos es esta.`,

  WHO_ARE_WE_7: `¡Pero eso es todo de nuestra parte! Ahora queremos escuchar sobre tus sueños, objetivos, proyectos y lo que quieres lograr. Si quieres hablar con nosotros sobre tu negocio en línea, todo nuestro equipo está esperando y estamos llenos de ideas geniales. Nos encanta la comunicación digital, pero también disfrutamos de las conversaciones telefónicas. Puedes enviarnos un correo a info@medyax.com.`,

  PHONEHOME: "Teléfono de Casa",
  PHONEMOBILE: "Teléfono Móvil",
  PHONEOFFICE: "Teléfono de Oficina",
  PHONEHOMECOUNTRYCODE: "Código de País del Teléfono de Casa",
  PHONEMOBILECOUNTRYCODE: "Código de País del Teléfono Móvil",
  PHONEOFFICECOUNTRYCODE: "Código de País del Teléfono de Oficina",
  ABOUT: "Acerca de",
  ABOUTUS: "Sobre Nosotros",
  TEAM: "Equipo",
  CONTACT: "Contacto",
  PREVIEW: "Vista Previa del Código QR",
  SUPPORT: "Soporte",
  ADDRESS: "Dirección",
  ENTER_LOCATION: "+ Por favor ingrese su dirección (Puede agregar múltiples direcciones)",
  ENTER_LOCATION_HELP:
    "Por favor ingrese su dirección a continuación. Luego, seleccione su dirección en la ventana emergente. Después, puede señalar su ubicación más detallada moviendo el ícono rojo.",
  PLEASE_CHOOSE_LOCATION: "Por favor elija una ubicación primero",
  MANAGER_FULL_NAME: "Nombre del Gerente",
  MANAGER_PHONE: "Teléfono del Gerente",
  MANAGER_JOBTITLE: "Puesto del Gerente",
  MANAGER_MAIL: "Correo del Gerente",
  ART_NAME: "Nombre de la Obra",
  ART_ACCESSION_NUMBER: "Número de Acceso",
  ART_ERA: "Era",
  ART_MEASUREMENT: "Medidas",
  ART_DISCOVERY_LOCATION: "Ubicación del Descubrimiento",
  ART_ACCESSION_DATE: "Fecha de Acceso",
  ART_DESCRIPTION: "Descripción de la Obra",
  RESTAURANT_ABOUT_US: "",
  RESTAURANT_ADDRESS: "",
  RESTAURANT_PHONE: "",
  RESTAURANT_EMAIL: "",
  NAME: "Nombre Completo",
  BLOODGROUP: "Grupo Sanguíneo",
  MEDICATION: "Medicamentos Utilizados",
  PLATENUMBER: "Número de Placa",
  ALLERGICTO: "Alergia",
  DESCRIPTION: "Descripción",
  MEDICALRECORD: "Expediente Médico",
  NUMBER: "Número",
  SERIALNUMBER: "Número de Serie",
  NEXTMAINTENANCE: "Próxima Fecha de Mantenimiento",
  LASTMAINTENANCE: "Última Fecha de Mantenimiento",
  REPAIRCOMPANY: "Compañía de Reparación/Calibración",
  LOCATION: "Ubicación",
  SHOWREMAINING: "Mostrar los días restantes",
  FREQUENCY: "Frecuencia de Mantenimiento/Calibración",
  STARTHOUR: "Hora de Inicio",
  ENDHOUR: "Hora de Fin",
  ENDDATE: "Fecha de Fin",
  STARTDATE: "Fecha de Inicio",
  TITLE: "Título",
  TITLE1: "Primer Título",
  TITLE2: "Segundo Título",
  SUBTITLE: "Subtítulo",
  EMAIL: "Correo Electrónico",
  YOUTUBE: "Enlace de Youtube",
  GMAP: "Google Maps",
  URL: "Enlace",
  TEXT2: "Segundo Texto",
  TEXT1: "Texto",
  TEXT: "Texto",
  BUTTONTEXT: "Texto del Botón",
  NOPERIOD: "Sin Periodo",
  LAST_30_DAYS: "Últimos 30 Días",
  LAST_90_DAYS: "Últimos 90 Días",
  LAST_180_DAYS: "Últimos 180 Días",
  SELECTED_TIME: "Todo el Tiempo",
  OWNER: "Propietario",
  VETADDRESS: "Dirección del Veterinario",
  VETPHONE: "Teléfono del Veterinario",
  VETEMAIL: "Correo del Veterinario",
  VETNAME: "Nombre del Veterinario",
  GOOGLELINK: "Enlace de Google",
  VIDEOLIST: "Lista de Videos de Youtube",
  IOSLINK: "Enlace de App Store",
  HUAWEILINK: "Enlace de Huawei Gallery",
  COUPONDISCOUNT: "Descuento de Cupón",
  COUPONTITLE: "Título del Cupón",
  COUPONCODE: "Código del Cupón",
  PRODUCT: "Producto",
  WIFINAME: "Nombre de Wifi",
  CONTENTLIST: "Lista de Contenidos",
  CONTENTIMAGES: "Imágenes de Contenido",
  PASSWORD: "Contraseña",
  USER: "Usuarios",
  BTNTEXT: "Texto del Botón",
  TEXT: "Texto",
  FILE: "Archivo",
  ["AD SOYAD"]: "Nombre Completo",
  BÖLÜMÜ: "Departamento",
  GÖREVI: "Puesto",
  TELEFONU: "Teléfono",
  ["KAN GRUBU"]: "Grupo Sanguíneo",
  ["İŞE GIRIŞ TARIHI"]: "Fecha de Ingreso",
  ["SICIL NO"]: "Número de Registro",
  RESPONSIBLESTAFFPHONE: "Teléfono del Personal Responsable",
  CITY: "Ciudad y Municipio",
  FAX: "Fax",
  ZIP: "Código Postal",
  ZIPCODE: "Código Postal",
  FAXCOUNTRYCODE: "Código de País del Fax",
  MESSAGE: "Mensaje",
  SSID: "SSID",
  ISHIDDEN: "Oculto",
  NETWORKTYPE: "Tipo de Red",
  PW: "Contraseña",
  SECURITY: "Seguridad",
  TOPICON: "Ícono Superior",
  MIDDLEICON: "Ícono Medio",
  GOOGLE: "Enlace de Google Play",
  APPLE: "Enlace de App Store",
  HUAWEI: "Enlace de Huawei Gallery",
  COUNTRYCODE: "Código de País",
  ISWIFIHIDDEN: "Wifi Visible",
  NEW: "¡Nuevo!",
  bulk_upload: "Carga Masiva",
  NO_TITLE: "Sin Título",
  ACTIVE: "Activo",
  active: "Activo",
  PASSIVE: "Pasivo",
  passive: "Pasivo",
  status: "Estado",
  UNIQUE_SCAN: "Escaneo Único",
  DOWNLOAD: "Descargar",
  SCAN: "Escaneo Total",
  PRINT: "Imprimir",
  VIEW: "Ver",
  ISTATISTICS: "Activando estadísticas podrás obtener información detallada.",
  DELETE: "Eliminar",
  ACTIVE: "Puedes activar o desactivar el Código QR en cualquier momento.",
  RENAME: "Renombrar",
  SEE_PACKAGE: "Ver Paquete",
  CANCEL: "Cancelar",
  LIVE: "En Vivo",
  OK: "Aceptar",
  ARE_YOU_SURE: "¿Estás seguro?",
  ERROR: "Error",
  ATTENTION: "¡Atención!",
  SUCCESS: "¡Operación Exitosa!",
  DOWNLOAD_ERROR: "Hubo un error al descargar",
  CLOSE: "Cerrar",
  SORT_BY: "Ordenar por",
  MOST_RECENT: "Más Reciente",
  LEAST_RECENT: "Menos Reciente",
  RECENTLY_UPDATE: "Última Actualización",
  LEAST_SCANNED: "Menos Escaneado",
  MOST_SCANNED: "Más Escaneado",
  SEARCH: "Buscar",
  UPLOAD: "Subir Archivo",
  BACK: "Regresar",
  NEW_FOLDER: "Nueva Carpeta",
  IMAGE_QUALITY: "Calidad de Lectura del Código QR",
  LOW: "Baja",
  MEDIUM: "Media",
  GOOD: "Buena",
  HIGH: "Alta",
  BACK_TO_DEFAULTS: "Volver al Código QR Clásico",
  SAVE_QR_CODE: "Guardar Código QR",
  SAVE: "Guardar",
  RESET: "Restablecer",
  SHORT: "Corto",
  URL: "Enlace",
  SEND: "Enviar",
  SCAN_QR: "Probar Escaneo",
  SAVED_DESIGNS: "Guardar como Plantilla",
  LOGOS: "Logos",
  SHOW_ALL: "Ver Todos",
  ANIMATION: "Animado",
  STATIC: "Estático",
  EYE_BALL: "Referencia Interna",
  BODY: "Formas de Cuerpo",
  EYE_FRAME: "Referencia Externa",
  FRAME: "Marco",
  TEMPLATES: "Plantillas de Código QR",
  ADVENCED_LOGO_SETTING: "Configuración Avanzada del Logo",
  GO_BACK: "Volver",
  SELECT: "Seleccionar",
  SINGLE_COLOR: "Color Único",
  FIRST_COLOR: "Primer Color",
  SECOND_COLOR: "Segundo Color",
  BG_COLOR: "Color de Fondo",
  FRONT_COLOR: "Color Frontal",
  BACK_COLOR: "Color Posterior",
  CUSTOMIZE_INDIVIDUALLY: "Personalizar Individualmente",
  SINGLE_COLOR: "Color Único",
  CHANGE_REFERANCE_POINT: "Cambiar Puntos de Referencia",
  NEED_HELP: "¿Necesitas ayuda?",
  FRAME_SETTINGS: "Configuración de Marco",
  DEFAULT_LANGUAGE: "Idioma Predeterminado",
  DELETE_ALL: "Eliminar Todos",
  ACTIVATE_ALL: "Activar Todos",
  DE_ACTIVATE_ALL: "Desactivar Todos",
  OPTION: "Opciones",
  DISAGREE: "Permanecer en la Página",
  AGREE: "Aceptar",
  FIRSTSECTIONTITLE: "Título del Campo",

  field_name: "Nombre del Campo",
  field_value: "Valor del Campo",
  field_type: "Tipo de Campo",
  DYNAMIC_FIELDS: "Campos Dinámicos",
  DYNAMIC_FIELDS_DESC:
    "Su campo dinámico está vacío. Por favor, haga clic en el siguiente botón para agregar un nuevo campo.",

  you_have_unsaved_changes: "Tienes cambios no guardados",
  you_have_unsaved_changes_desc: "¿Aún quieres abandonar esta página?",
  approve_comment: "Aprobar Comentario",
  disapprove_comment: "Desaprobar Comentario",
  warning: "¡Advertencia!",
  add_new_languages: "Añadir nuevo idioma",
  get_more_information_about: "Obtener más información sobre la configuración de idiomas",
  copy_content_from_description: "El contenido que elija aquí se aplicará a los nuevos idiomas añadidos",
  copy_content_from: "Idioma del cual se copiará el contenido",
  you_are_about_to_delete_the_category: "Estás a punto de eliminar la categoría",
  are_you_sure_you_want_to_continue:
    "Si continúas, perderás los datos de esta categoría junto con el PDF seleccionado. ¿Estás seguro de que quieres continuar?",
  you_at_least_need_to_have_1_category: "Debes tener al menos 1 categoría",
  dark_mode: "Modo Oscuro",
  pdf_is_processing: "Cargando PDF...",
  image: "Imagen",
  browse_my_media: "Explorar mis medios",
  my_media: "Mis Medios",
  used: " Usado",
  my_media_subtext: "Gestiona tus medios",
  my_domains: "Mis dominios",
  my_domains_subtext: "Gestiona tus dominios",
  default_domain: "DOMINIO POR DEFECTO",
  not_verify_domain: "DOMINIO NO VERIFICADO",
  verify_domain: "VERIFICAR DOMINIO",
  make_deafult_domain: "ESTABLECER COMO POR DEFECTO",
  default_status: "Estado por defecto",
  domain_add: "AÑADIR DOMINIO",
  animation: "Animación",
  background_color: "Color de fondo",
  choose_design: "Seleccionar diseño",
  select_from_my_media: "Seleccionar imagen / logo de mis medios",
  select_from_animation: "Seleccionar de animaciones",
  age_restriction:
    "Para tu aplicación, puedes aplicar restricciones de edad para garantizar la seguridad y la experiencia de los usuarios. Los usuarios deberán verificar su edad para acceder a nuestra aplicación. Esto significa que nuestra aplicación ofrecerá contenido específico para ciertos grupos de edad y limitará las características para adultos.",
  age_restriction_text: "Restricción de Edad",
  age_restriction_tooltip: "Advertencia para usuarios menores de cierta edad",
  analytics: "Análisis",
  expiration_date: "Fecha de expiración",
  device: "Dispositivo",
  personal_info: "Información personal",
  preparing_template: "",
  RESERVATIONEMAIL: "Correo electrónico de reserva",
  locked: "Bloqueado",
  RESTORE: "Restaurar",
  current_password: "Contraseña actual",
  new_password: "Nueva contraseña",
  verify_password: "Verificar contraseña",
  time_limit: "Límite de tiempo",
  pdf_is_processing: "Procesando PDF",
  field_required: "Este campo es obligatorio",
  start_typing: "Comienza a escribir...",
  processing_pdf: "Procesando archivo PDF",
  general_settings: "Configuración general",
  REMINDER: "Recordatorio",
  WEBURL: "Sitio web",
  NONE: "Ninguno",
  MINUTES: "minutos restantes",
  HOURS: "horas restantes",
  DAYS: "días restantes",
  WEEKS: "semanas restantes",
  COMPLETE: "Completar",
  type_your_first_name: "Escribe tu nombre",
  type_your_last_name: "Escribe tu apellido",
  type_your_address: "Escribe tu dirección",
  your_company_info: "Información de tu empresa",
  order_summary: "Resumen de pedido",
  order_desc: "Es un resumen de la información del paquete seleccionado.",
  annual: "Anual",
  subtotal: "Subtotal",
  tax: "Impuesto",
  coupon_discount: "Descuento del cupón",
  total: "Total",
  package_price: "Precio del paquete",
  package_discount: "Descuento del paquete",
  type_your_discount_code: "Introduce tu código de descuento",
  coupon_code: "Código de descuento",
  use_coupon_code: "Usar código",
  cancel_coupon: "Cancelar código",
  i_agree_to: "He leído y acepto los términos del contrato",
  go_back: "VOLVER",
  month: "Mes",
  year: "Año",
  day: "Día",
  creditcard_number: "Número de tarjeta",
  creditcard_name: "Titular de la tarjeta",
  creditcard_expiration_date: "Fecha de expiración",
  creditcard_cvv: "CVC",
  terms_of_use: "Términos de uso",
  current_plan: "Plan actual",
  active_package: "Paquete activo",
  started_at: "Comenzado en",
  ends_at: "Termina en",
  remaining_time: "Tiempo restante",
  package_content: "Contenido del paquete",
  newspaper: "Periódico",
  campaign_start: "Inicio de campaña",
  campaign: "Información de impresión",
  campaign_end: "Fin de campaña",
  start_date: "Fecha de inicio",
  end_date: "Fecha de fin",
  are_you_sure: "¿Estás seguro?",
  campaign_info: "Información de la campaña",
  print_style: "Estilo de impresión",
  print_amount: "Cantidad de impresiones",
  advanced_statistics: "Estadísticas avanzadas",
  advanced_statistics_desc: "Las estadísticas avanzadas no están activadas. ¿Te gustaría activarlas?",
  change_range: "Filtrar",
  select_start_and_end_dates: "Selecciona las fechas de inicio y fin",
  language_settings: "Configuración de idioma",
  pdf_info:
    "Cuando esta opción esté activada, los usuarios no verán ninguna página y serán redirigidos al archivo PDF.",
  pdf_download: "Debes cargar el archivo PDF para que esté activo.",
  general_settings_link: "Haz clic aquí para obtener más información.",
  email_address_to_be_sent: "Dirección de correo electrónico para recibir el formulario de contacto",
  general_settings_desc:
    "El título del código QR solo será visible para ti, para diferenciarlo de otros códigos QR que poseas. Los usuarios no lo verán y cambiarlo no afectará tu página.",
  submit: "Enviar",
  invalid_email: "Correo electrónico inválido",
  new_qr_code: "Crear nuevo código QR",
  days_remaining: "RESTANTES:",
  days: "DÍA",
  the_last_day_of_your_package: "Último día de tu paquete",
  full_name: "Nombre completo",
  phone: "Teléfono",
  email: "Correo electrónico",
  text: "Texto",
  link: "Enlace",
  desc_message: "Por favor escribe una descripción...",
  how_many_qrcodes_you_need: "¿Cuántos códigos QR necesitas?",
  request_custom_package: "Solicitar paquete personalizado",
  communication: "Comunicación",
  account_privacy: "Eliminar cuenta",
  deactivate_your_account: "Desactivar tu cuenta",
  default_language: "Este será tu idioma predeterminado",
  save_changes: "Guardar cambios",
  cancel: "Cancelar",
  avatar: "Foto de perfil",

  update_your_personal_information: "Actualiza tu información personal",
  allowed_file_types: "Tipos de archivos permitidos",
  change_password: "Cambiar contraseña",
  account_holder: "Titular de la cuenta",
  account_information: "Información de la cuenta",
  FIRSTBTNTEXT: "Primer Botón",
  SECONDBTNTEXT: "Segundo Botón",
  request_price: "Solicitar oferta especial",
  make_request: "SOLICITAR OFERTA",
  gender: "Género",
  male: "Masculino",
  female: "Femenino",
  cell_phone: "Teléfono móvil",
  invalid_phone: "Número de teléfono inválido",
  invalid_mail: "Dirección de correo electrónico inválida",
  invalid_website: "Dirección web inválida",
  invalid_name: "Nombre inválido",
  dinamik_qr_kod_fiyatları: "Precios de códigos QR dinámicos",
  restaurant_menü_qr_kod_fiyatları: "Precios de códigos QR para Menú de Restaurante",
  note: "Nota",
  user_profile: "Perfil de usuario",
  sign_out: "Cerrar sesión",
  signing_out: "Cerrando sesión",
  account_settings_and_more: "Configuración de la cuenta y más",
  my_profile: "Mi perfil",
  active_subscriptions: "Suscripciones activas",
  active_subscriptions_subtext: "Mis paquetes activos",
  SELECT_FROM_THE_LIST: "Seleccione el tipo de tarjeta de la lista",
  package_name: "Nombre del paquete",
  device_list: "Lista de dispositivos",
  details: "Detalles",
  detailed_chart: "Gráfico detallado",
  browser_list: "Lista de navegadores",
  city_list: "Lista de ciudades",
  country_list: "Lista de países",
  os_list: "Lista de sistemas operativos",
  age_profile: "Lista de edades",
  ip_list: "Lista de IPs",
  gender_profile: "Perfil de género",
  device_resolution_profile: "Perfil de resolución del dispositivo",
  last_day: "Último día",
  last_week: "Última semana",
  last_month: "Último mes",
  last_year: "Último año",
  page_view: "Vista de página",
  unique_visitor: "Visitante único",
  total_view: "Vista total",
  total_visit: "Visita total",
  delete_staticstics: "¿Estás seguro de que deseas eliminar los datos estadísticos?",
  avarage_duration_visitors_stay: "Duración promedio de la estancia de los visitantes",
  total_number: "Estadísticas totales",
  sorry_im_leaving: "¡Lo siento, me voy!",
  sorry_im_leaving_desc:
    "Estás a punto de eliminar tu cuenta. Si continúas, tus datos se eliminarán permanentemente y no podrás recuperarlos. ¿Estás seguro de que deseas continuar?",
  last_30_days: "Últimos 30 días",
  loading: "Cargando",
  klasor_name: "Nombre de la carpeta",
  delete_comment: "Eliminar comentario",
  are_you_sure_you_want_to_delete_this_comment: "¿Estás seguro de que quieres eliminar este comentario?",
  if_you_delete_this_comment: "Si eliminas este comentario, no podrás recuperarlo más tarde",
  CREATE: "Crear",
  Upgrade: "Actualizar",
  no_content: "Crea una 'NUEVA CARPETA' o carga un 'ARCHIVO'.",
  size: "Tamaño",
  scan: "Escanear",
  limit: "Límite",
  subject: "Asunto",
  message: "Mensaje",
  qrcode: "Código QR",
  allow_this_to_be_shared: "Permito que esto se comparta con otros usuarios",
  package_limit: "Límite del paquete",
  rows_per_page: "Filas por página",
  coupon_discount: "Descuento de cupón",
  rows_per_page: "Número de filas por página",
  your_subscription_has_expired: "Tu suscripción ha expirado",
  two_ways_to_keep_using: "Dos formas de seguir usando",
  contact_us: "Contáctanos",
  upgrade_now: "Actualizar ahora",
  or: "O",
  no_data: "No hay datos para mostrar",
  list_is_empty: "La lista está vacía",
  recaptcha_error: "Por favor, demuéstranos que no eres un robot",
  if_you_delete_this_qr_code: "Si eliminas este código QR, también perderás los datos relacionados con él.",

  delete_all_childs_alert_text: ({ named }) =>
    `Esta acción no se puede deshacer. Se eliminarán ${named(
      "amount"
    )} códigos QR bajo el maestro. Asegúrate de que deseas eliminar estos códigos QR antes de continuar.`,
  qr_codes_that_are_not_recovered:
    "Los códigos QR no recuperados dentro de los 20 días serán eliminados permanentemente.",
  changing_URL_will_create_a_new_qr_code:
    "Cambiar la URL generará un nuevo código QR y, si continúas, no podrás acceder a la versión impresa de la URL anterior.",
  attention: "¡Atención! ¡Importante!",
  valid_in_dynamic: "Válido en dinámico",
  you_can_not_add_more_than_connections: ({ named }) => `No puedes agregar más de ${named("amount")} conexiones.`,
  If_you_delete_this_language_you_will_lose_all_the:
    "Si eliminas este idioma, perderás todos los datos asociados con él. ¿Estás seguro de que deseas continuar?",
  loading_items: "Cargando datos",
  your_profile_data_has_been_successfuly_updated: "¡Los datos de tu perfil se han actualizado correctamente!",
  your_package_will_be_processed_after_your_payment_has_been_received:
    "Tu paquete será procesado después de que se haya recibido tu pago.",
  verify_password_does_not_match: "La contraseña no coincide, por favor verifícala",
  please_fill_all_fields: "Por favor, completa todos los campos",
  text_must_be_less_than_2500_characters: "El texto debe tener menos de 2500 caracteres",
  title_must_be_less_than_80_characters: "El título debe tener menos de 80 caracteres",
  must_be_less_than_80_characters: "Debe tener menos de 80 caracteres",
  must_be_less_than_200_characters: "Debe tener menos de 200 caracteres",
  must_be_less_than_100_characters: "Debe tener menos de 100 caracteres",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Puedes seleccionar una imagen desde tu PC, y mostrarla en la tarjeta haciendo clic en el botón rojo. Debes seleccionar una imagen en los ajustes de 'Medios' o desactivar la pestaña de fotos.",
  is_required: " es obligatorio",

  btnText: "Texto del Botón",
  title_is_required: "El título es obligatorio",
  this_is_required: "Esto es requerido",
  phone_is_required: "Se Requiere Número de Teléfono",
  this_url_is_invalid: "Esta URL no es válida",
  please_input_a_valid_phone_number: "Por favor ingresa un número de teléfono válido",
  you_need_to_chose_start_or_end_date: "Debes seleccionar una fecha de inicio o finalización",
  you_need_to_fill_the_required_fields: "Debes completar los campos obligatorios",
  selected_file: "Selecciona un PDF",
  no_file: "No hay archivo",
  no_link: "Sin enlace",
  add_more_button_text: "Añadir nuevo campo",
  go_to_main_page: "Volver a la página principal",
  new_address: "Nueva Dirección",
  information: "Información",
  description_for_the_address: "Descripción de la dirección",
  you_can_not_add_more_than_10_emergency_number: "No puedes agregar más de 10 números de emergencia",
  verify_password_does_not_match: "La contraseña no coincide, por favor verifícala",
  please_fill_all_fields: "Por favor, completa todos los campos",
  text_must_be_less_than_2500_characters: "El texto debe tener menos de 2500 caracteres",
  title_must_be_less_than_80_characters: "El título debe tener menos de 80 caracteres",
  must_be_less_than_200_characters: "El texto debe tener menos de 200 caracteres",
  must_be_less_than_500_characters: "El texto debe tener menos de 500 caracteres",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Puedes seleccionar una imagen desde tu PC y mostrarla en la tarjeta haciendo clic en el botón rojo. Debes seleccionar una imagen en los ajustes de 'Medios' o desactivar la pestaña de fotos.",
  is_required: " es obligatorio",
  this_is_required: "Este campo es obligatorio",
  invalid_url: "URL no válida",
  continue: "CREAR AHORA",
  direct_download:
    "Descarga directa (el código QR se descargará directamente en el teléfono del usuario después de ser escaneado)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Mostrar contador a partir de __ días",
  select_pdf_for_each_language:
    "Haz clic en el botón 'Seleccionar PDF'. Crea una nueva carpeta y sube tu archivo. Haz clic en el archivo subido y el PDF será procesado. Debes agregar un archivo PDF para cada idioma. Los siguientes idiomas no tienen PDF agregado:",
  missing_locations_for_languages:
    "¡Por favor no uses copiar y pegar para la DIRECCIÓN! Para seleccionar la DIRECCIÓN correcta, busca tu dirección escribiéndola y selecciona de las opciones del menú desplegable.",

  drag_drop: "Arrastrar para cambiar el orden",
  change_order: "Cambiar orden",
  caption: "Subtítulo",
  image: "Imagen",
  add_new: "Añadir nuevo",
  delete_selected: "Eliminar seleccionados",

  your_own: "Tu propio",
  logo: "Agregar<br>Logo",
  add: "Añadir",

  upper_left: "Arriba Izquierda",
  upper_right: "Arriba Derecha",
  lower_left: "Abajo Izquierda",

  qrcode_static_subtext: "Selecciona para crear tu código QR estático.",

  type_something_here: "Puedes escribir una descripción aquí",

  start_program: "Inicio",
  end_program: "Fin",

  billed_annually: "Facturado anualmente",
  no_folder: "Sin carpeta",

  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "QUIERO OBTENER INFORMACIÓN SOBRE LOS PRODUCTOS",

  keep_track:
    "Sigue el número de usuarios que han escaneado este código QR y compáralo con tu sitio utilizando <strong>Estadísticas Avanzadas</strong></br></br> Haz clic para ir a <strong>Estadísticas Avanzadas</strong> después de desbloquear, y utiliza el botón en la esquina superior derecha.</br></br><img src='https://i.imgur.com/fOk5J81.png' />",

  view_user_ratings: "Ver, editar, eliminar calificaciones de usuarios",

  not_a_valid_linkedin_link: "No es un enlace válido de LinkedIn",
  not_a_valid_facebook_link: "No es un enlace válido de Facebook",
  not_a_valid_twitter_link: "No es un enlace válido de Twitter",
  not_a_valid_instagram_link: "No es un enlace válido de Instagram",
  not_a_valid_whatsapp_link: "No es un enlace válido de WhatsApp",
  not_a_valid_youtube_link: "No es un enlace válido de YouTube",
  not_a_valid_reddit_link: "No es un enlace válido de Reddit",
  not_a_valid_telegram_link: "No es un enlace válido de Telegram",
  not_a_valid_snapchat_link: "No es un enlace válido de Snapchat",
  not_a_valid_pinterest_link: "No es un enlace válido de Pinterest",
  not_a_valid_yelp_link: "No es un enlace válido de Yelp",
  not_a_valid_flicker_link: "No es un enlace válido de Flickr",
  not_a_valid_vimeo_link: "No es un enlace válido de Vimeo",
  not_a_valid_vk_link: "No es un enlace válido de VK",
  not_a_valid_soundcloud_link: "No es un enlace válido de SoundCloud",
  not_a_valid_github_link: "No es un enlace válido de GitHub",
  not_a_valid_tumblr_link: "No es un enlace válido de Tumblr",
  not_a_valid_skype_link: "No es un enlace válido de Skype",

  give_us_feedback: "Por favor, danos tu opinión",
  please_do_let_us_know: "Por favor, infórmanos:",
  your_feedback_is_important_to_us: "¡Tu opinión es importante para nosotros!",
  if_you_would_like_to_let_us_know:
    "Si deseas informarnos cómo podemos mejorar nuestros productos o servicios, o si quieres compartir un cumplido, ¡nos encantaría saber de ti!",
  how_you_use_our_products: "Cómo usas nuestros productos",
  how_we_can_improve_our_products_or_service: "Cómo podemos mejorar nuestros productos o servicios",
  which_conferences_you_think_we_should_attend: "¿A qué conferencias crees que deberíamos asistir?",
  any_publications_citing_the_use_of_our_products: "Publicaciones que mencionan el uso de nuestros productos",
  we_look_forward_to_hearing_from_you: "Esperamos saber de ti pronto",
  feedback_form: "Formulario de Opinión",
  please_feed: "Por favor, comparte tus opiniones",
  feed_back_share: "¡Compártelo con nosotros!",
  thank_you: "¡Gracias!",
  rate_us: "¡Califícanos!",
  we_have_recieved_your_feedback: "¡Hemos recibido tu opinión!",
  i_allow_for_this_message_to_be_shared_the_other_users: "Permito que este mensaje sea compartido por otros usuarios",

  if_you_delete_this_file:
    "Si eliminas este archivo, los códigos QR que utilizan este archivo como medio activo pueden volverse inválidos. ¿Estás seguro de que deseas continuar?",
  if_you_delete_selected_files:
    "Si eliminas estos archivos seleccionados, los códigos QR que utilizan estos archivos como medios activos pueden volverse inválidos. ¿Estás seguro de que deseas continuar?",
  if_you_delete_this_folder:
    "Si eliminas esta carpeta, los códigos QR que utilizan estos medios activos pueden volverse inválidos. ¿Estás seguro de que deseas continuar?",

  you_need_to_select_pdf_for_each_language_missing_in: "Debes agregar un archivo PDF para cada idioma. Faltan:",
  you_can_not_delete_default_language: "No puedes eliminar el idioma predeterminado",
  you_can_not_delete_active_language: "No puedes eliminar el idioma activo",
  "Set as a default": "Establecer como predeterminado",
  Delete: "Eliminar",
  Activate: "Activar idioma",
  a_new_verification_mail_has_been_sent_to_your_email_address:
    "Se ha enviado un nuevo correo de verificación a tu dirección de correo electrónico.",
  email_verification: "Verificación de correo electrónico",
  did_not_recieve_an_email: "¿No recibiste un correo?",
  re_send_verification_mail: "REENVIAR correo de verificación",
  just_for_you_to_join_us_one_step_left: "Solo te queda un paso para unirte a nosotros",
  an_activation_email_has_been_sent:
    "Se ha enviado un correo de activación a tu dirección de correo electrónico. Haz clic en el enlace para activar tu cuenta.",
  locks_your_app_and_asks_users_to_enter_this_password:
    "Se aplica un sistema de contraseñas por motivos de seguridad para acceder a tu aplicación. Los usuarios deben ingresar la contraseña previamente establecida para acceder a la aplicación o al contenido privado. Esta contraseña asegura la autenticación de los usuarios y previene accesos no autorizados, protegiendo así los datos del usuario.",
  paste_given_code_if_you_would_like_to_track_your_statictics:
    "Si deseas realizar un seguimiento detallado de tus estadísticas, pega tu código de Yandex Metrica o Google Analytics aquí.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date:
    "Si completas los campos a continuación, el código QR no estará disponible antes de la fecha de inicio ni después de la fecha de finalización.",
  the_content_will_only_be_avialable_to_the_selected_deviceList:
    "El contenido solo estará disponible para los dispositivos seleccionados.",
  this_must_be_less_than_5_characters: "Esto debe tener menos de 5 caracteres",
  click_here_to_save_qrcode: "<strong>¡NO GUARDADO!</strong> Debes ir a la página de edición y guardar.",
  type_changer_disclaimer: ({ named }) =>
    `Está a punto de cambiar a un código QR diferente del <strong>${named(
      "currentType"
    )}</strong> activo. Si continúa, todos los datos ingresados en este código QR se eliminarán y será redirigido a la página correspondiente para completar los campos necesarios. Tenga en cuenta que esta acción no se puede deshacer.`,

  btnText: "Texto del Botón",

  your_short_url_will_look_like_this: "Su URL corta se verá así <br> (Si no es su propio dominio): <br> q-r.cc/adc256",
  please_input_a_valid_phone_number: "Por favor ingrese un número de teléfono válido",

  qr_code_warning_text:
    "Nota: Si cambia su dominio o valor, el contenido del código QR cambiará. Si es un código impreso, el código QR no funcionará.",

  your_profile_photo_has_been_successfuly_updated: "¡Su foto de perfil se ha actualizado con éxito!",

  your_profile_photo_has_been_successfuly_removed: "¡Su foto de perfil ha sido eliminada con éxito!",

  you_at_least_need_to_have_1_address: "¡Debe tener al menos una dirección!",

  pricing_guide: `
  <div style="padding:30px;background:#ffffff">
            <h1 class="text-center">Guía de Precios</h1>
                        <ul>
              <li>Adoptamos una política de precios transparente y justa al ofrecer soluciones adecuadas a las necesidades de nuestros clientes. Ofrecemos diferentes opciones de paquetes para nuestras soluciones de código QR: 
Paket Starter, Paket Advanced y Paket Business. También ofrecemos ofertas personalizadas para clientes con necesidades especiales.</li>

                <li>Los paquetes se venden por cantidad de códigos y cada paquete ofrece diferentes características y niveles de servicio. La elección entre estos paquetes puede variar según las necesidades, presupuesto y escenario de uso de nuestros clientes.</li>

                <li>El Paket Starter es un excelente punto de partida para pequeñas empresas y usuarios personales. El Paket Advanced ofrece una gama más amplia de características para empresas de tamaño medio y usuarios con más necesidades. El Paket Business está diseñado para satisfacer los requisitos más complejos de nuestros grandes clientes corporativos.</li>

                <li>Nuestras ofertas personalizadas proporcionan soluciones y paquetes a medida para proyectos específicos o requisitos únicos, fuera de los códigos vendidos por cantidad. Al ofrecer opciones de precios flexibles según las necesidades específicas de los clientes, ayudamos a encontrar la solución más adecuada.</li>

                <li>Para obtener más información sobre detalles de precios y características de los paquetes, por favor, contáctenos.</li>
              </ul
            <p class="text-end">QR Code Matic</p>
          </div>
`,

  how_it_works: "Cómo Funciona",

  how_it_works_button_text: "Cómo Funciona",

  your_subscription_expired: "Su suscripción ha expirado. Puede crear un código QR estático GRATIS.",

  qrcode_will_be_deleted_in_24: "Este código QR será eliminado en 1 día. Haga clic para saber el motivo.",

  //Notification
  notification_detail: "Detalles de la notificación",

  //Adress
  address_information: "Información de la dirección",
  static_fields: "Campos Estáticos",

  //domain list
  manage_your_own_domains: "Gestiona tus propios dominios",

  domain_list: "Lista de Dominios",

  domain_name: "Nombre del Dominio",

  short_link: "Enlace Corto",

  options: "Opciones",

  delete: "Eliminar",

  domain_delete: "Eliminar Dominio",

  domain_delete_desc: "Estás a punto de eliminar el dominio ",

  domain_delete_desc_2: "¿Estás seguro de que quieres continuar?",

  verified_status: "Estado de Verificación",

  how_to_setup: "¿Cómo Configurarlo?",

  how_to_setup_desc:
    "Para activar tu propio dominio acortador, necesitas configurar un subdominio en tu dominio. El subdominio debe apuntar a nuestro dominio q-r.cc mediante un registro CNAME. Asegúrate de que tu dominio esté configurado correctamente, ya que puede tardar hasta 24 horas para que nuestro sistema reconozca los cambios en tu subdominio.",

  what_is_a_short_URL: "¿Qué es una URL corta?",

  what_is_a_short_URL_desc:
    "Los Códigos QR Dinámicos utilizan una URL corta que redirige a la página de inicio deseada. Esto es necesario para recopilar datos de seguimiento y permite editar los códigos. Puedes cambiar este dominio, por ejemplo, de esta manera",

  create_template_button_text: ({ named }) =>
    `<span class="font-weight-bold mr-1">Diseño</span><span class="font-weight-light">Crear</span>`,

  queue_processing_title: ({ named }) => `¡Tu ${named("type")} está en proceso!`,

  queue_done_title: ({ named }) => `¡Tu ${named("type")} ha sido completado!`,

  queue_done_text: ({ named }) =>
    `Tu operación masiva de ${named(
      "type"
    )} ha terminado. Ahora puedes realizar cambios en tus códigos QR principales y sus códigos QR secundarios correspondientes.`,

  queue_processing_text: ({ named }) =>
    `Tu solicitud masiva para ${named(
      "type"
    )} ha sido puesta en cola. Deberás esperar hasta que se complete. Recibirás un correo electrónico poco después de que se haya completado.`,

  waiting_for_process: "Esperando que se complete tu solicitud...",

  select_all: "Seleccionar todo",

  deselect_all: "Deseleccionar todo",

  add_more_child_from_previous_qrcodes: "+ Agregar más códigos QR secundarios",

  add_more_child_from_previous_qrcodes_tooltip:
    "Puedes agregar o mover otros códigos QR debajo de este código QR para actualizarlos de forma masiva y rápida.",

  convert_to_master: "Convertir en Maestro",

  convert_to_master_tooltip: "Convierte este código QR en un maestro para agregar o mover códigos QR debajo de él.",

  add_more_child_dialog_title: "Agregar Códigos QR Existentes bajo este Código QR Maestro",

  add_more_child_dialog_button_text: "Transferir",

  add_more_child_is_successful: "Operación exitosa",

  add_more_child_dialog_p1: () =>
    `Este menú te permite agregar tus códigos QR previamente creados a un código QR Maestro para realizar <strong>acciones masivas</strong>. Así podrás gestionar estos códigos QR de manera más eficiente.`,

  add_more_child_dialog_p2: ({ named }) =>
    `Los códigos QR seleccionados de la lista siguiente bajo el título <strong>${named("title")}</strong> /
  <strong>${named(
    "vars"
  )}</strong> serán agregados bajo este código QR Maestro. Después podrás realizar acciones masivas sobre estos códigos QR desde <strong>${named(
      "title"
    )}</strong>.`,

  add_more_child_dialog_p3: () => `Los íconos de advertencia junto a los códigos QR de la lista indican lo siguiente:
  <ul>
    <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">1 código QR secundario</span> Este código QR tiene otros códigos QR debajo. Si seleccionas estos, se transferirán junto con los códigos QR secundarios.</li>
    <li><i class="fa-solid fa-circle-info orange--text mr-2"></i>Este código QR tiene propiedades de maestro. Si lo seleccionas, perderá su estatus de maestro y será transferido debajo de otro código QR.</li>
  </ul>`,

  are_you_sure_convert_child_to_master: ({ named }) =>
    `Estás a punto de quitar el código QR titulado <strong>${named(
      "title"
    )}</strong> de su código QR maestro y convertirlo en maestro. ¿Estás seguro de que quieres continuar?`,

  add_more_child_count: ({ named }) => `Este código QR tiene ${named("count")} códigos QR secundarios`,

  gradient_on_eyes: "Gradiente en los Ojos",

  update_prices: "Actualizar los Precios del Menú",

  confirm_delete_all: "¿Estás seguro de que deseas eliminar todos estos códigos QR?",

  unsaved_changes_will_be_lost: "Los cambios no guardados se perderán. ¿Estás seguro de que quieres salir?",

  show_media: "Mostrar Medios cuyos Enlaces serán Copiados",

  $LOGIN: {
    welcome_to: "¡Bienvenido a QR Code Matic!",
    just_one_step: "¡Solo falta un paso para crear un Código QR dinámico o estático!",
    you_are_almost_done: "¡Casi has terminado!",
    please_choose_appropriate_options:
      "¡Por favor, selecciona la opción que mejor se adapte a ti! Puedes elegir rápidamente 'OTRO' en la parte inferior o 'USO PERSONAL' en la parte superior.",
    please_chose_one_of_the_options: "Por favor, selecciona una opción",
    disclaimer:
      "Durante el período de prueba, NO se te pedirá TU INFORMACIÓN DE TARJETA DE CRÉDITO. Solo asegúrate de seleccionar el país correcto.",
    new_here: "¿Nuevo aquí?",
    create_an_account: "Crear una cuenta",
    password: "Contraseña",
    password_re: "Verificar Contraseña",
    forgot_password: "¿Olvidaste tu contraseña?",
    sign_in: "Iniciar sesión",
    sign_in_with_google: "Iniciar sesión con Google",
    sign_in_with_facebook: "Iniciar sesión con Facebook",
    sign_in_with_twitter: "Iniciar sesión con Twitter",
    sign_up: "Registrarse",
    subheader: "Rellena la información requerida para registrarte",
    your_organization: "Tu industria",
    your_country: "Tu país",
    fullname: "Nombre completo",
    confirm_password: "Confirma la contraseña",
    agree_terms: "Acepto los términos de privacidad y uso",
    did_you_forget_password: "¿Olvidaste tu contraseña?",
    forgot_password_subheader: "Ingresa tu correo electrónico para restablecer tu contraseña",
    submit: "Enviar",
    cancel: "Cancelar",
    terms: "Política de privacidad y Términos de uso",
    contact_us: "Contáctanos",
    enter_new_password: "Introduce una nueva contraseña",
    your_password_has_been_reset: "Tu contraseña ha sido restablecida",
    you_will_be_redirected_to_login: "Serás redirigido a la página de inicio de sesión...",
    reset_link_is_sent_to_your_mail: "El enlace de restablecimiento ha sido enviado a tu correo electrónico.",
    check_your_mail_box: "Revisa tu bandeja de entrada",
    login: "INICIAR SESIÓN",
    your_country: "Selecciona tu país",
    your_language: "Selecciona tu idioma",
  },

  $TICKET: {
    mail_support: "Solicitud de Soporte",
    choose_a_file: "Selecciona un archivo o arrástralo aquí...",
    enter_mail: "Ingresa tu correo electrónico...",
    mail: "Correo electrónico",
    subject: "Asunto",
    phone: "Teléfono",
    message: "Mensaje",
    select_topic: "Selecciona un tema",
    qenerals_quetion: "Pregunta General",
    billing: "Facturación",
    technical_question: "Pregunta Técnica",
    login_issue: "Problema de inicio de sesión",
    other: "Otro",
    thanks_title: "Gracias por contactarnos",
    thanks_text:
      "Tu mensaje ha sido enviado con éxito. Nuestro personal se pondrá en contacto contigo lo antes posible.",
  },

  $ORDERS: {
    order_number: "Número de pedido",
    order_status: "Estado del pedido",
    product_name: "Nombre del producto",
    currency: "Moneda",
    order_date: "Fecha del pedido",
    active_package: "Paquete activo",
    started_at: "Fecha de inicio",
    expires_at: "Fecha de expiración",
    remaining_days: "Días restantes",
    actions: "Acciones",
    order_title: "Pedidos",
    payment_confirmed: "Confirmado",
    incorrect_payment: "Pago incorrecto",
    awaiting_payment: "Pago pendiente",
    order_canceled: "Cancelado",
    cancel_order: "Cancelar pedido",
    status: "Estado",
    invalid_package: "Paquete inválido",
    payment_type: "Tipo de pago",
    account_number: "Número de cuenta",
    payment_amount: "Monto del pago",
    payment_desc: "Descripción del pago",
    bank_transfer: "Transferencia bancaria",
    creditcard: "Tarjeta de crédito",
  },

  $SELECT_SECTOR: {
    personal_use: "Uso personal",
    tourism: "Turismo",
    restaurants: "Restaurantes",
    software: "Software",
    photo_video: "Fotografía y Video",
    cities_culture: "Ciudades y Cultura",
    event_management: "Gestión de Eventos",
    home_repair: "Reparación y Hogar",
    finance: "Finanzas",
    publisher: "Editorial",
    nonprofit: "Sin ánimo de lucro",
    retail: "Venta al por menor",
    real_estate: "Bienes Raíces",
    gym: "Gimnasios y Bienestar",
    hotel: "Hoteles y Resorts",
    products: "Productos",
    education: "Educación",
    goverment: "Gobierno",
    doctors: "Médicos y Servicios de Salud",
    agency: "Servicios de Agencia",
    electronics: "Electrónica",
    paper: "Muebles, Papel y Productos de Papel",
    glass: "Vidrio, Cemento y Arcilla",
    environment: "Medio Ambiente",
    energy: "Energía",
    foods: "Alimentos",
    construction: "Construcción",
    business: "Negocios y Gestión",
    chemistry: "Química, Petróleo, Caucho y Plástico",
    culture: "Cultura, Arte y Diseño",
    mines: "Minería",
    machines: "Máquinas",
    media: "Medios, Comunicación y Publicidad",
    metal: "Metal",
    automotive: "Automotriz",
    social: "Servicios Sociales",
    sports: "Deportes y Recreación",
    agriculture: "Agricultura, Caza y Pesca",
    textile: "Textil, Confección, Cuero",
    trade: "Comercio (Ventas y Marketing)",
    community: "Servicios Comunitarios y Personales",
    beverage: "Servicios de Alimentos y Bebidas",
    transport: "Transporte y Logística",
    communications: "Comunicaciones",
    other: "Otros",
  },

  $SUBSCRIPTION: {
    selected_package_name: "Paquete seleccionado",
    choose_payment_method: "Seleccionar Método De Pago",
    choose_payment_method_description:
      "Puede realizar un pago instantáneo con tarjeta de crédito y completar su transacción de forma segura. Alternativamente, puede realizar su pago mediante transferencia bancaria.",
    add_payment_info: "Información de pago",
    payment_is_safe: "Con nuestro sistema de pago seguro, puede realizar su compra sin preocupaciones de seguridad.",
    private_or_business:
      "Por favor, seleccione si la compra es individual o comercial (empresarial). Esta selección es importante para la emisión de la factura.",
    billing_info: "Información de facturación",
    complete_payment: "Completar pago",
    bank_transfer_info: "Información de transferencia bancaria",
    about_pricing: "Si necesitas más información sobre nuestra política de precios, por favor consulta",
    choose_your_plan: "Selecciona Tu Plan",
    pricing_policy: "Política de precios",
    company_invoice: "Factura de empresa",
    individual_invoice: "Factura individual",
    select: "COMPRAR",
    discountMessage: ({ named }) => `Descuento de ${named("amount")}$ aplicado`,
  },

  validations: {
    field_required: "Este campo es obligatorio",
    email_required: "El correo electrónico es obligatorio",
    password_required: "La contraseña es obligatoria",
    agree_terms: "Debes aceptar los términos",
    invalid_zip: "Código postal no válido",
    invalid_email: "Correo electrónico no válido",
    invalid_password: "La contraseña no es válida",
    invalid_youtube: "Enlace de YouTube no válido",
    not_a_valid_email: "No es un correo electrónico válido",
    not_a_valid_phone_number: "No es un número de teléfono válido",
    you_must_accept_the_terms: "Debes aceptar los términos",
    your_password_does_not_match: "Las contraseñas no coinciden",
    you_must_enter_valid_email: "Debes ingresar un correo electrónico válido",
    you_must_enter_password: "Debes ingresar una contraseña",
    you_must_enter_your_name: "Debes ingresar tu nombre",
    you_must_choose_country: "Debes seleccionar un país",
    you_must_choose_organization: "Debes seleccionar una organización",
    must_contain_numbers: "Debe contener al menos un número",
    must_contain_letters: "Debe contener al menos una letra",
    short_link_is_required: "El enlace corto es obligatorio",
    short_link_special_char: "Solo debe contener números y letras",
    short_link_between: "Debe tener entre 3 y 30 caracteres",
    less_than: ({ named }) => `Debe tener menos de ${named("amount")} caracteres`,
    more_than: ({ named }) => `Debe tener al menos ${named("amount")} caracteres`,
  },

  authTemplate: {
    confirm: "Confirmar",
    legal_disclaimer_title: () => `<h1><span>Aviso</span> Legal</h1>`,
    legal_disclaimer:
      "Debes tener al menos 11 años para ver este sitio. Si tienes menos de 11 años, no tienes derecho a acceder a este sitio.",
    login_area_title: () => `<h1><span>Área</span> de acceso</h1>`,
    login_button_text: "Iniciar sesión",
    login_disclaimer:
      "El contenido que deseas alcanzar está oculto. Puedes acceder a la página ingresando tu contraseña.",
  },

  ALERT: {
    alert_msg_1:
      "Estás a punto de editar los datos de un código QR estático. Esto cambiará el propio código QR. Los cambios que realices no afectarán al código QR impreso o distribuido. ¿Estás seguro de que deseas continuar?",
  },

  FAQ_MAIN: {
    faqs_and_guides_about_qr_code: "Preguntas frecuentes y guías",
    search_for_topics: "Buscar temas...",
    faq: "Preguntas frecuentes",
    the_most_frequently_asked_question: "Las preguntas más frecuentes",
    articles_in_this_collection: "Artículos Descripción Disponible",
    written_by_QR_Code_Matic: "Escrito por QR Code Matic",
    setting_up_your_account: "Configuración de tu cuenta",
    why_qr_code: "¿Por qué usar un código QR?",
    pricing_and_billing: "Precios y facturación",
    information_about_prices_and_invoices: "Información sobre precios y facturas",
    find_out_everything_you_need_to_know_about_qr_codes_here:
      "Aquí aprenderás todo lo que necesitas saber sobre los códigos QR",
    analytic_title: "Analítica para entender los datos: su importancia y aplicaciones en tu negocio",
    get_to_know_our_pricing_terms_and_billing: "Conoce nuestros términos de precios y facturación",
    analytic: "Analítica",
  },

  FAQ: {
    static_dynamic_title: "¿Cuál es la diferencia entre un código QR estático y dinámico?",
    static_dynamic: `Los códigos QR estáticos no se pueden modificar una vez que se han creado e impreso. 
  Si el enlace se rompe, no hay nada que puedas hacer más allá de volver a imprimirlo. Sin embargo, 
  los códigos QR dinámicos se pueden editar y modificar en tiempo real. 
  Dado que el código QR dinámico contiene una URL de marcador de posición, se puede usar el mismo código QR repetidamente, 
  lo que ahorra tiempo, esfuerzo y costos.`,

    better_static_dynamic_title: "¿Cuál es mejor: el código QR estático o dinámico?",
    better_static_dynamic: `Si buscas una campaña de marketing única que no requiera modificaciones ni seguimiento en el futuro, 
  los códigos QR estáticos podrían ser más adecuados para ti. Si necesitas más flexibilidad en tus campañas, 
  análisis de escaneo de QR, redireccionamiento en línea, escaneos más rápidos, etc., 
  los códigos QR dinámicos son mucho más recomendados que los estáticos.`,

    how_change_dynamic_title: "¿Cómo puedo convertir un código QR estático en uno dinámico?",
    how_change_dynamic: `No puedes convertir un código QR estático en un código QR dinámico, 
  porque los códigos QR estáticos no se pueden editar una vez que han sido creados.`,

    how_qrcode_free_title: "¿Cómo puedo crear un código QR de forma gratuita?",
    how_qrcode_free: `Visita el generador de códigos QR de QR Code Matic y comienza a crear tu código QR de forma gratuita. 
  Puedes descargarlo en el formato que desees y personalizarlo si lo deseas. 
  Nota: Los códigos QR estáticos son gratuitos de forma indefinida. 
  Sin embargo, para crear códigos QR dinámicos, deberás iniciar una prueba gratuita de 7 días o comprar un plan.`,

    what_is_qr_code_title: "¿Qué es un Código QR?",
    what_is_qr_code: `El nombre QR Code proviene de las iniciales de la frase en inglés Quick Response (Respuesta Rápida). 
  El código QR es un tipo de código de barras bidimensional desarrollado por la empresa Denso Wave, 
  que permite almacenar cientos de caracteres de datos. 
  Por lo general, está formado por píxeles blancos y negros. Los datos codificados en el código pueden ser descifrados 
  y visualizados rápidamente usando un teléfono inteligente o una tableta.`,

    how_can_i_generate_qr_code_title: "¿Cómo puedo generar un Código QR?",
    how_can_i_generate_qr_code: `Crear un código QR es un proceso sencillo. Puedes generar rápidamente códigos QR utilizando nuestro sitio web generador QR Code Matic. Al ingresar información como texto, enlaces, números de teléfono o correos electrónicos, puedes obtener un código QR en segundos. Los códigos QR permiten a los usuarios acceder a los datos rápidamente y son ideales para compartir información de manera práctica.`,

    what_should_I_consider_when_printing_title: "¿Qué debo tener en cuenta al imprimir?",
    what_should_I_consider_when_printing: `Si vas a utilizar el código en documentos impresos, es necesario usar formatos de salida de alta resolución. 
  En QR Code Matic, puedes descargar tus códigos QR no solo en formato PNG, sino también en formatos vectoriales como AI, EPS y SVG. 
  Estos formatos se pueden ampliar sin perder calidad de imagen. Las salidas en formato vectorial son especialmente adecuadas para aplicaciones de impresión a gran escala. 
  Antes de publicar los códigos, siempre se debe realizar una prueba con diferentes teléfonos inteligentes y aplicaciones.`,

    how_can_QR_codes_be_scanned_title: "¿Cómo se pueden escanear los códigos QR?",
    how_can_QR_codes_be_scanned: `Escanear códigos QR es bastante sencillo. Puedes escanearlos fácilmente utilizando las aplicaciones de cámara integradas en los teléfonos inteligentes o aplicaciones dedicadas para escanear códigos QR. Cuando apuntas la cámara hacia un código QR, el teléfono generalmente lo reconoce automáticamente y muestra el contenido relevante (como un sitio web, texto, número de teléfono, etc.). En algunos dispositivos, solo abrir la aplicación de la cámara y apuntarla al código QR es suficiente, pero en algunos casos, puede ser necesario descargar una aplicación separada para escanear los códigos QR. Los códigos QR ofrecen una forma rápida y fácil de compartir información y permiten a los usuarios acceder a los datos de manera segura.`,

    how_to_use_QR_codes_title: "¿Cómo se usan los Códigos QR?",
    how_to_use_QR_codes: `Con el aumento del uso de teléfonos inteligentes, la importancia de los códigos QR ha crecido. 
  En las campañas, los códigos QR desempeñarán un papel clave, y en el futuro, se convertirán en una herramienta indispensable 
  para llegar a audiencias más amplias. Los códigos QR se utilizan actualmente principalmente con fines de marketing móvil. 
  Los materiales impresos como folletos, carteles, catálogos o tarjetas de presentación pueden respaldarse con contenido digital, como sitios web, videos, PDF o datos de contacto. 
  La variedad de aplicaciones es prácticamente ilimitada.`,

    can_I_customize_QR_codes_visually_title: "¿Puedo personalizar los Códigos QR visualmente?",
    can_I_customize_QR_codes_visually: `Gracias a su alta tolerancia a errores, es posible modificar el código hasta cierto punto sin alterar la estructura necesaria para su legibilidad. 
  Por ejemplo, los colores de fondo y primer plano se pueden definir en degradado o radial, se puede agregar tu logo al código QR, 
  o se pueden cambiar los patrones del cuerpo utilizando puntos de referencia. Sin embargo, siempre prueba la legibilidad del código cuando lo personalices visualmente.`,

    can_I_view_scan_statistics_of_QR_codes_title: "¿Puedo ver las estadísticas de escaneo de los Códigos QR?",
    can_I_view_scan_statistics_of_QR_codes: `Puedes ver estadísticas de escaneo en tiempo real de tus códigos QR dinámicos. 
  Obtendrás detalles como el país, ciudad, navegador, dispositivo, edad, género, resolución del dispositivo, descargas, entre otros.`,

    what_file_formats_and_size_should_i_use_for_printing_title:
      "¿Qué formatos de archivo y tamaños debo usar para la impresión?",
    what_file_formats_and_size_should_i_use_for_printing: `Para las aplicaciones de impresión a gran escala, se recomienda utilizar salidas en formato vectorial para evitar la pérdida de calidad de imagen. 
  Además del tipo de archivo, también debes tener en cuenta el tamaño de los códigos QR. 
  Especialmente los códigos QR estáticos, que contienen más datos, deben ser de mayor tamaño, ya que la cantidad de datos codificados determina el espacio requerido. 
  Para una buena interacción del usuario, el código debe ser fácil de leer. 
  El tamaño mínimo del código que se usará en una aplicación debe ser de al menos 2 x 2 cm. 
  La relación entre la distancia de escaneo y el tamaño del código debe ser al menos de 1/10. 
  Por ejemplo, si el código debe ser escaneado desde 10 metros de distancia, el código QR debe ser de al menos 1 metro x 1 metro. 
  No debe colocarse en superficies irregulares. Las arrugas de folletos o páginas pueden afectar negativamente la legibilidad del código.`,

    where_and_how_to_use_qr_code_title: "¿Dónde y cómo se pueden usar los Códigos QR?",
    where_and_how_to_use_qr_code: `Los usos de los códigos QR son ilimitados. Los códigos QR pueden ser utilizados para compartir tu información personal, 
  así como para resolver problemas logísticos y de marketing de tu empresa, ofreciendo infinitas posibilidades.`,
  },

  WHY_QR_CODE: {
    unlimited_title: "Es Ilimitado",
    unlimited:
      "Con los códigos QR de QRCodeMatic.com, permite que tus usuarios accedan a videos, PDFs, mensajes, información y todo tipo de contenido de forma ilimitada.",

    attractive_title: "Es Atractivo",
    attractive:
      "Lo que es el código QR que utilizas atrae el interés de las personas de manera natural y despierta curiosidad. Con la configuración adecuada, es posible aumentar el nivel de curiosidad y mejorar las conversiones. Además, como es un enfoque orientado a los resultados, la satisfacción de tus clientes mostrará un aumento visible.",

    everywhere_is_a_showcase_for_you_title: "Todo Es un Vitrina Para Ti",
    everywhere_is_a_showcase_for_you:
      "Cada lugar donde tengas tus códigos QR es tu vitrina. Desde productos que otros usuarios ven y les gustan, hasta folletos, tarjetas de presentación o incluso los bocadillos que ofreces, todo puede ser tu vitrina viviente.",

    add_on_your_product_title: "Añade el Folleto de Información del Producto a Tu Producto",
    add_on_your_product:
      "Puedes pegar guías de uso, detalles, instrucciones y documentos (video, pdf, archivos) de herramientas, dispositivos, productos de consumo y cualquier equipo sobre tus productos. Además, puedes compartir fácilmente las licencias y estándares especiales que hayas obtenido para tus productos, lo que aumentará tu confiabilidad.",

    your_successful_sales_representative_title: "Es Tu Representante de Ventas Exitoso",
    your_successful_sales_representative:
      "Hace que los productos en tu vitrina estén disponibles 24/7, recomendables y de fácil acceso para tus usuarios, proporcionando una experiencia de compra divertida e interesante.",

    makes_you_easy_to_reach_title: "Facilita que Te Encuentren",
    makes_you_easy_to_reach:
      "Con un solo código, puedes permitir que tus clientes te llamen, envíen correos electrónicos o mensajes, accedan a tus redes sociales, te agreguen a sus contactos y direcciones, o consigan tu ubicación, gracias a su integración con Google Maps y la capacidad de incluir toda la información de tu perfil de empresa.",

    it_is_social_title: "Es Social",
    it_is_social:
      "Permite que tus usuarios vean y sigan todas tus cuentas de redes sociales, sigan tu marca, den 'me gusta', comenten, e incluso reporten quejas o sugerencias.",

    lets_you_show_your_creativity_title: "Te Permite Mostrar Tu Creatividad",
    lets_you_show_your_creativity:
      "Es una plataforma de marketing fácil de usar para tus eventos especiales, sorteos, campañas y descuentos. Puedes crear aplicaciones intrigantes que aumenten la visibilidad de tu marca y presenten tus productos en promociones, eventos o sorteos digitales. El límite de estas aplicaciones es solo tu creatividad e imaginación.",

    low_cost_title: "Es de Bajo Costo",
    low_cost:
      "Es una herramienta de promoción y marketing de bajo costo cuando se consideran los beneficios que ofrece. Puedes ver los detalles de nuestros paquetes en QRCodeMatic.com.",

    durable_title: "Es Duradero",
    durable:
      "Es una herramienta de promoción y marketing de bajo costo cuando se consideran los beneficios que ofrece. Puedes ver los detalles de nuestros paquetes en QRCodeMatic.com.",
  },

  BILLING: {
    when_can_i_use_my_package_title: "¿Cuándo puedo usar mi paquete?",
    when_can_i_use_my_package:
      "Una vez que hayas realizado el pago y confirmado tu pedido, todas las características de tu plan estarán desbloqueadas y podrás comenzar a crear nuevos Códigos QR o gestionar tus Códigos QR de Prueba Gratis inmediatamente.",

    can_i_upgrade_to_a_higher_plan_title: "¿Puedo actualizar a un plan superior?",
    can_i_upgrade_to_a_higher_plan:
      "Sí. Si has alcanzado el límite de Códigos QR dinámicos o si deseas desbloquear más características, puedes actualizar a un plan superior en cualquier momento. Luego te proporcionaremos un reembolso proporcional.",

    how_long_is_the_contract_period_title: "¿Cuál es la duración del contrato?",
    how_long_is_the_contract_period:
      "A menos que se cancele el contrato, el contrato se renovará automáticamente por el período de tiempo seleccionado.",

    how_do_i_cancel_my_subscription_title: "¿Cómo cancelo mi suscripción?",
    how_do_i_cancel_my_subscription:
      "Si no desea renovar su contrato, enviar un correo electrónico oficial será suficiente. Tenga en cuenta que la renovación se puede realizar hasta el último día del período del contrato, por lo que es importante enviar su solicitud a tiempo.",
  },

  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: "¿Qué tipo de informes analíticos ofrecen?",
    what_kind_of_analytics_reporting_do_you_offer:
      "Los datos de análisis de las URL redirigidas que utilizan nuestro servicio de acortamiento de URL se proporcionan en un formato de fecha buscable, mostrando la fecha, la hora, el tipo de dispositivo y la ubicación para cada evento de escaneo, en tiempo real en el panel de control de su cuenta.",

    can_analytics_be_used_with_all_your_QR_codes_title: "¿Se puede usar análisis con todos tus Códigos QR?",
    can_analytics_be_used_with_all_your_QR_codes:
      "Los datos de análisis solo se pueden recopilar cuando crea un código QR dinámico que permite al usuario regresar a nuestro sitio web a través de su URL corta codificada y nos permite recopilar los datos necesarios durante ese proceso. Muchos otros sitios web generadores de códigos QR lo obligan a crear un código QR dinámico, quiera o no. Sin embargo, decidimos hacerlo opcional para asegurarnos de que se muestre la URL del sitio web real, no la nuestra, cuando se escanee el código QR. La desventaja es que, dado que no hay un mecanismo de seguimiento dentro del propio código QR, si crea un código QR estático, el visitante escanea el código QR y luego es dirigido directamente a la URL de destino o al contenido codificado sin pasar por nuestro servidor. Esto significa que no tenemos la oportunidad de intervenir en ese camino y recopilar datos de seguimiento en su nombre. Por lo tanto, es posible que necesite usar una herramienta de seguimiento, como Google Analytics, en la URL a la que apunta el código QR.",

    how_can_I_access_my_analytics_reports_title: "¿Cómo puedo acceder a mis informes analíticos?",
    how_can_I_access_my_analytics_reports:
      "Cuando inicies sesión en tu cuenta de suscripción, podrás acceder a tu panel de control desde el enlace en la parte superior derecha de la página. En el informe de historial, haz clic en la pestaña 'Estadísticas' a la derecha de cada registro de código QR para ver información detallada sobre ese código QR. Los códigos QR dinámicos mostrarán un enlace de 'Analytics' a la derecha de la lista de detalles. Si no ves la opción 'Analytics', el código QR que creaste es un código QR estático y no tiene informes analíticos disponibles.",

    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title:
      "¿Se incluyen números de teléfono y direcciones de correo electrónico en los informes analíticos?",
    are_phone_numbers_and_email_addresses_included_in_analytics_reports:
      "No. Debido a los protocolos de privacidad y seguridad incorporados en los teléfonos, no se puede acceder a la información de usuario identificable, como números de teléfono y direcciones de correo electrónico, almacenada en el dispositivo del usuario al escanear el código QR.",
  },

  login_note: `<div style="font-weight: 900">¡IMPORTANTE!</div> <span class="demo-time"> Al finalizar el período de prueba</span> 
  podrás seguir utilizando las funcionalidades dentro de la plataforma para crear <span class="font-weight-bolder"> Códigos QR Estáticos GRATUITOS</span> 
  y continuar utilizando tu <span class="font-weight-bolder"> SUSCRIPCIÓN</span>.`,

  MENU: {
    NEW: "Nuevo",
    ACTIONS: "Acciones",
    CREATE_POST: "Crear Nueva Publicación",
    PAGES: "Páginas",
    FEATURES: "Características",
    APPS: "Aplicaciones",
    DASHBOARD: "Panel",
    MARKET: "Mercado",
    MYQRCODES: "Mis Códigos QR",
    ALLQRCODES: "Todos los Códigos QR",
    ACTIVEQRCODES: "Códigos QR Activos",
    PASSIVEQRCODES: "Códigos QR Pasivos",
    DELETEDQRCODES: "Códigos QR Eliminados",
    MASTERQRCODES: "Códigos QR Creados en Masa",
    SUBSCRIPTIONS: "Precios y Paquetes",
    MYFOLDERS: "Mis Carpetas",
    QRFOLDERS: "Carpetas de Códigos QR",
    MYMEDIA: "Mis Medios",
    ADDNEWFOLDER: "Agregar Nueva Carpeta",
    CREATENEWQRCODE: "Crear Nuevo Código QR",
    MYACCOUNT: "Mi Cuenta",
    PAIDPLAN: "Plan de Pago",
    ORDERHISTORY: "Historial de Pedidos",
    SUBMITTICKET: "Soporte",
    HELP: "Ayuda",
    PACKAGE_HISTORY: "Historial de Paquetes",
    DAY_REMANING: "Días Restantes",
    SCAN: "Escanear",
    STATIC: "Estático",
    DYNAMIC: "Dinámico",
    PACKAGE_TITLE: "DETALLES DEL PAQUETE",
    CORPARATE: "Corporativo",
    TITLE: "Menú",
    BULKPRICE: "Permite actualizar rápidamente los precios del menú",
    MY_FOLDER: "MIS CARPETAS",
  },

  QRLIST: {
    STATIC: "Estático",
    PREVIEW: "Puedes ver una vista previa de los Códigos QR que has creado.",
    DYNAMIC: "Dinámico",
    MULTIPLY_QRCODE: "Multiplicar el Código QR",
    BULK_CREATE:
      "Puedes agregar nuevos Códigos QR debajo de este, y actualizar el contenido y diseño de los Códigos QR en masa.",
    BULK_UPLOAD: "Subir en Masa",
    BULK_UPDATE: "Actualizar en Masa",
    BULK_SETTINGS: "Configuraciones de Subida/Actualización en Masa",
    CLICK_TO_DOWNLOAD: "Haz clic para descargar",
    CLICK_TO_UPLOAD: "Haz clic para subir tu archivo Excel",
    CLICK_TO_UPDATE: "Haz clic para editar",
    SELECT_FILE: "Selecciona un archivo",
    DOWNLOAD_SAMPLE_FILE: "Descargar archivo de ejemplo",
    DOWNLOAD_SUCCESSFUL: "¡Descarga exitosa!",
    CLICK_HERE_TO_CUSTOMIZE: "Haz clic aquí para personalizar los colores y diseños de tu código QR",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "Crea una nueva carpeta en el área del menú izquierdo y filtra los tipos de códigos QR para tus campañas.",
    CLICK_HERE_TO_EDIT: "Haz clic en el título para editar la información de tu tarjeta",
    NON_TRACKABLE: "Estático / No rastreable",
    MY_QR_CODE: "Estás a punto de eliminar tu código QR con la identidad",
    DOWNLOAD_IN_PDF_FORMAT: "Descargar en formato PDF",
    DOWNLOAD_IN_PNG_FORMAT: "Descargar en formato PNG",
    DOWNLOAD_IN_SVG_FORMAT: "Descargar en formato SVG",
    DOWNLOAD_IN_EPS_FORMAT: "Descargar en formato EPS",
    DESC: `Por favor, prueba tu código QR en diferentes dispositivos antes de descargarlo. Para impresión, puedes usar .svg, .eps, .pdf, y para tu sitio web, usa .png.
    Esto te permitirá utilizarlo tanto para documentos impresos como para diseños, incluso en afiches de gran tamaño. Una vez que completes las pruebas, tu código QR estará listo para ser impreso.`,
    NOTE: "Nota: Los códigos QR Radial y Gradient se pueden descargar y abrir en formato .svg.",
    BULK_UPLOAD_DESC:
      "Para crear múltiples códigos QR a la vez, debes descargar el archivo de ejemplo de Excel y llenar correctamente los campos con la información de tu personal. Cada fila de datos generará un código QR nuevo. Haz clic en el ícono de Excel a continuación para descargar el archivo de ejemplo y usa el botón de subir para enviar tu archivo.",
    BULK_CREATE_DESC: "Descarga todos tus códigos QR a la vez y edita su contenido.",
    BULK_UPDATE_DESC: "Descarga todos tus códigos QR a la vez y edita su contenido.",
    UPLOAD_YOUR_FILE: "Sube tu archivo",
  },

  CREATEQR: {
    MENU: {
      DYNAMIC: {
        TITLE: "Código QR Dinámico",
        ITEMS: {
          WEBSITE: "Sitio Web",
          RESTAURANT: "Restaurante",
          FEEDBACK: "Comentarios",
        },
      },
      STATIC: {
        TITLE: "Código QR Estático",
        ITEMS: {},
      },
    },
    WIZARD: {
      BASIC_INFO: {
        TITLE: "Añadir información sobre el contenido",
        CATEGORIES: "Categorías",
        CATEGORY_IMAGE: "Imagen de categoría",
        SELECT_IMAGE: "Selecciona una imagen",
        LOADER_TEXT: "",
        THIS_URL_IS_INVALID: "Esta URL no es válida",
        FIELDS: {
          key: "Campo",
          value: "Valor",
          start: "Inicio",
          end: "Fin",
          video: "Video",
          title: "Título",
          image: "Imagen",
          text: "Texto",
          color: "Color",
          description: "Descripción",
          name: "Nombre",
          number: "Número",
          phone: "Teléfono",
          buttonText: "Texto del botón",
          url: "URL",
          BASIC_INFORMATION: "Información básica",
          SETTINGS: "Ajustes",
          LANGUAGE: "Seleccionar idioma",
          ACTIVE_LANGUAGE: "Idioma activo",
          ADD_SOME_INFO: "Introduce la información requerida",
          FULL_NAME: "Nombre completo",
          COMPANY_NAME: "Nombre de la empresa",
          JOBTITLE: "Título profesional",
          DEPARTMENT: "Departamento",
          BLOOD_GROUP: "Grupo sanguíneo",
          FOLDER_NO: "Número de registro",
          DATE_OF_START: "Fecha de inicio",
          COMPANY: "Empresa",
          MAIL: "Correo electrónico",
          WEBSITE: "Sitio web",
          PHONE: "Teléfono",
          ADDRESS: "Dirección",
          ENTER_LOCATION: "Selecciona una ubicación",
          PLEASE_CHOOSE_LOCATION: "Por favor selecciona una ubicación primero",
          MANAGER_FULL_NAME: "Nombre del responsable",
          MANAGER_PHONE: "Teléfono del responsable",
          MANAGER_JOBTITLE: "Cargo del responsable",
          MANAGER_MAIL: "Correo electrónico del responsable",
          ART_NAME: "Nombre de la obra",
          ART_ACCESSION_NUMBER: "Número de inventario",
          ART_ERA: "Época",
          ART_MEASUREMENT: "Medidas",
          ART_DISCOVERY_LOCATION: "Ubicación del descubrimiento",
          ART_ACCESSION_DATE: "Fecha de ingreso al inventario",
          ART_DESCRIPTION: "Descripción de la obra",
          RESTAURANT_ABOUT_US: "",
          RESTAURANT_ADDRESS: "",
          RESTAURANT_PHONE: "",
          RESTAURANT_EMAIL: "",
          BLOODGROUP: "Grupo sanguíneo",
          MEDICATION: "Tratamiento médico",
          PLATENUMBER: "Número de placa",
          ALLERGICTO: "Alérgico a",
          DESCRIPTION: "Descripción",
          RESPONSIBLESTAFFPHONE: "Persona responsable",
          CITY: "Ciudad",
          FAX: "Fax",
          ZIP: "Código postal",
          ADD_A_NEW_DOCUMENT: "Añadir un nuevo documento",
          QR_CODE_TITLE: "Título del código QR",
          general_info: "Información general",
          BASIC_INFORMATION: "Información básica",
          SHOW_CONTACT_FROM: "Mostrar formulario de contacto",
          SHOW_GOOGLE_MAPS: "Mostrar Google Maps",
          SHOW_ADD_TO_CONTACTS: "Mostrar opción para añadir a contactos",
          SHOW_ADDRESS_FIELD: "Mostrar campo de dirección",
          ADDITIONAL_INFO: "Información adicional",
          SHOW_PHONE_ICON: "Mostrar icono de teléfono",
          SHOW_MAIL_ICON: "Mostrar icono de correo",
          CONTACT_INFO: "Información de contacto",
          FIELDS: "Campos",
          YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "Debes completar los campos antes de postularte.",
          SELECT_PDF: "Selecciona un PDF",
          FDF_MENU_IF_YOU:
            "Si deseas usar menús únicos para cada restaurante, puedes agregar más aquí. Cada restaurante tendrá su propio código QR.",
          DESCRIPTION_FIELD: "Puedes escribir la descripción para este campo aquí",
          CURRENT_RESTAURANTS: "Restaurantes actuales",
          NO_TITLE: "Sin título",
          NEW_RESTAURANT: "Nuevo restaurante",
          BOOK_TITLE: "Título del PDF",
          PERSONNEL_INFO: "Información del personal",
          COMPANY_INFO: "Información de la empresa",
          ENABLE_DOCUMENTS_PAGE: "Activar página de documentos",
          ENABLE_REMINDER: "Activar/desactivar recordatorio por email",
          ENABLE_COUNTER: "Activar/desactivar contador",
          CUSTOM_FIELDS: "Campos personalizados",
          CALL_LIST: "Lista de llamadas",
          PERSON: "Persona",
          EMERGENCY_CALLS: "Llamadas de emergencia",
          DOCUMENTS_LIST: "Lista de documentos",
          GROUPS: "Grupos",
          TITLE: "Título",
          SCHEDULE_INFO: "Información del horario",
          DAY: "Día",
          SHOW_SCHEDULE_CALENDER: "Mostrar calendario de horario",
          MANAGER_INFO: "Información del gerente",
          MAPS_STYLE: "Estilo de mapa",
          TEXT_ADDRESS: "Texto",
          TITLE_ADDRESS: "Título",
          SONG: "Canción",
          ADD_MORE: "Añadir más",
          ARTIST: "Artista",
          NO_RESTRICTION: "Sin restricciones",
          ARTIFACT_INFORMATION: "Información del artefacto",
          ARTIFACT_INFO: "Información de la estructura",
          STORE_INFORMATION: "Información de la tienda",
          PLATFORM: "Plataforma",
          OTHER_FIELDS: "Otros campos",
          WIFINAME: "Nombre de wifi",
          RANDOM_LINK: "Enlace aleatorio",
          SHOW_RANDOM_LINK: "Mostrar un elemento aleatorio de la lista.",
          SHOW_ALL_RANDOM_LINK: "Mostrar todos los elementos de la lista.",
          MULTIPLE_LINK: "Enlaces múltiples",
          LINKS: "Enlaces",
          PRODUCT_NAME: "Nombre del producto",
          GO_TO_WEBSITE: "Ir al sitio web",
          SELECT_ICON: "Selecciona un icono",
          brochure_image: "Imagen del banner",
          SELECT: "Seleccionar",
          SELECT_IMAGE: "Seleccionar imagen",
          SELECT_RELATED_CATEGORY: "Selecciona la categoría relacionada",
          ABOUT_US_PAGE_INFO: "Información sobre nosotros",
          CONTACT_PAGE_INFO: "Información de contacto",
          RESERVATION_PAGE_INFO: "Información de reservas",
          ABOUT_US_PAGE: "Página de sobre nosotros",
          CONTACT_PAGE: "Página de contacto",
          RESERVATION_PAGE: "Página de reservas",
          BROCHURES: "Folletos",
          BANNER: "Banner",
          type: "Tipo",
          REDIRECT: "Redirección",
          SONGURL: "Pega el enlace del video aquí...",
          isActive: "Activo/Inactivo",
          proximity: "Proximidad",
          sort_order: "Orden de clasificación",
          SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Mostrar botones de redes sociales",
          fileUrl: "URL del archivo",
          fileFormat: "Formato del archivo",
        },
      },
      VALIDATION: {
        VAILD_PHONE_NUMBER: "Por favor, ingresa un número de teléfono válido",
      },
      CUSTOMIZE: {
        TITLE: "Diseño & Personalización",
        FIELDS: {
          CUSTOMIZE_TEXT: "Personaliza tu texto",
          CUSTOMIZE_YOUR_PAGE: "Personaliza tu página eligiendo colores",
          SINGLE_COLOR: "Un solo color",
          FIRST_COLOR: "Primer color",
          SECOND_COLOR: "Segundo color",
          BG_COLOR: "Color de fondo",
          GRADIENT: "Color de gradiente",
          COLOR: "Color",
          PRIMARY: "Color principal",
          TEXT_COLOR: "Color del texto",
          BUTTONS: "Botones",
          CHOOSE_BACKGROUND: "Selecciona fondo o color",
          IMAGE: "Imagen",
          CHOOSE_FILE_DROP: "Selecciona un archivo o arrástralo aquí",
          BODY_OPTION: "Opciones del cuerpo",
          LINEAR: "Lineal",
          RADIAL: "Radial",
          TRANSPARENT_BACKGROUND: "Fondo transparente",
          CUSTOMIZE_YOUR_PAGE_LAYOUT:
            "Puedes personalizar el diseño de tu página cambiando las opciones de encendido/apagado abajo.",
          CONTACT_EMAIL_LABEL: "Dirección de correo electrónico para el formulario de contacto",
        },
      },
      SOCIAL_MEDIA_CHANNELS: {
        TITLE: "Enlaces de redes sociales",
        FIELDS: {
          LINK_YOUR_SOCIAL: "Añadir enlaces de redes sociales",
          YOU_CAN_REMOVE: "Puedes eliminar cualquier enlace haciendo clic en la cruz",
          ADD_MORE: "Añadir más",
        },
      },
      MEDIA: {
        TITLE: "Añadir imágenes & videos",
        FIELDS: {
          UPLOAD_PROFILE_PHOTO: "Sube tu foto de perfil",
          PHOTO: "Foto",
          STORY: "Historia",
          NOTE: "Para mejores resultados, elige una imagen 1:1 (por ejemplo: 300px/300px)",
          CHOOSE_MEDIA: "Selecciona contenido multimedia desde tu almacenamiento local",
          VIDEO: "Video",
          CHOOSE_STORY_VIDEO: "Sube un video para mostrar en tu historia",
          YOUTUBE_VIDEO: "Pega el enlace del video de YouTube",
          PHOTOS: "Fotos",
          CHOOSE_PHOTO: "Selecciona fotos para tus historias",
        },
      },
      CATEGORY: {
        TITLE: "Categorías & Productos",
      },
      WELCOME_SCREEN: {
        TITLE: "Personaliza la pantalla de bienvenida",
        FIELDS: {
          LOADING_SCREEN_LOGO: "Logo de la pantalla de bienvenida",
          SELECT_FROM_MEDIA: "Haz clic para seleccionar desde mis medios",
          SELECT_FROM_ANIMATIONS: "Añadir tu propio logo",
          LOADING_SCREEN_BACKGROUND: "Fondo de la pantalla de bienvenida",
          SINGLE_COLOR: "Un solo color",
          GRADIENT: "Color de gradiente",
          YOUR_INFORMATION: "Tu información",
          FIRST_FIELD: "Texto de carga",
          SECOND_FIELD: "Segundo campo",
        },
      },
      ADVANCED_SETTINGS: {
        TITLE: "Ajustes avanzados",
        FIELDS: {
          PASSWORD: "Contraseña",
          AGE_BELOW: "Restricción de edad",
          PASS_CODE_HERE: "Pega el código aquí...",
          TIME_ZONE: "Zona horaria",
          PICK_START_DATE: "Selecciona fecha de inicio",
          CANCEL: "Cancelar",
          OK: "Aceptar",
          STARTING_DATE: "Hora de inicio",
          ENDING_DATE: "Hora de finalización",
          PICK_END_DATE: "Selecciona fecha de finalización",
        },
      },
      CREATEQR: "Crear código QR",
      UPDATE_CHANGES: "Actualizar información",
    },
    FOLLOWALBE: "Seguible (De pago)",
    NOT_TRACKED: "No se puede seguir (Gratis de por vida)",
  },
  RESTAURANT: {
    title: "Título: ",
    description: "Descripción: ",
    cover_image: "Imagen de portada: ",
    category_icon: "Icono de categoría: ",
    categories: "Categorías",
    category: "Categoría",
    product: "Producto",
    products: "Productos: ",
    product_name: "Nombre del producto",
    preparation: "Tiempo de preparación: ",
    calorie: "Calorías",
    ingredients: "Ingredientes: ",
    price: "Precio: ",
    price_label: "Etiqueta de precio",
    likes: "Me gusta: ",
    current_categories: "Categorías activas",
    add_new_field: "Agregar nuevo campo",
    add_new_category: "Agregar nueva categoría",
    add_new_product: "Agregar nuevo producto",
    add_new_price: "Agregar precio",
    please_add_product: "Por favor, agregue un producto",
    brochure_slider: "Banners",
    delete_product: "ELIMINAR PRODUCTO",
    delete_category: "ELIMINAR CATEGORÍA",
    new_category_title: "Nueva categoría",
    new_category_description: "Descripción de la nueva categoría",
    new_product_title: "Nuevo producto",
    new_product_description: "Descripción del nuevo producto",
    status_desc: "Las categorías inactivas serán eliminadas de la publicación.",
    status: "Estado",
    detailed_title: ({ named }) => `${named("value")} Detalles`,
  },
  ERRORS: {
    err_1: "Lo siento, ha ocurrido un error desconocido. Por favor, inténtelo de nuevo más tarde.",
    err_2: "Lo siento, ha ocurrido un error inesperado al cargar el avatar. Por favor, inténtelo de nuevo.",
    err_3: "Lo siento, la dirección de enlace es incorrecta. Por favor, utilice una dirección de enlace válida.",
    err_4: "Lo siento, este correo electrónico ya ha sido confirmado. Intente con otro correo electrónico.",
    err_6: "Lo siento, ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.",
    err_7: "Lo siento, se han realizado demasiadas solicitudes. Por favor, inténtelo de nuevo después de 1 minuto.",
    err_8:
      "Lo siento, su cuenta no está verificada. Haga clic en el botón 'Reenviar' debajo de su contraseña para recibir nuevamente el correo de verificación.",
    err_9: "Lo siento, la contraseña es incorrecta. Por favor, ingrese la contraseña correcta.",
    err_10: "Lo siento, no se encontró un usuario registrado con esta dirección.",
    err_11: "Lo siento, no ha aceptado los términos y condiciones. Por favor, acepte el acuerdo.",
    err_12: "Lo siento, el usuario ha sido eliminado. Por favor, póngase en contacto con nuestro equipo de soporte.",
    err_13:
      "Lo siento, la cuenta del usuario ha sido suspendida. Por favor, póngase en contacto con nuestro equipo de soporte.",
    err_14: "Lo siento, la cuenta está inactiva. Por favor, póngase en contacto con nuestro equipo de soporte.",
    err_15:
      "Lo siento, su paquete actual tiene más de un año de vigencia. Por favor, póngase en contacto con nuestro equipo de soporte.",
    err_16:
      "¡Advertencia! Tiene una transacción pendiente. Para realizar otro pedido, debe cancelar primero el pedido actual. ¿Desea cancelarlo?",
    err_17: ({ named }) =>
      `Lo siento, el espacio de disco de ${named(
        "value"
      )} mb está lleno. Necesita liberar espacio para cargar un nuevo archivo. Si lo desea, puede ponerse en contacto con nuestro equipo de soporte.`,
    err_18: "Lo siento, este código ya ha sido utilizado. Intente con otro código.",
    err_19: "Lo siento, el código es inválido. Por favor, ingrese un código válido.",
    err_20: "Lo siento, este código ha expirado. Por favor, obtenga un nuevo código.",
    err_21: "Lo siento, no se puede cambiar a un paquete inferior.",
    err_22: "Lo siento, el código es inválido. Por favor, ingrese un código válido.",
    err_23: "Lo siento, la clave es inválida. Por favor, ingrese una clave válida.",
    err_24:
      "Lo siento, no se ha encontrado un usuario registrado con esta dirección de correo electrónico. Por favor, utilice una dirección de correo válida.",
    err_25: "Lo siento, no puede dejar vacío el nombre del archivo.",
    err_26: "Lo siento, ya ha utilizado este nombre de archivo antes. Por favor, elija otro nombre para el archivo.",
    err_100: "Lo siento, el campo de correo electrónico es obligatorio.",
    err_101: "Lo siento, el campo de correo electrónico debe ser de tipo texto.",
    err_102: "Lo siento, por favor ingrese una dirección de correo electrónico válida.",
    err_103: "Lo siento, el campo de correo electrónico no debe exceder los 255 caracteres.",
    err_104: "Lo siento, este correo electrónico ya está registrado.",
    err_105:
      "Lo siento, no se acepta esta dirección de correo electrónico. Por favor, ingrese una dirección con una extensión válida.",
    err_106: "Lo siento, el campo de nombre es obligatorio.",
    err_107: "Lo siento, el campo de nombre debe ser de tipo texto.",
    err_108: "Lo siento, el campo de nombre no debe exceder los 255 caracteres.",
    err_109: "Lo siento, el campo de contraseña es obligatorio.",
    err_110: "Lo siento, la contraseña debe tener al menos 6 caracteres.",
    err_111: "Lo siento, la contraseña debe contener al menos una letra.",
    err_112: "Lo siento, la contraseña debe contener al menos un número.",
    err_113: "Lo siento, el campo de país es obligatorio.",
    err_114: "Lo siento, ha seleccionado un país inválido.",
    err_115: "Lo siento, el campo de organización es obligatorio.",
    err_116: "Lo siento, debe aceptar los términos de uso.",
    err_404:
      "Lo siento, no se ha encontrado el contenido que busca. Por favor, ingrese la página o el nombre de contenido correcto.",
    err_500:
      "Lo siento, ha ocurrido un error desconocido. Por favor, recargue la página y vuelva a intentarlo. Si el error persiste, por favor, contáctenos.",
    err_1001:
      "Lo siento, no tiene permisos para realizar esta acción. Por favor, póngase en contacto con una persona autorizada.",
    err_1002: "Lo siento, su paquete ha caducado. Por favor, intente comprar un nuevo paquete.",
    err_1003: "Lo siento, ha alcanzado el límite máximo de su paquete. No puede agregar más contenido.",
    err_1004:
      "Lo siento, no tiene este tipo de código QR. Puede cambiar a un paquete que incluya este tipo de código QR.",
    err_1005: "Lo siento, ha superado el número máximo de 100. No puede agregar más códigos QR.",
    err_1006:
      "Lo siento, sus verificaciones de conexión han fallado. Por favor, utilice la información de conexión correcta.",
    err_1007: ({ named }) =>
      `Error: Ha superado el límite válido de (${named("value")}), por lo que no puede generar el código QR estático.`,
    err_1008: ({ named }) => `Lo siento, su paquete ha caducado. Solo puede crear hasta ${named("value")} carpetas.`,
    err_2001: "Lo siento, el carácter es inválido. Por favor, utilice un carácter válido.",
    err_2002: "Lo siento, esta URL ya está en uso. Por favor, intente con otra URL.",
    err_2003: "Lo siento, ha ocurrido un error desconocido. Por favor, intente nuevamente más tarde.",
    err_2004: "Lo siento, los valores no pueden estar vacíos. Por favor, ingrese un valor válido.",
    err_2005: "Lo siento, el valor debe tener al menos 3 caracteres. Por favor, ingrese un valor más largo.",
    err_2006: "Lo siento, el valor no debe exceder los 30 caracteres. Por favor, ingrese un valor más corto.",
    err_2007: "Lo siento, debe seleccionar una imagen. Por favor, elija una imagen.",
    err_2008: "Lo siento, no se permiten caracteres especiales. Solo puede utilizar números y letras.",
    err_2009: "Lo siento, debe seleccionar un archivo. Por favor, elija un archivo.",
    err_2010: "Lo siento, solo se aceptan archivos. Por favor, cargue un archivo en un formato válido.",
    err_2011: "Lo siento, solo se puede cargar un archivo de hasta 25 MB. Por favor, elija un archivo más pequeño.",
    err_2012: "Lo siento, ya ha cargado un archivo con este nombre. Por favor, elija un nombre de archivo diferente.",
    err_2013:
      "Lo siento, tiene una operación en curso. Recibirá un correo cuando se complete. Intente de nuevo más tarde.",
    err_2014:
      "Lo siento, en el paquete de prueba puede cargar hasta 10 códigos QR. Para más, por favor actualice su paquete.",
    err_2015:
      "Lo siento, su archivo Excel debe tener un máximo de 400 filas para ser cargado correctamente. Actualícelo y vuelva a intentarlo.",
    err_2016:
      "Lo siento, no se han encontrado archivos para la descarga masiva. Intente de nuevo después de cargar el archivo.",
    err_2017: "Lo siento, ha superado el límite de carga masiva. Por favor, contáctenos.",
    err_2018: "Lo siento, debe eliminar al menos un archivo anterior para cargar un nuevo archivo Excel.",
    err_2019:
      "Lo siento, se han detectado enlaces inapropiados o redirigidos en sus datos. Por favor, modifíquelos y vuelva a intentarlo.",
    err_2020:
      "Lo siento, solo se pueden cargar archivos Excel con extensión .xlsx. Verifique el tipo de archivo y vuelva a intentarlo.",
    err_2021: "Lo siento, solo se pueden cargar archivos Excel de hasta 2 MB. Por favor, elija otro archivo.",
    err_2022: "Lo siento, necesita tener al menos 2 códigos QR para realizar esta operación.",
    err_2023: "Lo siento, debe seleccionar al menos 1 código QR secundario.",
    err_2024:
      "Lo siento, para realizar esta operación, necesita tener al menos 1 código QR principal. Por favor, seleccione al menos 1 código QR secundario.",
    err_2025: ({ named }) =>
      `Lo siento, solo se pueden cargar archivos de hasta ${named(
        "value"
      )} MB. Por favor, cargue un archivo más pequeño.`,
    err_2026:
      "Lo siento, solo se pueden cargar imágenes con un ancho máximo de 1000 px. Por favor, ajuste las dimensiones de la imagen para que no exceda los 1000 px de ancho o altura.",
    err_2027: "Lo siento, el formato del dominio es incorrecto.",
    err_2028: "Lo siento, este dominio ya ha sido agregado.",
    err_2029: "Lo siento, no se pudo verificar el registro CNAME.",
    err_2030: "Error; No se puede agregar este dominio, el registro CNAME no se pudo verificar.",
    validation: {
      credit_card: {
        card_invalid: "Tarjeta inválida; ¡Asegúrese de haber ingresado el número de tarjeta correcto!",
        card_pattern_invalid: "Tarjeta inválida; ¡Asegúrese de haber ingresado el número de tarjeta correcto!",
        card_checksum_invalid: "Tarjeta inválida; ¡Asegúrese de haber ingresado el número de tarjeta correcto!",
        card_length_invalid: "Tarjeta inválida; ¡Asegúrese de haber ingresado el número de tarjeta correcto!",
        card_cvc_invalid: "CVC inválido; ¡Asegúrese de haber ingresado el número de CVC correcto!",
        card_expiration_month_invalid: "Mes inválido; ¡Asegúrese de haber ingresado el mes correcto!",
        card_expiration_year_invalid: "Año inválido; ¡Asegúrese de haber ingresado el año correcto!",
      },
    },
  },
  PAYMENT: {
    unknown_reason: "Razón desconocida",
    payment: "Pago",
    successful: "EXITOSO",
    failed: "FALLIDO",
    awaiting: "EN ESPERA",
    reason: "Razón",
    payment_successful_desc: `Estimado cliente,
    Su pedido ha sido recibido con éxito, su paquete ha sido asignado a su cuenta.    
    Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros. Estaremos encantados de ayudarle.
    ¡Gracias por su interés y confianza!`,
    payment_failed_desc: `Estimado cliente,
    Lo sentimos, hemos detectado un error en su pago y puede recibir asistencia de nuestra parte con respecto al asunto.
    Estaremos encantados de ayudarle.`,
    payment_awaiting_desc: `Estimado cliente,
    ¡Gracias por su pedido! El último paso para procesar su pedido es completar el pago. 
    Sin embargo, al revisar en nuestro sistema, hemos detectado que su pago aún no se ha completado.
    Por favor, complete su pago realizando una transferencia a nuestras cuentas bancarias.`,
    title_success: () => `<strong>Gracias</strong>, ¡Su pedido ha sido recibido!`,
    title_fail: () => `<strong>Lo sentimos</strong>, ¡Pago fallido!`,
    title_awaiting: () => `<strong>Gracias</strong>, ¡Su pedido ha sido recibido!`,
    err_105: "El pago fue cancelado por el usuario",
    err_000: "El proceso de pago ha fallado",
  },
  SNACKBAR: {
    qrcode_updated: "¡Código QR actualizado!",
    qrcode_created: "¡Código QR creado!",
    verify_email: "Es necesario verificar el correo electrónico",
    login_successful: "Ingreso exitoso",
    logged_out: "¡Cerrado sesión!",
    registeration_successful: "Registro exitoso",
  },
  DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Bienvenido al recorrido paso a paso",
    STEP_BY_STEP_TOUR_DESC:
      "¡Hola usuario! Hemos notado que es tu primera vez aquí. Este recorrido te guiará a través de las secciones y te explicará cómo funcionan las cosas. Si deseas omitirlo, puedes hacer clic en el botón de omitir abajo.",
    STEP_BY_STEP_TOUR_SKIP: "Omitir",
    STEP_BY_STEP_TOUR_START: "Iniciar",
    STEP_BY_STEP_TOUR_CREATE_QR: "Crear Código QR",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "Para crear un código QR, puedes usar el botón arriba.",
  },
  POPUP: {
    error_title: "¡Error!",
    CHOOSE_ONE: "Seleccione el tipo de código QR",
    TRANSPARENT_QR_CODE: "Código QR Transparente",
    STANDART_QR_CODE: "Código QR Estándar",
    BANNER_QR_CODE: "Código QR con Banner",
    GO_BACK: "Volver",
    QR_CODE_BACKGROUND_PICTURE: "Quiero poner la imagen de fondo que elija en mi código QR",
    QR_CODE_COLOR: "Quiero personalizar la forma y colores del código QR",
    QR_CODE_BANNER: "Quiero crear un banner y personalizar el tamaño del código QR",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
      <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Mejorar</span> <span style="font-weight: 200; color:#919191">para crear más</span> <strong class="text-h4 button_green--text">Actualizar</strong></h1>
      <p style="font-size: 1rem; max-width: 360px; text-align: justify;">Has alcanzado el límite de <strong class="orange--text">${named(
        "total"
      )}</strong> códigos QR en tu paquete. Para crear más códigos QR, revisa nuestros paquetes premium.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Actualizar <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>`,
    packageController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">¡Tu paquete ha <strong style="color: tomato">expirado!</strong></h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paquete</strong> adquiere un paquete para continuar utilizando QR Code Matic.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Actualizar <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    upgradeToPro: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Esta característica es exclusiva para <strong style="color: tomato">PRO</strong></h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Paquete</strong> adquiere un paquete para continuar utilizando QR Code Matic.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Actualizar <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    allowedTypeController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Actualiza para continuar usando <strong class="orange--text">${named(
        "typeName"
      )}</strong></h1>
      <p>Tu paquete no cubre este tipo de código QR. Si actualizas al plan <strong>Pro</strong>, podrás usar este y otros códigos QR premium sin límites.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Actualizar <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    getMoreInformation: ({ named }) => `
    <div>
     <p>La configuración de idioma te permite ingresar diferentes contenidos para cada idioma que elijas. El usuario final del código QR verá un menú de idiomas en la esquina superior derecha si añades múltiples idiomas.</p>
     <p>El proceso es el siguiente:</p>
     <ol>
      <li>Primero, haz clic en el botón <span class="font-weight-bold">"</span>+</span></li>
      <li>Selecciona los idiomas que deseas agregar. Puedes seleccionar varios idiomas. Luego, elige el idioma para el contenido copiado. (Esta opción puede no estar disponible para este tipo de código QR.) Haz clic en <span class="font-weight-bold">"Aceptar"</span></li>
      <li>Ahora, puedes hacer clic en los íconos de bandera para cada idioma agregado y presionar el botón <span class="font-weight-bold">"Activo"</span> para cambiar el contenido para ese idioma específico.</li>
      <li>Para eliminar un idioma agregado, haz clic en el botón <span class="font-weight-bold">"Eliminar"</span>. Recuerda que siempre debe haber al menos un idioma.</li>
      <li>Al hacer clic en <span class="font-weight-bold">"Establecer como predeterminado"</span>, podrás guardar este idioma como predeterminado y los usuarios verán este idioma cuando visiten tu página.</li>
     </ol>
    </div>
    `,
    getInfoAboutTitle: ({ named }) => `
    <div>
   <p class="text--justify" style="font-size: 14px">El título de tu código QR aparecerá debajo de la imagen en la página de la lista de códigos QR como se muestra a continuación. Después de completar la creación de tu código QR, serás redirigido a esta página.<br>
   Puedes editar el título del código QR en cualquier momento.</p>
   <div class="d-flex justify-content-center" style="width: 100%;">
      <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
   </div>
  </div>
    `,
  },

  BULK: {
    create_new_child: "Quiero crear nuevos códigos QR secundarios",
    update_childs_data: "Quiero cambiar los detalles de mis códigos QR secundarios",
    update_data: "Quiero actualizar la información general de todos mis códigos QR secundarios",
    zip_request: "Quiero descargar todos mis códigos QR en formato SVG",
    zip_downloaded: "¡Archivo zip descargado!",
    download_master: "Descargar archivo Excel principal",
    update_config_button_text:
      "Quiero actualizar el color, diseño y otras configuraciones de todos mis códigos QR secundarios",
    update_data_description: () => `<p style="text-align: center">
    Selecciona los campos que deseas actualizar para los códigos QR bajo el master, en los cuadros
    de abajo. Todos los códigos QR bajo el master se actualizarán automáticamente con los ajustes
    que has seleccionado en el archivo excel(vcard-bulk-update).
  </p>
  <p style="text-align: center">
    Por ejemplo, si seleccionas el campo <strong>avatar</strong>, al presionar <strong>Actualizar</strong>,
    el <strong>valor de avatar</strong> que escribiste en el archivo excel(vcard-bulk-update) se aplicará
    a todos los códigos QR bajo el master.
  </p>`,
    update_config_description: () => `<p>
  Este proceso se utiliza para aplicar los cambios realizados en el <strong>master</strong> a todos los códigos QR secundarios de una sola vez. Todas las características mencionadas en la lista del master se aplicarán a los códigos QR secundarios.
  <ul>
    <li>Configuración de color de todos los códigos QR</li>
    <li>Diseño de todos los códigos QR</li>
  </ul>
</p>
<p>
  Al realizar la actualización, el diseño de todos los códigos QR secundarios será el mismo que el del master.
</p>`,
    create_description:
      () => `Para crear códigos QR en masa, arrastra el archivo Excel que has preparado al área de carga
y haz clic en el botón <strong class="green--text">CARGAR</strong> que aparecerá.`,
    update_options_title: "Opciones de actualización",
    avatar: "Avatar",
    are_you_sure: "¿Estás seguro?",
    see_qr_codes: "Ver mis Códigos QR",
    see_qr_codes_tooltip: "Puedes ver los códigos QR secundarios y volver a convertirlos en master.",
    background: "Fondo",
    settings: "Configuraciones",
    advanced_settings: "Configuraciones avanzadas",
    update_data_button_text: "Actualizar",
    update_config_button_text: "Actualizar diseño y colores de los códigos QR secundarios",
    show_previously_uploaded: "Mostrar mis cargas de Excel anteriores",
    reached_upload_limit: "Has alcanzado el límite de carga. Necesitas eliminar uno o más archivos de la lista.",
    upload_error_text:
      "Los datos que ingresaste en el archivo Excel no están en el formato correcto. Por favor, revisa los campos incorrectos indicados a continuación y vuelve a intentar cargar el archivo.",
    back_to_upload: "Volver a la carga",
    select_from_list: "Selecciona la acción que deseas realizar desde abajo",
  },

  NEWS: {
    firstVisitMessage: ({ named }) => `
    <p> Hoy les traemos una emocionante noticia. ¡Hemos renovado nuestro sistema de códigos QR! Hemos realizado una serie de mejoras para ofrecerles una mejor experiencia.</p>
    <p>Los códigos QR se han convertido en una herramienta imprescindible para compartir información de manera rápida y fácil. Nosotros, al desarrollar esta tecnología, trabajamos para ofrecerles el mejor servicio.</p>
    `,
    firstVisitFullMessage: ({ named }) => `
    <p> Hoy les traemos una emocionante noticia. ¡Hemos renovado nuestro sistema de códigos QR! Hemos realizado una serie de mejoras para ofrecerles una mejor experiencia.</p>

    <p>Los códigos QR se han convertido en una herramienta imprescindible para compartir información de manera rápida y fácil. Nosotros, al desarrollar esta tecnología, trabajamos para ofrecerles el mejor servicio.</p>

    <p> Nuestro nuevo sistema de códigos QR tiene un diseño más amigable para el usuario. Ahora será suficiente con dar menos pasos para ingresar a nuestro sitio y crear códigos QR. 
    Hemos simplificado nuestra interfaz y te hemos librado de clics innecesarios. Hemos reorganizado el diseño para ofrecerte una experiencia más rápida, fluida y sin problemas.</p>

    <p>Además, hemos agregado nuevas características para hacer que nuestros códigos QR sean más funcionales y flexibles. Ahora podrás personalizar los códigos QR que creas con más datos. 
    Puedes agregar enlaces de tu sitio web o perfiles de redes sociales, crear menús con los deliciosos sabores de tu restaurante, o generar un vCard con tu dirección de correo electrónico o número de teléfono. 
    Te ofrecemos muchas opciones para ampliar el uso de tus códigos QR.</p>

    <p>La seguridad en este amplio campo de uso es nuestra prioridad. En nuestro nuevo sistema de códigos QR, hemos implementado medidas de seguridad adicionales para proteger tus datos e información. 
    Hemos creado una base más sólida para garantizar la seguridad de tu información personal y sensible.</p>

    <p> La velocidad de nuestros códigos QR también ha mejorado. Gracias a un algoritmo mejorado y sistemas optimizados, podrás escanear tus códigos QR más rápido y completar tus tareas en menos tiempo. 
    Hemos actualizado nuestra infraestructura tecnológica para que puedas utilizar tu tiempo de manera más eficiente.</p>

    <p> Los comentarios y necesidades de nuestros valiosos usuarios siempre son importantes para nosotros. Por eso, en nuestro renovado sistema de códigos QR, trabajamos constantemente para ofrecerte una mejor experiencia. 
    Si tienes alguna pregunta, sugerencia o comentario sobre el uso de nuestro sitio, no dudes en comunicárnoslo. Tu satisfacción es nuestro éxito. ¡Bienvenido!</p>
    
    `,

    HELLO: "¡Hola, nos renovamos!",
    we_are_improving_to_provide_you_with_better_service: "Nos estamos mejorando para ofrecerte un mejor servicio",
  },

  TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>Términos y Condiciones de Uso del Sitio QR Code Matic</h1>
      <hr>
      <p>Los clientes que utilicen este sitio para generar códigos QR y creen códigos QR, aceptan los términos que se detallan a continuación. Además, las páginas web de este sitio y todas las páginas relacionadas (‘Sitio’) son propiedad de Medyax Bilişim Hizmetleri, con sede en FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa, y son operadas por esta empresa (‘Empresa’). Usted (‘Usuario’) acepta que al utilizar los servicios ofrecidos en el sitio, está sujeto a los términos y condiciones establecidos a continuación, y confirma que tiene derecho y capacidad legal para firmar un contrato conforme a las leyes aplicables y que tiene más de 18 años. Al continuar utilizando el sitio, usted acepta los términos y condiciones de este contrato, habiéndolo leído y comprendido.</p>
      <p>Este contrato impone derechos y obligaciones a ambas partes en relación con el sitio en cuestión, y las partes declaran que cumplirán con dichos derechos y obligaciones de manera completa, correcta y puntual al aceptar este contrato.</p>

      <h2>1. RESPONSABILIDADES</h2>
        <p> a. La Empresa se reserva el derecho de modificar los precios y los productos y servicios ofrecidos en cualquier momento.</p>
        <p> b. La Empresa se compromete a proporcionar los servicios del sitio al usuario, salvo en caso de fallos técnicos.</p>
        <p> c. El Usuario acepta que no realizará ingeniería inversa ni buscará obtener el código fuente del sitio, y será responsable de los daños causados a terceros, así como de las acciones legales que puedan ser emprendidas en su contra en caso de infringir esta condición.</p>
        <p> d. El Usuario se compromete a no crear, compartir ni distribuir contenido que sea contrario a la moral y las buenas costumbres, que infrinja leyes o los derechos de terceros, que sea engañoso, agresivo, obsceno, pornográfico, que viole derechos de autor o promueva actividades ilegales. En caso contrario, será completamente responsable por los daños causados y el sitio se reserva el derecho de suspender o eliminar cuentas y tomar acciones legales. Además, el sitio se reserva el derecho de compartir información sobre las cuentas de los usuarios con las autoridades judiciales cuando sea requerido.</p>
        <p> e. Las relaciones entre los miembros del sitio o entre los usuarios y terceros son responsabilidad exclusiva de los usuarios.</p>

      <h2>2. DERECHOS DE PROPIEDAD INTELECTUAL</h2>
    <p> 2.1. Todos los derechos de propiedad intelectual relacionados con el título, nombre comercial, marca, patente, diseño, códigos de sistema, información y métodos, ya sean registrados o no, son propiedad de la empresa operadora del sitio o de las partes indicadas, y están protegidos por las leyes nacionales e internacionales. El acceso al sitio o el uso de los servicios ofrecidos no otorgan al usuario ningún derecho sobre estos derechos de propiedad intelectual.</p>
    <p>2.2. La información disponible en el sitio no puede ser reproducida, publicada, copiada, presentada y/o transmitida sin el consentimiento previo de la Empresa. El sitio o sus partes no pueden ser utilizadas en otro sitio web sin autorización.</p>

  <h2>3. INFORMACIÓN CONFIDENCIAL</h2>
    <p> 3.1. La Empresa no revelará la información personal proporcionada por los usuarios del sitio a terceros. Esta información personal incluye nombre, dirección, número de teléfono, correo electrónico y otros datos que permiten identificar al usuario, y será denominada ‘Información Confidencial’.</p>
    <p> 3.2. El Usuario acepta que la empresa pueda compartir su información de contacto, situación de portafolio y datos demográficos con sus afiliadas o empresas del grupo para fines de marketing, como promociones, anuncios, campañas o encuestas.</p>
    <p>3.3. La Información Confidencial podrá ser divulgada a autoridades oficiales en caso de requerirse por ley o por solicitud de una autoridad competente.</p>

  <h2>4. NO GARANTÍA</h2>
    <p>Este contrato se aplica en la medida permitida por las leyes aplicables. Los servicios ofrecidos por la Empresa se proporcionan "TAL CUAL" y "SEGÚN DISPONIBILIDAD", sin ninguna garantía expresa o implícita, incluida la comerciabilidad, idoneidad para un propósito particular o no infracción de derechos.</p>

  <h2>5. REGISTRO Y SEGURIDAD</h2>
    <p> El Usuario debe proporcionar información de registro precisa, completa y actualizada. De lo contrario, se considerará que el contrato ha sido incumplido, y la cuenta del usuario podrá ser cerrada sin previo aviso. El Usuario es responsable de la seguridad de su contraseña y cuenta en el sitio y en sitios de terceros. En caso de pérdida de datos, violación de seguridad o daños en el hardware y dispositivos, la Empresa no será responsable. Se recomienda probar los códigos QR en varios dispositivos antes de enviarlos a impresión. La Empresa no es responsable por errores de impresión o creación de los códigos QR.</p>

  <h2>6. FUERZA MAYOR</h2>
    <p>Las partes no serán responsables por el incumplimiento de sus obligaciones contractuales si se deben a circunstancias fuera de su control, como desastres naturales, incendios, explosiones, guerras, conflictos, huelgas, cortes de electricidad, fallos en infraestructura o problemas en internet. Durante este período, las obligaciones del contrato estarán suspendidas.</p>

  <h2>7. INTEGRIDAD Y APLICABILIDAD DEL CONTRATO</h2>
    <p>Si alguna de las condiciones de este contrato se considera parcialmente o completamente inválida, el resto del contrato continuará siendo válido.</p>

  <h2>8. CAMBIOS EN EL CONTRATO</h2>
    <p>La Empresa se reserva el derecho de modificar los servicios ofrecidos y los términos de este contrato en cualquier momento. Las modificaciones serán efectivas a partir de su publicación en el sitio. El Usuario es responsable de revisar estos cambios, y al continuar utilizando los servicios, acepta dichos cambios.</p>

  <h2>9. NOTIFICACIONES</h2>
    <p>Todas las notificaciones relacionadas con este contrato se enviarán a través de los correos electrónicos indicados por la Empresa y el Usuario. El Usuario acepta que la dirección de correo electrónico proporcionada es válida para recibir notificaciones y que notificará por escrito cualquier cambio en esta dirección dentro de los 5 días posteriores al cambio, de lo contrario, se considerará válida cualquier notificación enviada a esa dirección.</p>
  
    <h2>10. CONTRATO DE PRUEBA</h2>
    <p>En caso de cualquier disputa relacionada con el contrato, los registros de las partes, los registros informáticos y las transmisiones de fax serán considerados como pruebas en virtud de la Ley 6100 de Procedimientos Civiles, y el Usuario acepta no impugnar estos registros.</p>

  <h2>11. RESOLUCIÓN DE CONFLICTOS</h2>
    <p>En caso de cualquier disputa relacionada con la interpretación o aplicación de este contrato, los tribunales de la Corte Judicial de Bursa (Centro) serán los competentes.</p>

  <h2>12. TARIFAS</h2>
    <p>Al registrarse por primera vez, se le asignará un paquete de prueba que le permitirá probar el sistema. La duración de este paquete de prueba y los resultados generados será de 5 días, con la opción de generar salidas en formato .png. El Usuario podrá elegir el tipo de QR Code (estático/dinámico) en la página de finalización del proceso de creación del código QR. La selección del tipo de código QR es responsabilidad del Usuario. Las salidas vectoriales solo están disponibles para paquetes corporativos. La Empresa se reserva el derecho de cambiar la duración y las condiciones del paquete de prueba en cualquier momento. Después de los 5 días, el Usuario deberá seleccionar un paquete de pago para continuar utilizando los códigos QR creados. Si no se renueva el paquete, los códigos QR creados se eliminarán. Si desea evitar la eliminación, deberá renovar su paquete al menos un mes antes de la expiración.</p>

  <h2>13. ELIMINACIÓN DE CUENTA</h2>
    <p>El Usuario podrá eliminar su cuenta desde la sección "Mi Cuenta" en el menú superior, seleccionando la opción "Eliminar Cuenta". Alternativamente, puede enviar una solicitud de eliminación a info@qrcodematic.com. Tenga en cuenta que al eliminar su cuenta, todo el contenido y los códigos QR creados se perderán permanentemente. Al confirmar la eliminación, acepta que ha leído, entendido y aceptado estos términos.</p>
    `,
  },

  CROPPER: {
    confirm_selected_area: "Aplicar Imagen",
    confirm_selection_tooltip: "Confirma el área seleccionada",
    cropper_success: "Aplicado con éxito",
  },
  STATISTICS: {
    show_all: "Mostrar Todo",
    last_x_days: ({ named }) => `Últimos ${named("days")} días`,
    trial_warning:
      "<strong>DEMO: </strong> Las estadísticas a continuación se muestran como ejemplo en una cuenta <strong>de prueba</strong>.",
  },
  HELP: {},
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "¿No tienes cuenta?",
      SIGNUP_BUTTON: "Registrarse",
      FORGOT_BUTTON: "¿Olvidaste tu contraseña?",
      BACK_BUTTON: "Regresar",
      PRIVACY: "Privacidad",
      LEGAL: "Legal",
      CONTACT: "Contacto",
    },
    LOGIN: {
      TITLE: "Acceder a la cuenta",
      BUTTON: "Registrarse",
    },
    FORGOT: {
      TITLE: "¿Olvidaste tu contraseña?",
      DESC: "Ingresa tu correo electrónico para restablecer tu contraseña",
      SUCCESS: "Tu cuenta ha sido restablecida con éxito.",
    },
    REGISTER: {
      TITLE: "Registrarse",
      DESC: "Introduce tus datos para crear tu cuenta",
      SUCCESS: "Tu cuenta ha sido registrada con éxito.",
    },
    INPUT: {
      EMAIL: "Correo electrónico",
      FULLNAME: "Nombre completo",
      PASSWORD: "Contraseña",
      CONFIRM_PASSWORD: "Confirmar contraseña",
      USERNAME: "Nombre de usuario",
    },
    VALIDATION: {
      INVALID: "{{name}} no es válido",
      REQUIRED: "{{name}} es obligatorio",
      MIN_LENGTH: "{{name}} debe tener al menos {{min}} caracteres",
      AGREEMENT_REQUIRED: "Se requiere aceptar los términos y condiciones",
      NOT_FOUND: "{{name}} no encontrado",
      INVALID_LOGIN: "Datos de acceso incorrectos",
      REQUIRED_FIELD: "Campo obligatorio",
      MIN_LENGTH_FIELD: "Longitud mínima del campo:",
      MAX_LENGTH_FIELD: "Longitud máxima del campo:",
      INVALID_FIELD: "Campo no válido",
    },
  },
  ICONSELECT: {
    animals: "animales",
    landscape: "paisaje",
    people: "personas",
    vehicles: "vehículos",
    communication: "comunicación",
    technology: "tecnología",
    business: "Catálogo / Menú",
    food: "comida",
  },
};
