// italiananca
export const locale = {
  greet: ({ named }) => `Ciao <span class="font-weight-bold">${named("userName")}!</span>`,
  spanish: "Spagnolo",
  german: "Tedesco",
  turkish: "Turco",
  english: "Inglese",
  true: "Sì",
  false: "No",
  date: "Data",
  time: "Ora",
  dynamic: "Dinamico",
  static: "Statico",
  hi: "Ciao",
  create: "Crea",
  update: "Aggiorna",
  change: "Cambia",
  template: "Modello",
  qr_list: "Elenco QR",
  QR_TITLE: "Titolo del Codice QR",
  qr_code_list: "Elenco dei codici QR",
  qrcode_document: "Documento",
  qrcode_document_subtext: "Condividi i tuoi documenti",
  qrcode_website: "Sito Web (Link)",
  qrcode_website_subtext: "Reindirizza i tuoi link",
  qrcode_vcard: "vCard Plus",
  qrcode_vcard_subtext: "Crea una vCard con informazioni di contatto",
  qrcode_smsdynamic: "SMS",
  qrcode_smsdynamic_subtext: "Crea un mittente SMS",
  qrcode_sms: "SMS",
  qrcode_sms_subtext: "Crea un messaggio SMS",
  qrcode_business: "Carta Aziendale",
  qrcode_business_subtext: "Crea la tua pagina aziendale",
  qrcode_socialmedia: "Social Media",
  qrcode_socialmedia_subtext: "Reindirizza ai tuoi account social media",
  qrcode_pdf: "PDF",
  qrcode_pdf_subtext: "Crea un visualizzatore PDF",
  qrcode_restaurant: "Menu del Ristorante",
  qrcode_restaurant_subtext: "Crea un menu personalizzato avanzato per il tuo ristorante!",
  qrcode_pdfmenu: "Catalogo / Menu PDF",
  qrcode_pdfmenu_subtext: "Crea un catalogo o menu caricando più PDF",
  qrcode_catalog: "Catalogo / Menu Online",
  qrcode_catalog_subtext: "Crea un catalogo dal PDF selezionato",
  qrcode_equipment: "Scheda Attrezzatura",
  qrcode_equipment_subtext: "Tieni traccia dello stato delle tue attrezzature",
  qrcode_staffcard: "Carta del Personale",
  qrcode_staffcard_subtext: "Carta del personale per i tuoi dipendenti",
  qrcode_emergency: "Emergenza",
  qrcode_emergency_subtext: "Scheda informativa per situazioni di emergenza",
  qrcode_event: "Evento",
  qrcode_event_subtext: "Applicazione per inviti a concerti, fiere e altri eventi",
  qrcode_location: "Posizione",
  qrcode_location_subtext: "Crea indicazioni per gli indirizzi della tua organizzazione",
  qrcode_music: "Musica",
  qrcode_music_subtext: "Carica e riproduci una canzone o una registrazione audio",
  qrcode_pet: "Animale Domestico",
  qrcode_pet_subtext: "Crea una scheda di monitoraggio con le informazioni del tuo animale domestico",
  qrcode_museum: "Museo",
  qrcode_museum_subtext: "Crea una scheda informativa per opere d'arte, luoghi storici",
  qrcode_coupon: "Coupon",
  qrcode_coupon_subtext: "Applicazione per distribuire coupon speciali per le tue promozioni",
  qrcode_rate: "Valutazione",
  qrcode_rate_subtext: "Applicazione in cui gli utenti possono valutare e lasciare commenti sul tuo prodotto",
  qrcode_store: "Applicazioni",
  qrcode_store_subtext: "Crea link per Apple Store, Google Play delle tue applicazioni",
  qrcode_call: "Chiamata",
  qrcode_call_subtext: "Crea una scheda per chiamate telefoniche",
  qrcode_text: "Testo",
  qrcode_text_subtext: "Scheda in cui puoi condividere e copiare un testo",
  qrcode_mail: "E-mail",
  qrcode_mail_subtext: "Crea una scheda di contatto e-mail",
  qrcode_whatsapp: "WhatsApp",
  qrcode_whatsapp_subtext: "Crea un link di contatto WhatsApp",
  qrcode_instagram: "Instagram",
  qrcode_instagram_subtext: "Crea un link di contatto Instagram",
  qrcode_facebook: "Facebook",
  qrcode_facebook_subtext: "Crea un link di contatto Facebook",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "Crea un link di contatto X / Twitter",
  qrcode_linkedin: "LinkedIn",
  qrcode_linkedin_subtext: "Crea un link di contatto LinkedIn",
  qrcode_youtube: "YouTube",
  qrcode_youtube_subtext: "Condividi un video YouTube",
  qrcode_pinterest: "Pinterest",
  qrcode_pinterest_subtext: "Crea un link di contatto Pinterest",
  qrcode_paypal: "PayPal",
  qrcode_paypal_subtext: "Crea un link per pagamenti o donazioni PayPal",
  qrcode_wifidynamic: "Wi-Fi",
  qrcode_wifidynamic_subtext: "Condividi le informazioni di connessione Wi-Fi",
  qrcode_websitestatic: "Sito Web (Statico)",
  qrcode_websitestatic_subtext: "Codice QR statico contenente l'URL del tuo sito web",
  qrcode_vcardstatic: "vCard (Statico)",
  qrcode_vcardstatic_subtext: "Codice QR che può essere salvato come .vcf sul telefono",
  qrcode_phone: "Telefono",
  qrcode_phonestatic: "Telefono (Statico)",
  qrcode_phone_subtext: "Telefono",
  qrcode_textstatic: "Testo (Statico)",
  qrcode_textstatic_subtext: "Testo (Statico)",
  qrcode_smsstatic: "SMS (Statico)",
  qrcode_smsstatic_subtext: "SMS (Statico)",
  qrcode_wifistatic: "Wi-Fi (statico)",
  qrcode_wifistatic_subtext: "Wi-Fi (statico)",
  qrcode_multiplelink: "Link multipli",
  qrcode_multiplelink_subtext: "Aggiungi più link con opzione di reindirizzamento casuale",
  qrcode_gallery: "Galleria Immagini",
  qrcode_gallery_subtext: "Galleria Immagini",
  qrcode_customerservice: "Contatto Reparto",
  qrcode_customerservice_subtext: "Crea link per contatti dei reparti",
  qrcode_title_wifi: "Wi-Fi",
  qrcode_title_vcard: "Vcard Plus",
  qrcode_title_sms: "SMS",
  qrcode_title_gallery: "Galleria",
  qrcode_title_multiplelink: "Link Multipli",
  qrcode_title_music: "Musica",
  qrcode_title_website: "Sito Web",
  qrcode_title_whatsapp: "WhatsApp",
  qrcode_title_email: "E-mail",
  qrcode_title_pdfmenu: "Catalogo/Menu PDF",
  qrcode_title_catalog: "Catalogo/Menu Online",
  qrcode_title_text: "Testo",
  qrcode_title_coupon: "Coupon",
  qrcode_title_pinterest: "Pinterest",
  qrcode_title_instagram: "Instagram",
  qrcode_title_twitter: "Twitter",
  qrcode_title_rate: "Valutazione",
  qrcode_title_business: "Carta Aziendale",
  qrcode_title_phone: "Telefono",
  qrcode_title_staffcard: "Carta Personale",
  qrcode_title_paypal: "PayPal",
  qrcode_title_store: "Applicazioni",
  qrcode_title_call: "Chiamata",
  qrcode_title_location: "Posizione",
  qrcode_title_pdf: "PDF",
  qrcode_title_museum: "Museo",
  qrcode_title_linkedin: "LinkedIn",
  qrcode_title_youtube: "YouTube",
  qrcode_title_pet: "Animale Domestico",
  qrcode_title_facebook: "Facebook",
  qrcode_title_event: "Evento",
  qrcode_title_equipment: "Scheda Attrezzatura",
  qrcode_title_emergency: "Scheda Emergenza",
  qrcode_title_restaurant: "Ristorante",
  qrcode_title_socialmedia: "Social Media",
  qrcode_all: "- Mostra Tutto -",
  page_not_found_text: "Ops! Il contenuto che stai cercando non è stato trovato",
  go_home: "Home",
  link_is_copied_to_clipboard: "Link copiato!",
  select_pdf_by_clicking: "Clicca sul file PDF per attivarlo",
  select_content_by_clicking: "Clicca sul file per attivarlo",
  clear_all_fields: "Pulisci il modulo",
  search_type: "Seleziona Tipo di Carta",
  create_quickly: "Creazione rapida",
  empty: "Vuoto",
  click_lock_to_unlock: "Premi l'icona del lucchetto per modificare questo campo",
  user_folder: "Cartella Utente",
  this_field_is_required: "Questo campo è obbligatorio",
  you_dont_have_any_saved_design: "Salva il design e usalo per i prossimi QR code",
  you_can_save_your_current_design_by_clicking_button_below:
    "Potrai applicare lo stesso design ai futuri QR code senza dover riselezionare colori e forme con un solo clic.",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature:
    "Per utilizzare questa funzione devi salvare questo QR code.",
  SAVE_CURRENT_DESIGN: "+ SALVA DESIGN ATTUALE",
  you_have_to_update_current_changes_first: "Devi prima aggiornare le modifiche correnti",
  scan_result_good: "Scansione Buona",
  scan_result_bad: "Scansione Debole",
  LANGUAGE: "Seleziona Lingua",
  YES: "SÌ",
  ACTIVE_LANGUAGE: "Lingua Attiva",
  ADD_SOME_INFO: "Inserisci informazioni",
  CONTACT_INFO: "Informazioni di contatto",
  FULL_NAME: "Nome completo",
  FIRSTNAME: "Nome",
  LASTNAME: "Cognome",
  COMPANY_NAME: "Azienda",
  COMPANY_ADDRESS: "Indirizzo Azienda",
  JOB_TITLE: "Posizione",
  JOBTITLE: "Posizione",
  DEPARTMENT: "Dipartimento",
  BLOOD_GROUP: "Gruppo Sanguigno",
  FOLDER_NO: "Numero Cartella",
  DATE_OF_START: "Data di Inizio",
  COMPANY: "Azienda",
  COUNTRY: "Paese",
  STATE: "Provincia",
  MAIL: "E-mail",
  WEBSITE: "Link",
  PHONE: "Telefono",
  SMS: "SMS",
  HOME: "Home",
  WHO_ARE_WE: "Chi siamo",
  icon: "Icona",
  show_more: "Mostra altro",
  remove_this_song: "Rimuovi questa canzone",
  select_cover_image: "Seleziona immagine di copertina",
  select_audio_file: "Seleziona file audio",
  delete_all: "Elimina Tutto",
  spotify_settings: "Impostazioni Spotify",
  light_theme: "Tema Chiaro",
  dark_theme: "Tema Scuro",
  you_can_paste_single_song_or_playlist:
    "Puoi incollare una singola canzone o playlist. Il link corretto dovrebbe apparire così:",
  add_songs_from_local_files: "Aggiungi brani da file locali",
  add_songs_from_spotify: "Aggiungi brani da Spotify",
  SETTINGS: "Impostazioni",
  APPLY: "APPLICA",

  crops_image: "Trasferisci immagine alla carta selezionata",
  rotate_right: "Ruota a destra",
  rotate_left: "Ruota a sinistra",
  upload_image: "Carica immagine dal computer",
  reset: "Pulisci modulo",
  upload: "Carica da PC",
  transfer: "Mostra immagine",

  adress_icon: "Icona indirizzo",
  area_icon: "Icona area",

  awaiting: "In attesa",
  approved: "Approvato",
  rejected: "Rifiutato",
  download_excel: "Scarica Excel",
  excel_error: "Si è verificato un errore durante il download del file Excel.",

  card_owner: "Titolare della carta",
  expires_at: "Data",
  mounth: "MM",
  years: "AA",
  full_name: "Nome Cognome",

  type_city_name: "Inserisci Città e Provincia",
  your_zip_code: "CAP",
  id_number: "Numero Identificativo (ID)",
  tax_number: "Codice Fiscale",
  tax_office: "Ufficio Imposte",

  qr_code_type: "Puoi cambiare il tipo di QR Code. (Importante!)",

  static_qr_code_why_delete:
    "Al termine del tuo pacchetto, i codici QR creati vengono conservati per un solo giorno. Successivamente tutti i dati relativi a questo codice QR verranno eliminati. Se vuoi stampare il codice QR creato, devi scaricarlo e salvarlo prima che venga cancellato. Se desideri conservare i tuoi codici QR statici, puoi aggiornare il tuo pacchetto.",
  static_qr_code_why_title: "Perché i miei codici QR statici vengono eliminati?",

  WHO_ARE_WE_1:
    "La nostra azienda di tecnologie software, completamente attrezzata, è stata fondata nel 1997 con il motto 'ci sono molte cose da fare e molto codice da scrivere'. Il nostro fondatore ha immaginato un'azienda che offre ai suoi clienti servizi con valori reali e risultati misurabili. Oggi continuiamo a offrire servizi che vanno ben oltre le aspettative.",

  WHO_ARE_WE_2:
    "Abbiamo percorso molta strada finora. Nel corso degli anni abbiamo aperto le porte a talentuosi geni del software e siamo diventati ancora più forti insieme. Continuiamo questo viaggio combinando l’esperienza acquisita con le tecnologie odierne e con la passione per il nostro lavoro.",

  WHO_ARE_WE_3:
    "Grazie alla nostra conoscenza ed esperienza nel settore, adottiamo un approccio olistico ai processi e rafforziamo il vostro potere digitale con tecnologie software sostenibili. Con servizi di gestione del marchio, progettazione e sviluppo web, strategie di marketing digitale, design per social media, SEM, SEO, sistemi di e-commerce e progetti software personalizzati, accompagniamo le aziende in tutti i processi dalla produzione alla vendita, dalla finanza al marketing, dalla ricerca e sviluppo agli acquisti.",

  WHO_ARE_WE_4:
    "Al di là dei servizi che offriamo, la cura e l'attenzione che dedichiamo ad ogni progetto sono sempre le stesse, indipendentemente dalla sua grandezza o valore. La nostra reputazione si basa su clienti soddisfatti, che hanno visto crescere le loro aziende grazie ai progetti che abbiamo completato.",

  WHO_ARE_WE_5:
    "Sappiamo che il primo passo di ogni nuovo progetto è ascoltare. Comprendiamo esattamente ciò di cui avete bisogno, i problemi che incontrate e i vostri piani per il futuro. È così che progettiamo strategie complete ed efficaci.",

  WHO_ARE_WE_6:
    "Grazie alla nostra lunga esperienza, sappiamo che possiamo aiutarvi indipendentemente dalle dimensioni del vostro progetto o della vostra azienda. I nostri principi guida sono miglioramento continuo, empatia, attenzione ai dettagli, onestà e dedizione. È questo il motivo per cui i clienti con cui collaboriamo dalla nostra fondazione continuano a restare con noi.",

  WHO_ARE_WE_7:
    "Ora basta parlare di noi! Ora vogliamo sentire i vostri sogni, obiettivi, progetti e ciò che volete realizzare! Se volete parlare del vostro business online con noi, tutto il nostro team vi aspetta, pieno di idee fantastiche. Amiamo la comunicazione digitale, ma apprezziamo anche le conversazioni telefoniche. Potete scrivere a info@medyax.com.",

  PHONEHOME: "Telefono casa",
  PHONEMOBILE: "Telefono cellulare",
  PHONEOFFICE: "Telefono ufficio",
  PHONEHOMECOUNTRYCODE: "Prefisso internazionale casa",
  PHONEMOBILECOUNTRYCODE: "Prefisso internazionale cellulare",
  PHONEOFFICECOUNTRYCODE: "Prefisso internazionale ufficio",
  ABOUT: "Chi siamo",
  ABOUTUS: "Chi siamo",
  TEAM: "Team",
  CONTACT: "Contatti",
  PREVIEW: "Anteprima QR Code",
  SUPPORT: "Supporto",
  ADDRESS: "Indirizzo",
  ENTER_LOCATION: "+ Inserisci il tuo indirizzo (puoi aggiungere più indirizzi)",
  ENTER_LOCATION_HELP:
    "Inserisci il tuo indirizzo qui sotto, quindi selezionalo dalla finestra che si apre. Successivamente puoi contrassegnare con più precisione la tua posizione trascinando l'icona rossa.",
  PLEASE_CHOOSE_LOCATION: "Seleziona prima una posizione",
  MANAGER_FULL_NAME: "Nome del responsabile",
  MANAGER_PHONE: "Telefono del responsabile",
  MANAGER_JOBTITLE: "Posizione del responsabile",
  MANAGER_MAIL: "Email del responsabile",
  ART_NAME: "Nome dell'opera",
  ART_ACCESSION_NUMBER: "Numero di catalogo",
  ART_ERA: "Epoca",
  ART_MEASUREMENT: "Misure",
  ART_DISCOVERY_LOCATION: "Luogo del ritrovamento",
  ART_ACCESSION_DATE: "Data del ritrovamento",
  ART_DESCRIPTION: "Descrizione dell'opera",
  RESTAURANT_ABOUT_US: "",
  RESTAURANT_ADDRESS: "",
  RESTAURANT_PHONE: "",
  RESTAURANT_EMAIL: "",
  NAME: "Nome Cognome",
  BLOODGROUP: "Gruppo sanguigno",
  MEDICATION: "Farmaci assunti",
  PLATENUMBER: "Numero targa",
  ALLERGICTO: "Allergie",
  DESCRIPTION: "Descrizione",
  MEDICALRECORD: "Cartella medica",
  NUMBER: "Numero",
  SERIALNUMBER: "Numero di Serie",
  NEXTMAINTENANCE: "Prossima data di manutenzione",
  LASTMAINTENANCE: "Data dell'ultima manutenzione",
  REPAIRCOMPANY: "Azienda di manutenzione/calibrazione",
  LOCATION: "Posizione",
  SHOWREMAINING: "Mostra giorni rimanenti",
  FREQUENCY: "Frequenza manutenzione/calibrazione",
  STARTHOUR: "Ora di inizio",
  ENDHOUR: "Ora di fine",
  ENDDATE: "Data di fine",
  STARTDATE: "Data di inizio",
  TITLE: "Titolo",
  TITLE1: "Primo titolo",
  TITLE2: "Secondo titolo",
  SUBTITLE: "Sottotitolo",
  EMAIL: "Email",
  YOUTUBE: "Link Youtube",
  GMAP: "Google Maps",
  URL: "Link",
  TEXT2: "Secondo testo",
  TEXT1: "Testo",
  TEXT: "Testo",
  BUTTONTEXT: "Testo del pulsante",
  NOPERIOD: "Nessun periodo",
  LAST_30_DAYS: "Ultimi 30 giorni",
  LAST_90_DAYS: "Ultimi 90 giorni",
  LAST_180_DAYS: "Ultimi 180 giorni",
  SELECTED_TIME: "Tutto il tempo",
  OWNER: "Proprietario",
  VETADDRESS: "Indirizzo veterinario",
  VETPHONE: "Telefono veterinario",
  VETEMAIL: "Email veterinario",
  VETNAME: "Nome veterinario",
  GOOGLELINK: "Link Google",
  VIDEOLIST: "Elenco multiplo di Youtube",
  IOSLINK: "Link App Store",
  HUAWEILINK: "Link Huawei Gallery",
  COUPONDISCOUNT: "Coupon di sconto",
  COUPONTITLE: "Titolo del coupon",
  COUPONCODE: "Codice coupon",
  PRODUCT: "Prodotto",
  WIFINAME: "Nome Wi-Fi",
  CONTENTLIST: "Elenco contenuti",
  CONTENTIMAGES: "Immagini dei contenuti",
  PASSWORD: "Password",
  USER: "Utenti",
  BTNTEXT: "Testo del pulsante",
  TEXT: "Testo",
  FILE: "File",
  ["AD SOYAD"]: "Nome Cognome",
  BÖLÜMÜ: "Dipartimento",
  GÖREVI: "Posizione",
  TELEFONU: "Telefono",
  ["KAN GRUBU"]: "Gruppo sanguigno",
  ["İŞE GIRIŞ TARIHI"]: "Data di assunzione",
  ["SICIL NO"]: "Numero di registro",
  RESPONSIBLESTAFFPHONE: "Personale responsabile",
  CITY: "Provincia e Città",
  FAX: "Fax",
  ZIP: "CAP",
  ZIPCODE: "CAP",
  FAXCOUNTRYCODE: "Prefisso internazionale Fax",
  MESSAGE: "Messaggio",
  SSID: "SSID",
  ISHIDDEN: "Nascosto",
  NETWORKTYPE: "Tipo di rete",
  PW: "Password",
  SECURITY: "Sicurezza",
  TOPICON: "Icona superiore",
  MIDDLEICON: "Icona centrale",
  GOOGLE: "Link Google Play",
  APPLE: "Link App Store",
  HUAWEI: "Link Huawei Gallery",
  COUNTRYCODE: "Prefisso internazionale",
  ISWIFIHIDDEN: "Visibilità Wi-Fi",
  NEW: "Nuovo!",
  bulk_upload: "Caricamento di massa",
  NO_TITLE: "Nessun titolo",
  ACTIVE: "Attivo",
  active: "Attivo",
  PASSIVE: "Passivo",
  passive: "Passivo",
  status: "Stato",
  UNIQUE_SCAN: "Scansioni uniche",
  DOWNLOAD: "Download",
  SCAN: "Scansioni totali",
  PRINT: "Stampa",
  VIEW: "Visualizzazioni",
  ISTATISTICS: "Attiva le statistiche per ottenere informazioni dettagliate.",
  DELETE: "Elimina",
  ACTIVE: "Puoi attivare o disattivare il tuo QR Code in qualsiasi momento.",
  RENAME: "Rinomina",
  SEE_PACKAGE: "Dettagli del pacchetto",
  CANCEL: "Annulla",
  LIVE: "Online",
  OK: "OK",
  ARE_YOU_SURE: "Sei sicuro?",
  ERROR: "Errore",
  ATTENTION: "Attenzione!",
  SUCCESS: "Operazione riuscita!",
  DOWNLOAD_ERROR: "Errore durante il caricamento",
  CLOSE: "Chiudi",
  SORT_BY: "Ordina per",
  MOST_RECENT: "Più recenti",
  LEAST_RECENT: "Meno recenti",
  RECENTLY_UPDATE: "Ultimo aggiornamento",
  LEAST_SCANNED: "Meno scansionati",
  MOST_SCANNED: "Più scansionati",
  SEARCH: "Cerca",
  UPLOAD: "Carica file",
  BACK: "Indietro",
  NEW_FOLDER: "Nuova cartella",
  IMAGE_QUALITY: "Qualità di lettura QR Code",
  LOW: "Bassa",
  MEDIUM: "Media",
  GOOD: "Buona",
  HIGH: "Alta",
  BACK_TO_DEFAULTS: "Torna al QR Code classico",
  SAVE_QR_CODE: "Salva QR Code",
  SAVE: "Salva",
  RESET: "Cancella",
  SHORT: "Breve",
  URL: "Link",
  SEND: "Salva",
  SCAN_QR: "Verifica scansione",
  SAVED_DESIGNS: "Salva come modello",
  LOGOS: "Loghi",
  SHOW_ALL: "Mostra tutto",
  ANIMATION: "Animato",
  STATIC: "Statico",
  EYE_BALL: "Occhio interno",
  BODY: "Forma corpo",
  EYE_FRAME: "Occhio esterno",
  FRAME: "Cornice",
  TEMPLATES: "Design QR Code",
  ADVENCED_LOGO_SETTING: "Impostazioni avanzate logo",
  GO_BACK: "Torna indietro",
  SELECT: "Seleziona",
  SINGLE_COLOR: "Colore Singolo",
  FIRST_COLOR: "Primo Colore",
  SECOND_COLOR: "Secondo Colore",
  BG_COLOR: "Colore di Sfondo",
  FRONT_COLOR: "Colore Anteriore",
  BACK_COLOR: "Colore Posteriore",
  CUSTOMIZE_INDIVIDUALLY: "Personalizza individualmente",
  SINGLE_COLOR: "Colore Singolo",
  CHANGE_REFERANCE_POINT: "Cambia punti di riferimento",
  NEED_HELP: "Hai bisogno di aiuto?",
  FRAME_SETTINGS: "Impostazioni cornice",
  DEFAULT_LANGUAGE: "Lingua predefinita",
  DELETE_ALL: "Elimina tutto",
  ACTIVATE_ALL: "Attiva tutto",
  DE_ACTIVATE_ALL: "Disattiva tutto",
  OPTION: "Opzioni",
  DISAGREE: "Rimani sulla pagina",
  AGREE: "Accetta",
  FIRSTSECTIONTITLE: "Titolo sezione",

  field_name: "Nome campo",
  field_value: "Valore campo",
  field_type: "Tipo campo",
  DYNAMIC_FIELDS: "Campi dinamici",
  DYNAMIC_FIELDS_DESC:
    "I tuoi campi dinamici sembrano vuoti. Aggiungi un nuovo campo cliccando sul pulsante qui sotto.",

  you_have_unsaved_changes: "Hai modifiche non salvate",
  you_have_unsaved_changes_desc: "Vuoi davvero lasciare la pagina?",
  approve_comment: "Approva commento",
  disapprove_comment: "Rifiuta commento",
  warning: "Avviso!",
  add_new_languages: "Aggiungi nuova lingua",
  get_more_information_about: "Ulteriori informazioni sulle impostazioni lingua",
  copy_content_from_description:
    "Il contenuto della lingua selezionata sarà applicato alle nuove lingue che aggiungerai",
  copy_content_from: "Lingua da cui copiare il contenuto",
  you_are_about_to_delete_the_category: "Stai per eliminare la categoria",
  are_you_sure_you_want_to_continue:
    "Se continui, perderai i dati relativi a questa categoria e il PDF selezionato. Sei sicuro di voler proseguire?",
  you_at_least_need_to_have_1_category: "Devi avere almeno 1 categoria",
  dark_mode: "Modalità scura",
  pdf_is_processing: "Caricamento PDF in corso...",
  image: "Immagine",
  browse_my_media: "Sfoglia i miei media",
  my_media: "I miei media",
  used: "Utilizzato",
  my_media_subtext: "Gestisci i tuoi media",
  my_domains: "I miei domini",
  my_domains_subtext: "Gestisci i tuoi domini",
  default_domain: "DOMINIO PREDEFINITO",
  not_verify_domain: "DOMINIO NON VERIFICATO",
  verify_domain: "VERIFICA DOMINIO",
  make_deafult_domain: "IMPOSTA COME PREDEFINITO",
  default_status: "Stato predefinito",
  domain_add: "AGGIUNGI DOMINIO",
  animation: "Animazione",
  background_color: "Immagine di sfondo",
  choose_design: "Scegli design",
  select_from_my_media: "Seleziona immagine/logo dai miei media",
  select_from_animation: "Seleziona dalle animazioni",
  age_restriction:
    "Per garantire la sicurezza e l'esperienza dei nostri utenti, puoi impostare una restrizione di età per la tua applicazione. Gli utenti dovranno confermare la propria età per accedere. Ciò significa limitare determinati contenuti o funzionalità agli adulti.",
  age_restriction_text: "Restrizione di età",
  age_restriction_tooltip: "Avviso per utenti sotto una determinata età",
  analytics: "Analisi",
  expiration_date: "Data di scadenza",
  device: "Dispositivo",
  personal_info: "Informazioni personali",
  preparing_template: "",
  RESERVATIONEMAIL: "Email di prenotazione",
  locked: "Bloccato",
  RESTORE: "Ripristina",
  current_password: "Password attuale",
  new_password: "Nuova password",
  verify_password: "Conferma password",
  time_limit: "Limite di tempo",
  pdf_is_processing: "Elaborazione PDF in corso",
  field_required: "Questo campo è obbligatorio",
  start_typing: "Inizia a digitare...",
  processing_pdf: "Il file PDF è in elaborazione",
  general_settings: "Impostazioni generali",
  REMINDER: "Promemoria",
  WEBURL: "Sito Web",
  NONE: "Nessuno",
  MINUTES: "minuti prima",
  HOURS: "ore prima",
  DAYS: "giorni prima",
  WEEKS: "settimane prima",
  COMPLETE: "Completa",
  type_your_first_name: "Inserisci il tuo nome",
  type_your_last_name: "Inserisci il tuo cognome",
  type_your_address: "Inserisci il tuo indirizzo",
  your_company_info: "Informazioni sulla tua azienda",
  order_summary: "Riepilogo ordine",
  order_desc: "Questo è il riepilogo delle informazioni del pacchetto selezionato.",
  annual: "Annuale",
  subtotal: "Subtotale",
  tax: "IVA",
  coupon_discount: "Sconto coupon",
  total: "Importo totale",
  package_price: "Prezzo pacchetto",
  package_discount: "Sconto pacchetto",
  type_your_discount_code: "Inserisci il tuo codice sconto",
  coupon_code: "Codice sconto",
  use_coupon_code: "Usa codice",
  cancel_coupon: "Annulla codice",
  i_agree_to: "Ho letto e accetto il contratto",
  go_back: "INDIETRO",
  month: "Mese",
  year: "Anno",
  day: "Giorno",
  creditcard_number: "Numero carta",
  creditcard_name: "Intestatario della carta",
  creditcard_expiration_date: "Data di scadenza",
  creditcard_cvv: "CVC",
  terms_of_use: "Termini di utilizzo",
  current_plan: "Piano attuale",
  active_package: "Pacchetto attivo",
  started_at: "Inizio",
  ends_at: "Fine",
  remaining_time: "Tempo rimanente",
  package_content: "Contenuto del pacchetto",
  newspaper: "Giornale",
  campaign_start: "Inizio",
  campaign: "Informazioni sulla stampa",
  campaign_end: "Fine",
  start_date: "Data di inizio",
  end_date: "Data di fine",
  are_you_sure: "Sei sicuro?",
  campaign_info: "Informazioni campagna",
  print_style: "Tipo di stampa",
  print_amount: "Quantità stampa",
  advanced_statistics: "Statistiche avanzate",
  advanced_statistics_desc: "Le statistiche avanzate non sono attive. Vuoi attivarle?",
  change_range: "Filtro",
  select_start_and_end_dates: "Seleziona le date di inizio e fine",
  language_settings: "Impostazioni lingua",
  pdf_info:
    "Se questa opzione è selezionata, gli utenti non vedranno alcuna pagina e saranno reindirizzati direttamente al file PDF.",
  pdf_download: "Devi caricare un PDF affinché sia attivo.",
  general_settings_link: "Clicca qui per ulteriori informazioni.",
  email_address_to_be_sent: "Indirizzo email a cui inviare il modulo di contatto",
  general_settings_desc:
    "Il titolo del QR Code è visibile solo a te per distinguerlo dagli altri QR code. Gli utenti non lo vedranno, e modificarlo non cambierà la tua pagina.",
  submit: "Invia",
  invalid_email: "Email non valida",
  new_qr_code: "Crea nuovo QR Code",
  days_remaining: "RIMASTI :",
  days: "GIORNI",
  the_last_day_of_your_package: "Ultimo giorno del tuo pacchetto",
  full_name: "Nome e Cognome",
  phone: "Telefono",
  email: "Email",
  text: "Testo",
  link: "Link",
  desc_message: "Per favore inserisci una descrizione...",
  how_many_qrcodes_you_need: "Quanti QR code ti servono?",
  request_custom_package: "Richiedi pacchetto personalizzato",
  communication: "Contatti",
  account_privacy: "Eliminazione account",
  deactivate_your_account: "Disattiva il tuo account",
  default_language: "Questa sarà la tua lingua predefinita",
  save_changes: "Salva",
  cancel: "Annulla",
  avatar: "Immagine profilo",
  update_your_personal_information: "Aggiorna le tue informazioni personali",
  allowed_file_types: "Formati accettati",
  change_password: "Cambia password",
  account_holder: "Titolare dell'account",
  account_information: "Informazioni account",
  FIRSTBTNTEXT: "Primo Pulsante",
  SECONDBTNTEXT: "Secondo Pulsante",
  request_price: "Richiedi Offerta Personalizzata",
  make_request: "RICHIEDI OFFERTA",
  gender: "Genere",
  male: "Maschio",
  female: "Femmina",
  cell_phone: "Cellulare",
  invalid_phone: "Numero di telefono non valido",
  invalid_mail: "Indirizzo email non valido",
  invalid_website: "Indirizzo non valido",
  invalid_name: "Nome non valido",
  dinamik_qr_kod_fiyatları: "Prezzi QR Code Dinamici",
  restaurant_menü_qr_kod_fiyatları: "Prezzi QR Code per Menu Ristoranti",
  note: "Nota",
  user_profile: "Profilo Utente",
  sign_out: "Esci",
  signing_out: "Disconnessione in corso",
  account_settings_and_more: "Impostazioni account e altro",
  my_profile: "Il mio profilo",
  active_subscriptions: "I miei pacchetti attivi",
  active_subscriptions_subtext: "Pacchetti attualmente in uso",
  SELECT_FROM_THE_LIST: "Seleziona il tipo di carta dalla lista",
  package_name: "Nome del pacchetto",
  device_list: "Elenco dispositivi",
  details: "Dettagli",
  detailed_chart: "Grafico dettagliato",
  browser_list: "Elenco browser",
  city_list: "Elenco città",
  country_list: "Elenco paesi",
  os_list: "Sistemi operativi",
  age_profile: "Elenco per età",
  ip_list: "Elenco IP",
  gender_profile: "Profilo per genere",
  device_resolution_profile: "Profilo risoluzione dispositivo",
  last_day: "Giornaliero",
  last_week: "Settimanale",
  last_month: "Mensile",
  last_year: "Annuale",
  page_view: "Visualizzazioni pagina",
  unique_visitor: "Visitatori unici",
  total_view: "Visualizzazioni totali",
  total_visit: "Visite totali",
  delete_staticstics: "Sei sicuro di voler eliminare i dati statistici?",
  avarage_duration_visitors_stay: "Durata media della visita degli utenti",
  total_number: "Statistiche totali",
  sorry_im_leaving: "Mi dispiace, sto lasciando!",
  sorry_im_leaving_desc:
    "Stai per eliminare il tuo account. Continuando, i tuoi dati verranno cancellati permanentemente e non potrai recuperarli. Vuoi ancora proseguire?",
  last_30_days: "Ultimi 30 giorni",
  loading: "Caricamento",
  klasor_name: "Nome cartella",
  delete_comment: "Elimina commento",
  are_you_sure_you_want_to_delete_this_comment: "Sei sicuro di voler eliminare questo commento?",
  if_you_delete_this_comment: "Se elimini questo commento, non potrai recuperarlo in seguito",
  CREATE: "Crea",
  Upgrade: "Aggiorna",
  no_content: "Crea una 'NUOVA CARTELLA' o carica un 'FILE'.",
  size: "Dimensione",
  scan: "Scansione",
  limit: "Limite",
  subject: "Oggetto",
  message: "Messaggio",
  qrcode: "QR Code",
  allow_this_to_be_shared: "Consento la condivisione con altri utenti",
  package_limit: "Limite pacchetto",
  rows_per_page: "Righe per pagina",
  coupon_discount: "Sconto Coupon",
  your_subscription_has_expired: "Il tuo pacchetto è scaduto",
  two_ways_to_keep_using: "Per continuare a usare il servizio",
  contact_us: "Contattaci",
  upgrade_now: "Aggiorna ora",
  or: "OPPURE",
  no_data: "Nessun dato disponibile",
  list_is_empty: "La lista è vuota",
  recaptcha_error: "Per favore, dimostra di non essere un robot",
  if_you_delete_this_qr_code: "Se elimini questo QR code, perderai anche i dati associati.",
  delete_all_childs_alert_text: ({ named }) =>
    `Questa azione irreversibile eliminerà ${named(
      "amount"
    )} QR code subordinati al master. Assicurati di voler procedere prima di continuare.`,
  qr_codes_that_are_not_recovered: "I QR code non recuperati entro 20 giorni verranno eliminati definitivamente.",
  changing_URL_will_create_a_new_qr_code:
    "Modificare l'URL creerà un nuovo QR code e non sarà più possibile accedere alla versione stampata precedente se continui.",
  attention: "Attenzione! Importante!",
  valid_in_dynamic: "Valido nel dinamico",
  you_can_not_add_more_than_connections: ({ named }) => `Non puoi aggiungere più di ${named("amount")} connessioni.`,
  If_you_delete_this_language_you_will_lose_all_the:
    "Se elimini questa lingua, perderai tutti i dati associati. Vuoi davvero continuare?",
  loading_items: "Caricamento dati in corso",
  your_profile_data_has_been_successfuly_updated: "I dati del tuo profilo sono stati aggiornati con successo!",
  your_package_will_be_processed_after_your_payment_has_been_received:
    "Il tuo pacchetto sarà attivato dopo aver ricevuto il pagamento.",
  verify_password_does_not_match: "Le password non corrispondono, verifica",
  please_fill_all_fields: "Per favore, compila tutti i campi",
  text_must_be_less_than_2500_characters: "Il testo deve essere inferiore a 2500 caratteri",
  title_must_be_less_than_80_characters: "Il titolo deve essere inferiore a 80 caratteri",
  must_be_less_than_80_characters: "deve essere inferiore a 80 caratteri",
  must_be_less_than_200_characters: "deve essere inferiore a 200 caratteri",
  must_be_less_than_100_characters: "Deve contenere meno di 100 caratteri",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Puoi selezionare un'immagine dal PC e mostrarla sulla scheda premendo il pulsante rosso. Devi selezionare un'immagine nelle impostazioni 'Media' o disattivare la sezione foto.",
  is_required: "è obbligatorio",
  btnText: "Testo del pulsante",
  title_is_required: "Il titolo è obbligatorio",
  this_is_required: "Questo campo è obbligatorio",
  phone_is_required: "Numero di telefono obbligatorio",
  this_url_is_invalid: "Questo URL non è valido",
  please_input_a_valid_phone_number: "Inserisci un numero di telefono valido",
  you_need_to_chose_start_or_end_date: "Devi selezionare una data di inizio o di fine",
  you_need_to_fill_the_required_fields: "Devi compilare i campi obbligatori",
  selected_file: "Seleziona PDF",
  no_file: "Nessun file",
  no_link: "Collega link",
  add_more_button_text: "Aggiungi nuovo campo",
  go_to_main_page: "Torna alla pagina principale",
  new_address: "Nuovo indirizzo",
  information: "Informazioni",
  description_for_the_address: "Descrizione dell'indirizzo",
  you_can_not_add_more_than_10_emergency_number: "Non puoi aggiungere più di 10 numeri di emergenza",
  verify_password_does_not_match: "La password non corrisponde, verifica",
  please_fill_all_fields: "Per favore compila tutti i campi",
  text_must_be_less_than_2500_characters: "Il testo deve contenere meno di 2500 caratteri",
  title_must_be_less_than_80_characters: "Il titolo deve contenere meno di 80 caratteri",
  must_be_less_than_200_characters: "Il testo deve contenere meno di 200 caratteri",
  must_be_less_than_500_characters: "Il testo deve contenere meno di 500 caratteri",
  invalid_url: "URL non valido",
  continue: "CREA ORA",
  direct_download: "Download diretto (scarica direttamente sul telefono dell'utente dopo la scansione del codice QR)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Il contatore sarà visibile a partire da __ giorni",
  select_pdf_for_each_language:
    "Clicca sul pulsante 'Seleziona PDF', crea una nuova cartella e carica il tuo file. Fai clic sul file caricato, il PDF sarà elaborato. Devi aggiungere un file PDF per ogni lingua. Lingue senza file PDF: ",
  missing_locations_for_languages:
    "Non copiare e incollare il tuo INDIRIZZO! Per selezionare correttamente l'INDIRIZZO, DIGITA E SELEZIONA DAL MENU A TENDINA!",

  drag_drop: "Cambia ordine trascinando",
  change_order: "Cambia ordine",
  caption: "Sottotitolo",
  image: "Immagine",
  add_new: "Aggiungi nuovo",
  delete_selected: "Elimina selezionati",

  your_own: "Proprio",
  logo: "Aggiungi<br>Logo",
  add: "Aggiungi",

  upper_left: "In alto a sinistra",
  upper_right: "In alto a destra",
  lower_left: "In basso a sinistra",

  qrcode_static_subtext: "Seleziona per creare il tuo codice statico.",

  type_something_here: "Puoi scrivere qui una descrizione",

  start_program: "Inizio",
  end_program: "Fine",

  billed_annually: "Pagamento annuale",
  no_folder: "Nessuna cartella",

  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "VOGLIO AVERE INFORMAZIONI SUI PRODOTTI",

  keep_track:
    "Tieni traccia del numero di utenti che scansionano questo codice QR e confronta il tuo sito usando le <strong>Statistiche avanzate</strong>.</br></br>Clicca sul pulsante in alto a destra dopo aver sbloccato per andare alle <strong>Statistiche avanzate</strong>.</br></br><img src='https://i.imgur.com/fOk5J81.png' />",

  view_user_ratings: "Visualizza, modifica, elimina le valutazioni degli utenti",

  not_a_valid_linkedin_link: "Non è un link Linkedin valido",
  not_a_valid_facebook_link: "Non è un link Facebook valido",
  not_a_valid_twitter_link: "Non è un link Twitter valido",
  not_a_valid_instagram_link: "Non è un link Instagram valido",
  not_a_valid_whatsapp_link: "Non è un link Whatsapp valido",
  not_a_valid_youtube_link: "Non è un link Youtube valido",
  not_a_valid_reddit_link: "Non è un link Reddit valido",
  not_a_valid_telegram_link: "Non è un link Telegram valido",
  not_a_valid_snapchat_link: "Non è un link Snapchat valido",
  not_a_valid_pinterest_link: "Non è un link Pinterest valido",
  not_a_valid_yelp_link: "Non è un link Yelp valido",
  not_a_valid_flicker_link: "Non è un link Flickr valido",
  not_a_valid_vimeo_link: "Non è un link Vimeo valido",
  not_a_valid_vk_link: "Non è un link VK valido",
  not_a_valid_soundcloud_link: "Non è un link Soundcloud valido",
  not_a_valid_github_link: "Non è un link Github valido",
  not_a_valid_tumblr_link: "Non è un link Tumblr valido",
  not_a_valid_skype_link: "Non è un link Skype valido",

  give_us_feedback: "Per favore lasciaci un feedback",
  please_do_let_us_know: "Per favore facci sapere:",
  your_feedback_is_important_to_us: "Il tuo feedback è importante per noi!",
  if_you_would_like_to_let_us_know:
    "Se desideri farci sapere come possiamo migliorare i nostri prodotti o servizi o condividere un complimento, saremo felici di ascoltarti!",
  how_you_use_our_products: "Come utilizzi i nostri prodotti",
  how_we_can_improve_our_products_or_service: "Come possiamo migliorare i nostri prodotti o servizi",
  which_conferences_you_think_we_should_attend: "A quali conferenze pensi che dovremmo partecipare",
  any_publications_citing_the_use_of_our_products: "Qualsiasi pubblicazione che cita l'uso dei nostri prodotti",
  we_look_forward_to_hearing_from_you: "Non vediamo l'ora di sentirti!",
  feedback_form: "Modulo di Feedback",
  please_feed: "Per favore, i tuoi commenti",
  feed_back_share: "Condividi con noi!",
  thank_you: "Grazie!",
  rate_us: "Valutaci!",
  we_have_recieved_your_feedback: "Abbiamo ricevuto il tuo messaggio!",
  i_allow_for_this_message_to_be_shared_the_other_users:
    "Acconsento che questo messaggio venga condiviso con altri utenti",

  if_you_delete_this_file:
    "Se elimini questo file, i QR code che utilizzano attivamente questo media potrebbero smettere di funzionare. Sei sicuro di voler continuare?",
  if_you_delete_selected_files:
    "Se elimini i file selezionati, i QR code che utilizzano attivamente questi media potrebbero smettere di funzionare. Sei sicuro di voler continuare?",
  if_you_delete_this_folder:
    "Se elimini questa cartella, i QR code che utilizzano attivamente questo media potrebbero smettere di funzionare. Sei sicuro di voler continuare?",

  you_need_to_select_pdf_for_each_language_missing_in: "Devi aggiungere un PDF per ogni lingua. Mancante:",
  you_can_not_delete_default_language: "Non puoi eliminare la lingua predefinita",
  you_can_not_delete_active_language: "Non puoi eliminare la lingua attiva",
  "Set as a default": "Imposta come predefinito",
  Delete: "Elimina",
  Activate: "Attiva lingua",
  a_new_verification_mail_has_been_sent_to_your_email_address:
    "Una nuova email di verifica è stata inviata al tuo indirizzo email.",
  email_verification: "Verifica email",
  did_not_recieve_an_email: "Non hai ricevuto l'email?",
  re_send_verification_mail: "INVIA NUOVAMENTE email di verifica",
  just_for_you_to_join_us_one_step_left: "Un solo passo per unirti a noi",
  an_activation_email_has_been_sent:
    "Una mail di attivazione è stata inviata al tuo indirizzo email. Puoi attivare il tuo account cliccando sul link inviato.",
  locks_your_app_and_asks_users_to_enter_this_password:
    "Per garantire la sicurezza, l'accesso alla tua applicazione è protetto da una password. Gli utenti devono inserire correttamente questa password per accedere o visualizzare contenuti riservati. Questo previene l'accesso non autorizzato e aumenta la sicurezza dei dati degli utenti.",
  paste_given_code_if_you_would_like_to_track_your_statictics:
    "Se vuoi tracciare dettagliatamente le tue statistiche, incolla qui il codice completo di Yandex Metrica o Google Analytics.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date:
    "Se compili i campi sottostanti, il QR code non sarà disponibile prima della data di inizio e dopo la data di fine.",
  the_content_will_only_be_avialable_to_the_selected_deviceList:
    "Il contenuto sarà disponibile solo per l'elenco dei dispositivi selezionati.",
  this_must_be_less_than_5_characters: "Questo deve contenere meno di 5 caratteri",
  click_here_to_save_qrcode: "<strong>NON SALVATO!</strong> devi andare alla pagina di modifica e salvare",
  type_changer_disclaimer: ({ named }) =>
    `Stai per cambiare da <strong>${named(
      "currentType"
    )}</strong> a un altro tipo di QR code. Se continui, tutti i dati inseriti per questo QR code verranno cancellati e verrai reindirizzato alla pagina per inserire i campi necessari. Ricorda che questa operazione è irreversibile.`,
  btnText: "Testo del pulsante",
  your_short_url_will_look_like_this:
    "Il tuo URL corto apparirà così <br> (Se non è il tuo dominio): <br> q-r.cc/adc256",
  please_input_a_valid_phone_number: "Per favore, inserisci un numero di telefono valido",
  qr_code_warning_text:
    "Nota: se cambi il nome del dominio o il suo valore, il contenuto del QR code cambierà. Se il codice è già stampato, potrebbe non funzionare più.",
  your_profile_photo_has_been_successfuly_updated: "La tua foto del profilo è stata aggiornata con successo!",
  your_profile_photo_has_been_successfuly_removed: "La tua foto del profilo è stata rimossa con successo!",
  you_at_least_need_to_have_1_address: "Devi avere almeno un indirizzo!",

  pricing_guide: `
  <div style="padding:30px;background:#ffffff">
            <h1 class="text-center">Guida ai Prezzi</h1>
                        <ul>
              <li>Proponiamo soluzioni adatte alle esigenze dei nostri clienti adottando una politica di prezzo trasparente ed equa. Offriamo diverse opzioni di pacchetti per le soluzioni QR code: Starter Pack, Advanced Pack e Business Pack. Offriamo inoltre offerte personalizzate per i clienti con esigenze particolari.</li>

              <li>I nostri pacchetti includono QR code venduti a quantità definite, ciascuno con specifiche caratteristiche e livelli di servizio. La scelta del pacchetto dipende dalle esigenze, dal budget e dai scenari d'uso dei clienti.</li>

              <li>Lo Starter Pack è perfetto per piccole imprese e utenti privati. L'Advanced Pack offre una gamma più ampia di funzioni per aziende di medie dimensioni o utenti con maggiori esigenze. Il Business Pack è pensato per grandi aziende con esigenze più complesse.</li>

              <li>Le offerte personalizzate includono soluzioni specifiche per progetti unici o requisiti particolari, con QR code disponibili in quantità diverse rispetto ai pacchetti standard. Offriamo prezzi flessibili per soddisfare al meglio le esigenze specifiche dei nostri clienti.</li>

              <li>Per maggiori informazioni sui dettagli dei prezzi e le caratteristiche dei pacchetti, contattaci direttamente.</li>
              </ul>
            <p class="text-end">QR Code Matic</p>
          </div>
`,
  how_it_works: "Come funziona",
  how_it_works_button_text: "Come funziona",
  your_subscription_expired: "Il tuo pacchetto è scaduto. Puoi creare gratuitamente QR Code Statici.",
  qrcode_will_be_deleted_in_24: "Questo QR code verrà eliminato entro 1 giorno. Clicca per scoprire il motivo",

  //Notification
  notification_detail: "Dettagli Notifica",

  //Adress
  address_information: "Informazioni sull'indirizzo",
  static_fields: "Campi Statici",

  //domain list
  manage_your_own_domains: "Gestisci i tuoi domini",
  domain_list: "Elenco domini",
  domain_name: "Nome del dominio",
  short_link: "Link breve",
  options: "Opzioni",
  delete: "Elimina",
  domain_delete: "Rimuovi dominio",
  domain_delete_desc: "Stai per eliminare il dominio ",
  domain_delete_desc_2: "Sei sicuro di voler continuare?",
  verified_status: "Stato di verifica",
  how_to_setup: "Come configurare?",
  how_to_setup_desc:
    "Per attivare il tuo dominio breve, devi impostare un sottodominio sul tuo dominio con un record CNAME che punti al nostro dominio q-r.cc. Assicurati di configurare prima il dominio: a volte occorrono fino a 24 ore perché il nostro sistema rilevi le modifiche.",
  what_is_a_short_URL: "Cos'è un URL breve?",
  what_is_a_short_URL_desc:
    "I Codici Dinamici utilizzano un URL breve che reindirizza alla landing page desiderata. Questo è necessario per tracciare i dati e consentire modifiche ai codici. Puoi modificare questo dominio ad esempio in questo modo:",

  create_template_button_text: ({ named }) =>
    `<span class="font-weight-bold mr-1">Crea</span><span class="font-weight-light">Design</span>`,
  queue_processing_title: ({ named }) => `La tua operazione ${named("type")} è in corso!`,
  queue_done_title: ({ named }) => `La tua operazione ${named("type")} è completata!`,
  queue_done_text: ({ named }) =>
    `L'elaborazione batch ${named(
      "type"
    )} per il master è completata. Ora puoi modificare i tuoi master QR Code e i relativi sotto-QR Code.`,
  queue_processing_text: ({ named }) =>
    `La tua richiesta batch per ${named(
      "type"
    )} è stata messa in coda. Devi attendere il completamento della richiesta. Riceverai un'e-mail poco dopo il completamento.`,
  waiting_for_process: "In attesa del completamento della tua richiesta...",
  select_all: "Seleziona tutto",
  deselect_all: "Deseleziona tutto",
  add_more_child_from_previous_qrcodes: "+ Aggiungi altri QR Code",
  add_more_child_from_previous_qrcodes_tooltip:
    "Puoi aggiungere o spostare altri QR Code sotto questo QR Code per aggiornarli rapidamente e collettivamente.",
  convert_to_master: "Converti in Master",
  convert_to_master_tooltip: "Converti questo QR Code per aggiungere o spostare QR Code sotto di esso.",
  add_more_child_dialog_title: "Aggiungi QR Code esistenti sotto questo Master",
  add_more_child_dialog_button_text: "Esegui trasferimento",
  add_more_child_is_successful: "Operazione riuscita",
  add_more_child_dialog_p1: () =>
    `Questo menu consente di raggruppare i QR Code creati in precedenza sotto un QR Code Master per eseguire operazioni collettive.`,
  add_more_child_dialog_p2: ({ named }) =>
    `I QR Code che selezionerai dall'elenco sottostante saranno trasferiti sotto il Master QR Code con titolo <strong>${named(
      "title"
    )}</strong> / variabili <strong>${named(
      "vars"
    )}</strong>. Potrai poi effettuare modifiche collettive tramite <strong>${named("title")}</strong>.`,
  add_more_child_dialog_p3: () => `Le icone di avviso accanto ai QR Code indicano quanto segue;
<ul>
  <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">ha 1 sotto QR Code</span> questo QR Code ha altri QR Code sotto di esso. Se selezionato, anche i suoi QR Code subordinati saranno trasferiti.</li>
  <li><i class="fa-solid fa-circle-info orange--text mr-2"></i>Questo QR Code è già un master. Se lo selezioni, perderà la caratteristica di master e sarà spostato sotto il QR Code corrente.</li>
</ul>`,
  are_you_sure_convert_child_to_master: ({ named }) =>
    `Stai per rimuovere il QR Code <strong>${named(
      "title"
    )}</strong> dal suo master e trasformarlo in master autonomo. Sei sicuro di voler procedere?`,
  add_more_child_count: ({ named }) => `Ha ${named("count")} sotto-QR Code`,
  gradient_on_eyes: "Gradiente sugli occhi",
  update_prices: "Aggiorna prezzi del menu",
  confirm_delete_all: "Sei sicuro di voler eliminare tutti questi QR Code?",
  unsaved_changes_will_be_lost: "Le modifiche non salvate andranno perse. Sei sicuro di voler uscire?",
  show_media: "Mostra media da cui copiare i link",
  $LOGIN: {
    welcome_to: "Benvenuto su QR Code Matic!",
    just_one_step: "Solo un passo per creare il tuo QR Code Dinamico o Statico!",
    you_are_almost_done: "Ci sei quasi!",
    please_choose_appropriate_options:
      "Seleziona l'opzione più adatta a te! Puoi scegliere rapidamente 'ALTRO' in basso o 'USO PERSONALE' in alto.",
    please_chose_one_of_the_options: "Per favore, fai una selezione",
    disclaimer:
      "Nessuna informazione sulla carta di credito è richiesta durante la prova gratuita. Assicurati solo di selezionare il paese corretto.",
    new_here: "Sei nuovo?",
    create_an_account: "Crea account",
    password: "Password",
    password_re: "Conferma password",
    forgot_password: "Password dimenticata",
    sign_in: "Accedi",
    sign_in_with_google: "Accedi con Google",
    sign_in_with_facebook: "Accedi con Facebook",
    sign_in_with_twitter: "Accedi con Twitter",
    sign_up: "Registrati",
    subheader: "Inserisci le informazioni necessarie per la registrazione",
    your_organization: "Il tuo settore",
    your_country: "Paese",
    fullname: "Nome completo",
    confirm_password: "Conferma password",
    agree_terms: "Accetto i termini di utilizzo e la privacy",
    did_you_forget_password: "Hai dimenticato la password?",
    forgot_password_subheader: "Inserisci la tua email per reimpostare la password",
    submit: "Invia",
    cancel: "Annulla",
    terms: "Privacy e Termini di Utilizzo",
    contact_us: "Contattaci",
    enter_new_password: "Inserisci una nuova password",
    your_password_has_been_reset: "La tua password è stata reimpostata",
    you_will_be_redirected_to_login: "Sarai reindirizzato alla pagina di accesso...",
    reset_link_is_sent_to_your_mail: "Il link di reimpostazione è stato inviato alla tua email.",
    check_your_mail_box: "Controlla la tua casella di posta",
    login: "ACCEDI",
    your_language: "Seleziona la lingua",
  },
  $TICKET: {
    mail_support: "Richiesta supporto",
    choose_a_file: "Seleziona un file o trascinalo qui...",
    enter_mail: "Inserisci email...",
    mail: "Email",
    subject: "Oggetto",
    phone: "Telefono",
    message: "Messaggio",
    select_topic: "Seleziona argomento",
    qenerals_quetion: "Domanda generale",
    billing: "Pagamento",
    technical_question: "Domanda tecnica",
    login_issue: "Problemi di accesso",
    other: "Altro",
    thanks_title: "Grazie per averci contattato",
    thanks_text: "Il tuo messaggio è stato inviato con successo. Il nostro staff ti contatterà al più presto.",
  },
  $ORDERS: {
    order_number: "Numero ordine",
    order_status: "Stato ordine",
    product_name: "Nome prodotto",
    currency: "Valuta",
    order_date: "Data ordine",
    active_package: "Pacchetto attivo",
    started_at: "Data di inizio",
    expires_at: "Data di scadenza",
    remaining_days: "Giorni rimanenti",
    actions: "Azioni",
    order_title: "ORDINI",
    payment_confirmed: "Confermato",
    incorrect_payment: "Pagamento errato",
    awaiting_payment: "In attesa di pagamento",
    order_canceled: "Annullato",
    cancel_order: "Annulla ordine",
    status: "Stato ordine",
    invalid_package: "Pacchetto non valido",
    payment_type: "Tipo di pagamento",
    account_number: "Numero conto",
    payment_amount: "Importo pagamento",
    payment_desc: "Descrizione pagamento",
    bank_transfer: "Bonifico bancario",
    creditcard: "Carta di credito",
  },
  $SELECT_SECTOR: {
    personal_use: "Uso personale",
    tourism: "Turismo",
    restaurants: "Ristoranti",
    software: "Software",
    photo_video: "Foto e video",
    cities_culture: "Città e cultura",
    event_management: "Gestione eventi",
    home_repair: "Casa e riparazioni",
    finance: "Finanza",
    publisher: "Editoria",
    nonprofit: "Non profit",
    retail: "Vendita al dettaglio",
    real_estate: "Immobiliare",
    gym: "Palestre e wellness",
    hotel: "Hotel e resort",
    products: "Prodotti",
    education: "Educazione",
    goverment: "Governo",
    doctors: "Medici e servizi sanitari",
    agency: "Servizi di agenzia",
    electronics: "Elettrico ed elettronico",
    paper: "Mobili, carta e prodotti cartacei",
    glass: "Vetro, cemento e terracotta",
    environment: "Ambiente",
    energy: "Energia",
    foods: "Alimentare",
    construction: "Edilizia",
    business: "Business e gestione",
    chemistry: "Chimica, petrolio, gomma e plastica",
    culture: "Cultura, arte e design",
    mines: "Miniere",
    machines: "Macchinari",
    media: "Media, comunicazione ed editoria",
    metal: "Metalli",
    automotive: "Automotive",
    social: "Servizi sociali",
    sports: "Sport e ricreazione",
    agriculture: "Agricoltura, caccia e pesca",
    textile: "Tessile, abbigliamento e pelle",
    trade: "Commercio (vendita e marketing)",
    community: "Servizi comunitari e personali",
    beverage: "Servizi di ristorazione",
    transport: "Trasporto e logistica",
    communications: "Comunicazioni",
    other: "Altro",
  },
  $SUBSCRIPTION: {
    selected_package_name: "Pacchetto selezionato",
    choose_payment_method: "Scegli Metodo Di Pagamento",
    choose_payment_method_description:
      "Puoi effettuare un pagamento istantaneo con una carta di credito e completare la tua transazione in modo sicuro. In alternativa, puoi effettuare il pagamento tramite bonifico bancario.",
    add_payment_info: "Informazioni pagamento",
    payment_is_safe:
      "Con il nostro sistema di pagamento sicuro, puoi completare il tuo acquisto senza preoccupazioni di sicurezza.",
    private_or_business:
      "Per favore, seleziona se l'acquisto è individuale o aziendale. Questa scelta è importante per l'emissione della fattura.",
    billing_info: "Informazioni di fatturazione",
    complete_payment: "Completa pagamento",
    bank_transfer_info: "Informazioni bonifico bancario",
    about_pricing: "Se hai bisogno di ulteriori informazioni sulla nostra politica dei prezzi, controlla",
    choose_your_plan: "Scegli il tuo piano",
    pricing_policy: "Politica prezzi",
    company_invoice: "Fattura aziendale",
    individual_invoice: "Fattura individuale",
    select: "ACQUISTA",
    discountMessage: ({ named }) => `È stato applicato uno sconto di ${named("amount")}$`,
  },
  validations: {
    field_required: "Campo obbligatorio",
    email_required: "Email obbligatoria",
    password_required: "Password obbligatoria",
    agree_terms: "Devi accettare i termini",
    invalid_zip: "CAP non valido",
    invalid_email: "Email non valida",
    invalid_password: "Password non valida",
    invalid_youtube: "Link YouTube non valido",
    not_a_valid_email: "Email non valida",
    not_a_valid_phone_number: "Numero di telefono non valido",
    you_must_accept_the_terms: "Devi accettare i termini",
    your_password_does_not_match: "La password non corrisponde",
    you_must_enter_valid_email: "Devi inserire un indirizzo email valido",
    you_must_enter_password: "Devi inserire una password",
    you_must_enter_your_name: "Devi inserire il tuo nome",
    you_must_choose_country: "Devi scegliere un paese",
    you_must_choose_organization: "Devi scegliere un'organizzazione",
    must_contain_numbers: "Deve contenere almeno un numero",
    must_contain_letters: "Deve contenere almeno una lettera",
    short_link_is_required: "Obbligatorio",
    short_link_special_char: "Deve contenere solo lettere e numeri",
    short_link_between: "Deve essere tra 3 e 30 caratteri",
    less_than: ({ named }) => `Deve essere inferiore a ${named("amount")} caratteri`,
    more_than: ({ named }) => `Deve essere almeno ${named("amount")} caratteri`,
  },
  authTemplate: {
    confirm: "Conferma",
    legal_disclaimer_title: () => `<h1><span>Avviso</span> Legale</h1>`,
    legal_disclaimer:
      "Devi avere almeno 11 anni per visualizzare questo sito. Se hai meno di 11 anni, non hai il diritto di visualizzarlo.",
    login_area_title: () => `<h1><span>Area</span> Login</h1>`,
    login_button_text: "Accedi",
    login_disclaimer:
      "Il contenuto che stai cercando di raggiungere è nascosto. Puoi accedere alla pagina inserendo una password.",
  },
  ALERT: {
    alert_msg_1:
      "Stai per modificare i dati del codice QR statico. Questo cambierà il codice QR stesso. Le modifiche apportate non saranno valide per i codici QR stampati o distribuiti. Sei sicuro di voler continuare?",
  },
  FAQ_MAIN: {
    faqs_and_guides_about_qr_code: "Domande frequenti e Guide sui codici QR",
    search_for_topics: "Cerca argomenti...",
    faq: "FAQ",
    the_most_frequently_asked_question: "Le domande più frequenti",
    articles_in_this_collection: "Articoli disponibili",
    written_by_QR_Code_Matic: "Scritto da QR Code Matic",
    setting_up_your_account: "Configurazione del tuo account",
    why_qr_code: "Perché utilizzare i codici QR?",
    pricing_and_billing: "Prezzi e fatturazione",
    information_about_prices_and_invoices: "Informazioni su prezzi e fatture",
    find_out_everything_you_need_to_know_about_qr_codes_here: "Scopri tutto ciò che devi sapere sui codici QR qui",
    analytic_title: "Analitica per comprendere i dati: importanza e applicazioni nel tuo business",
    get_to_know_our_pricing_terms_and_billing: "Scopri i nostri prezzi, termini e fatturazione",
    analytic: "Analitica",
  },
  FAQ: {
    static_dynamic_title: "Qual è la differenza tra un codice QR statico e dinamico?",
    static_dynamic: `I codici QR statici non possono essere modificati dopo essere stati creati e stampati. Se il link si rompe, non puoi fare altro che stamparli nuovamente. Al contrario, i codici QR dinamici possono essere modificati in tempo reale. Poiché contengono un URL che può essere aggiornato, puoi utilizzare lo stesso codice QR più volte, risparmiando tempo, sforzi e costi.`,

    better_static_dynamic_title: "Quale è migliore: codice QR statico o dinamico?",
    better_static_dynamic: `Se stai cercando una campagna di marketing singola che non richiede modifiche o monitoraggio futuro, i codici QR statici potrebbero essere più adatti. Se desideri maggiore flessibilità, analisi delle scansioni QR, retargeting online e scansioni più rapide, i codici QR dinamici sono decisamente consigliati rispetto a quelli statici.`,

    how_change_dynamic_title: "Come posso trasformare un codice QR statico in dinamico?",
    how_change_dynamic: `Non puoi trasformare un codice QR statico in dinamico perché i codici QR statici non possono essere modificati una volta creati.`,

    how_qrcode_free_title: "Come posso creare un codice QR gratuito?",
    how_qrcode_free: `Vai sul generatore QR Code Matic e inizia a creare gratuitamente il tuo codice QR. Puoi scaricarlo nel formato desiderato e personalizzarlo. Nota: i codici QR statici sono gratuiti a tempo indeterminato. Per creare codici QR dinamici devi attivare una prova gratuita di 7 giorni o acquistare un piano.`,

    what_is_qr_code_title: "Che cos'è un codice QR?",
    what_is_qr_code: `QR significa Quick Response (risposta rapida). È un tipo di codice a barre bidimensionale sviluppato dalla società Denso Wave che consente di criptare centinaia di caratteri di dati. Solitamente è composto da pixel bianchi e neri. I dati codificati possono essere rapidamente decifrati e visualizzati da smartphone o tablet.`,

    how_can_i_generate_qr_code_title: "Come posso creare un codice QR?",
    how_can_i_generate_qr_code: `Creare un codice QR è semplice. Usa il generatore di QR Code Matic sul nostro sito per generare rapidamente codici QR. Inserendo informazioni come testo, link, numero di telefono o e-mail, puoi ottenere un codice QR in pochi secondi. I codici QR consentono agli utenti di accedere rapidamente ai dati e sono ideali per condividere informazioni in modo pratico.`,

    what_should_I_consider_when_printing_title: "Cosa devo considerare quando stampo un codice QR?",
    what_should_I_consider_when_printing: `Per documenti stampati, è importante utilizzare formati di output ad alta risoluzione. Con QR Code Matic, puoi esportare codici QR non solo in PNG, ma anche in formati vettoriali come AI, EPS e SVG, che possono essere ingranditi senza perdita di qualità. I formati vettoriali sono ideali per applicazioni di stampa su larga scala. È sempre consigliato testare i codici con diversi smartphone e app prima della pubblicazione.`,

    how_can_QR_codes_be_scanned_title: "Come posso scansionare i codici QR?",
    how_can_QR_codes_be_scanned: `Scansionare i codici QR è semplice. Puoi farlo usando l'app della fotocamera integrata nel tuo smartphone o app dedicate per la scansione di codici QR. Puntando la fotocamera verso il codice QR, il dispositivo generalmente lo riconosce automaticamente e visualizza il contenuto collegato (sito web, testo, numero di telefono, ecc.). Alcuni dispositivi possono richiedere un'applicazione aggiuntiva.`,

    how_to_use_QR_codes_title: "Come si usano i codici QR?",
    how_to_use_QR_codes: `Con la crescente diffusione degli smartphone, l'importanza dei codici QR è aumentata. Sono principalmente utilizzati per il marketing mobile, integrando contenuti digitali come siti web, video, PDF o dati di contatto in materiali stampati come volantini, poster, cataloghi o biglietti da visita. Le possibilità applicative sono praticamente illimitate.`,

    can_I_customize_QR_codes_visually_title: "Posso personalizzare visivamente i codici QR?",
    can_I_customize_QR_codes_visually: `Grazie alla tolleranza agli errori, è possibile personalizzare visivamente i codici QR entro certi limiti senza comprometterne la leggibilità. Ad esempio, puoi impostare colori di primo piano e sfondo, aggiungere il tuo logo o modificare i modelli. È però importante testare sempre la leggibilità dopo ogni modifica.`,

    what_file_formats_and_size_should_i_use_for_printing_title:
      "Quali formati e dimensioni del file devo usare per la stampa?",

    what_file_formats_and_size_should_i_use_for_printing: `Per le applicazioni di stampa su larga scala si raccomanda l'uso di file vettoriali per evitare perdite di qualità. Oltre al tipo di file, è importante prestare attenzione anche alle dimensioni del codice QR per garantirne la leggibilità. I codici QR statici, che contengono una maggiore quantità di informazioni, richiedono dimensioni maggiori. Più contenuto viene criptato, più spazio è necessario. Per garantire un'interazione utente agevole, il codice deve essere facile da scansionare. La dimensione minima consigliata del codice QR è di 2 x 2 cm. Tra la distanza di scansione e la dimensione del codice QR deve esserci un rapporto minimo di 1/10. Ad esempio, se desideri che il codice sia leggibile da 10 metri, il tuo codice QR deve essere di almeno 1 metro x 1 metro. Evita di posizionare i codici su superfici irregolari. Pieghe su brochure o pagine possono influire negativamente sulla leggibilità del codice.`,

    where_and_how_to_use_qr_code_title: "Dove e come si utilizza il codice QR?",

    where_and_how_to_use_qr_code: `Le applicazioni dei codici QR sono illimitate. I codici QR offrono infinite opportunità, dalla condivisione delle informazioni personali alla risoluzione di problemi aziendali legati a marketing e logistica.`,
  },
  WHY_QR_CODE: {
    unlimited_title: "È illimitato",
    unlimited:
      "Con i codici QR di QRCodeMatic.com puoi fornire ai tuoi utenti accesso illimitato a video, PDF, messaggi, informazioni e contenuti di ogni tipo.",

    attractive_title: "È attraente",
    attractive:
      "Il QR Code che utilizzi attira naturalmente l’attenzione e suscita curiosità. Con la giusta strategia, puoi aumentare il livello di interesse e migliorare i risultati. Poiché è orientato ai risultati, la soddisfazione del cliente aumenterà visibilmente.",

    everywhere_is_a_showcase_for_you_title: "Ogni luogo è una vetrina per te",
    everywhere_is_a_showcase_for_you:
      "Ogni luogo in cui è presente un QR Code registrato dei tuoi prodotti è la tua vetrina personale. Dai prodotti visti e apprezzati da altri utenti, fino a brochure, biglietti da visita o snack offerti, tutto diventa una vetrina vivente per te.",

    add_on_your_product_title: "Aggiungi la brochure informativa direttamente sul tuo prodotto",
    add_on_your_product:
      "Puoi applicare sui tuoi prodotti come utensili, dispositivi, prodotti consumabili ed equipaggiamenti, manuali d'uso, dettagli, istruzioni e documenti (video, PDF, file) evitando così brochure che occupano spazio e si rovinano facilmente. Inoltre, aumenta la tua affidabilità condividendo facilmente con gli utenti i permessi speciali e le certificazioni del prodotto.",

    your_successful_sales_representative_title: "È il tuo rappresentante di vendita di successo",
    your_successful_sales_representative:
      "Rende disponibili i tuoi prodotti nella tua vetrina 24 ore su 24, consigliandoli agli utenti e fornendo un'esperienza di acquisto divertente e coinvolgente.",

    makes_you_easy_to_reach_title: "Ti rende facilmente raggiungibile",
    makes_you_easy_to_reach:
      "Grazie alla sua struttura integrata con Google Maps, puoi includere tutte le informazioni del tuo profilo aziendale, come la tua posizione sulla mappa, numero di telefono, email, messaggi, link ai social media, consentendo ai clienti di aggiungerti ai loro contatti e ottenere facilmente le tue informazioni di localizzazione.",

    it_is_social_title: "È social",
    it_is_social:
      "Consente la visualizzazione di tutti i tuoi account social media, incoraggiando gli utenti a seguire il tuo marchio, mettere mi piace, commentare, inviare reclami o esprimere soddisfazioni.",

    lets_you_show_your_creativity_title: "Ti permette di mostrare la tua creatività",
    lets_you_show_your_creativity:
      "È una piattaforma di marketing user-friendly per eventi speciali, lotterie, campagne e sconti. Puoi creare applicazioni digitali intriganti per aumentare la notorietà del marchio e distribuire buoni regalo, eventi e prodotti promozionali. L'unico limite a queste applicazioni è la tua creatività e immaginazione.",

    low_cost_title: "È economico",
    low_cost:
      "Considerando i benefici ottenuti, è uno strumento di promozione e marketing molto conveniente. Puoi trovare maggiori dettagli sui nostri pacchetti su QRCodeMatic.com.",

    durable_title: "È durevole",
    durable:
      "Considerando i benefici ottenuti, è uno strumento di promozione e marketing molto conveniente. Puoi trovare maggiori dettagli sui nostri pacchetti su QRCodeMatic.com.",
  },

  BILLING: {
    when_can_i_use_my_package_title: "Quando posso utilizzare il mio pacchetto?",
    when_can_i_use_my_package:
      "Dopo aver effettuato il pagamento e inviato l'ordine, tutte le funzioni del tuo piano saranno immediatamente disponibili e potrai iniziare subito a creare nuovi codici QR o gestire i tuoi codici di prova gratuita.",

    can_i_upgrade_to_a_higher_plan_title: "Posso passare a un pacchetto superiore?",
    can_i_upgrade_to_a_higher_plan:
      "Sì. Se hai raggiunto il limite dei codici QR dinamici o desideri sbloccare ulteriori funzionalità, puoi passare in qualsiasi momento a un piano superiore. Riceverai successivamente un rimborso proporzionale.",

    how_long_is_the_contract_period_title: "Quanto dura il periodo del contratto?",
    how_long_is_the_contract_period:
      "Se il contratto non viene disdetto, si rinnova automaticamente per il periodo selezionato.",

    how_do_i_cancel_my_subscription_title: "Come posso annullare il mio abbonamento?",
    how_do_i_cancel_my_subscription:
      "Se non desideri rinnovare il tuo contratto, ti basterà inviare un'e-mail ufficiale. È importante inviare la richiesta in tempo, considerando che il rinnovo può essere effettuato fino all'ultimo giorno del periodo contrattuale.",
  },
  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: `Quali tipi di report analitici offrite?`,
    what_kind_of_analytics_reporting_do_you_offer: `I dati analitici per gli URL reindirizzati che utilizzano il nostro servizio di abbreviazione URL sono disponibili nel pannello di controllo del tuo account in tempo reale e in un formato ricercabile per data, ora, tipo di dispositivo e posizione per ciascun evento di scansione.`,

    can_analytics_be_used_with_all_your_QR_codes_title: `Le analitiche possono essere utilizzate con tutti i vostri codici QR?`,
    can_analytics_be_used_with_all_your_QR_codes: `I dati analitici possono essere raccolti soltanto quando crei un codice QR dinamico che indirizza gli utenti tramite un URL abbreviato al nostro sito, permettendoci così di raccogliere le informazioni necessarie. Molti altri generatori di codici QR obbligano la creazione di codici QR dinamici, ma noi lo rendiamo opzionale in modo che venga visualizzato direttamente l'URL reale del tuo sito quando il codice QR viene scansionato. Lo svantaggio è che, poiché il codice QR statico non ha un meccanismo di tracciamento integrato, se crei un codice QR statico, il visitatore verrà indirizzato direttamente all'URL o al contenuto codificato senza passare dal nostro server. In questo caso, non avremo l'opportunità di raccogliere dati analitici, pertanto potrebbe essere necessario utilizzare uno strumento di analisi come Google Analytics direttamente sull'URL di destinazione del codice QR.`,

    how_can_I_access_my_analytics_reports_title: `Come posso accedere ai miei report analitici?`,
    how_can_I_access_my_analytics_reports: `Una volta effettuato l'accesso al tuo account, puoi accedere al pannello di controllo tramite il link nell'angolo in alto a destra della pagina. Nel rapporto storico, la scheda \"Statistiche\" accanto a ciascun codice QR ti fornirà informazioni dettagliate. Ogni codice QR dinamico creato mostra un link \"Analytics\" sul lato destro dell'elenco dettagliato del codice QR. Se non vedi l'opzione \"Analytics\", significa che il codice QR creato è statico e non dispone di report analitici.`,

    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title: `I numeri di telefono e gli indirizzi e-mail sono inclusi nei report analitici?`,
    are_phone_numbers_and_email_addresses_included_in_analytics_reports: `No. Non è possibile accedere in alcun modo ai dati identificabili degli utenti (come numeri di telefono o indirizzi e-mail) archiviati sui dispositivi utilizzati per scansionare il codice QR, a causa dei protocolli di privacy e sicurezza integrati nel dispositivo stesso.`,
  },

  login_note: `<div style=\"font-weight: 900\">IMPORTANTE!</div> <span class=\"demo-time\"> Al termine del periodo di prova</span>, puoi continuare a creare <span class=\"font-weight-bolder\">codici QR statici GRATUITI</span> utilizzando le funzionalità interne e continuare a utilizzare il tuo <span class=\"font-weight-bolder\">ACCOUNT</span>.`,
  MENU: {
    NEW: "Nuovo",
    ACTIONS: "Azioni",
    CREATE_POST: "Crea Nuovo Post",
    PAGES: "Pagine",
    FEATURES: "Funzioni",
    APPS: "Applicazioni",
    DASHBOARD: "Dashboard",
    MARKET: "Mercato",
    MYQRCODES: "I miei QR Code",
    ALLQRCODES: "Tutti i QR Code",
    ACTIVEQRCODES: "QR Code Attivi",
    PASSIVEQRCODES: "QR Code Passivi",
    DELETEDQRCODES: "QR Code Eliminati",
    MASTERQRCODES: "QR Code Multipli",
    SUBSCRIPTIONS: "Prezzi e Pacchetti",
    MYFOLDERS: "Le mie Cartelle",
    QRFOLDERS: "Cartelle QR Code",
    MYMEDIA: "I miei Media",
    ADDNEWFOLDER: "Aggiungi Nuova Cartella",
    CREATENEWQRCODE: "Crea Nuovo QR Code",
    MYACCOUNT: "Il mio Account",
    PAIDPLAN: "Piano di Pagamento",
    ORDERHISTORY: "Storico Ordini",
    SUBMITTICKET: "Supporto",
    HELP: "Aiuto",
    PACKAGE_HISTORY: "Storico Pacchetti",
    DAY_REMANING: "Giorni Rimasti",
    SCAN: "Scansione",
    STATIC: "Statico",
    DYNAMIC: "Dinamico",
    PACKAGE_TITLE: "DETTAGLI PACCHETTO",
    CORPARATE: "Aziendale",
    TITLE: "Menu",
    BULKPRICE: "Aggiorna rapidamente i prezzi del menu",
    MY_FOLDER: "LE MIE CARTELLE",
  },
  QRLIST: {
    STATIC: "Statico",
    PREVIEW: "Puoi visualizzare un'anteprima dei codici QR creati.",
    DYNAMIC: "Dinamico",
    MULTIPLY_QRCODE: "Duplica Codice QR",
    BULK_CREATE:
      "Puoi aggiungere nuovi codici QR sotto questo codice QR e aggiornare in massa i contenuti e i design dei codici QR sottostanti.",
    BULK_UPLOAD: "Caricamento Multiplo",
    BULK_UPDATE: "Aggiornamento Multiplo",
    BULK_SETTINGS: "Impostazioni di Caricamento/Aggiornamento Multiplo",
    CLICK_TO_DOWNLOAD: "Clicca per scaricare",
    CLICK_TO_UPLOAD: "Clicca per caricare il file Excel",
    CLICK_TO_UPDATE: "Clicca per modificare",
    SELECT_FILE: "Seleziona File",
    DOWNLOAD_SAMPLE_FILE: "Scarica file di esempio",
    DOWNLOAD_SUCCESSFUL: "Download completato!",
    CLICK_HERE_TO_CUSTOMIZE: "Clicca qui per personalizzare i colori e il design del tuo codice QR!",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "Crea cartelle tramite il menu a sinistra per filtrare i tipi di codici QR desiderati per le tue campagne.",
    CLICK_HERE_TO_EDIT: "Clicca sul titolo per modificare i contenuti della tua scheda!",
    NON_TRACKABLE: "Statico / Non tracciabile",
    MY_QR_CODE: "Stai per eliminare My Qr Card con ID",
    DOWNLOAD_IN_PDF_FORMAT: "Scarica in formato PDF",
    DOWNLOAD_IN_PNG_FORMAT: "Scarica in formato PNG",
    DOWNLOAD_IN_SVG_FORMAT: "Scarica in formato SVG",
    DOWNLOAD_IN_EPS_FORMAT: "Scarica in formato EPS",
    DESC: `Prova il tuo QR Code su diversi dispositivi prima del download. Usa .svg, .eps, .pdf per la stampa e .png per il sito web. Una volta verificati, i tuoi codici QR saranno pronti per la stampa.`,
    NOTE: "Nota: I QR Code Radial e Gradient vanno scaricati come file .svg.",
    BULK_UPLOAD_DESC:
      "Per creare più QR code contemporaneamente, scarica e compila correttamente il file Excel di esempio. Ogni riga creerà un nuovo codice QR.",
    BULK_CREATE_DESC: "Scarica tutti i codici QR contemporaneamente e modifica i contenuti.",
    BULK_UPDATE_DESC: "Scarica tutti i codici QR contemporaneamente e modifica i contenuti.",
    UPLOAD_YOUR_FILE: "Carica il tuo file",
  },
  CREATEQR: {
    MENU: {
      DYNAMIC: {
        TITLE: "QR Code Dinamico",
        ITEMS: {
          WEBSITE: "Sito Web",
          RESTAURANT: "Ristorante",
          FEEDBACK: "Feedback",
        },
      },
      STATIC: {
        TITLE: "QR Code Statico",
        ITEMS: {},
      },
    },
    WIZARD: {
      BASIC_INFO: {
        TITLE: "Aggiungi le Informazioni sul Contenuto",
        CATEGORIES: "Categorie",
        CATEGORY_IMAGE: "Immagine della Categoria",
        SELECT_IMAGE: "Seleziona Immagine",
        LOADER_TEXT: "",
        THIS_URL_IS_INVALID: "Questo URL non è valido",
        FIELDS: {
          key: "Alan",
          value: "Valore",
          start: "Inizio",
          end: "Fine",
          video: "Video",
          title: "Titolo",
          image: "Immagine",
          text: "Testo",
          color: "Colore",
          description: "Descrizione",
          name: "Nome",
          number: "Numero",
          phone: "Telefono",
          buttonText: "Testo del Pulsante",
          url: "URL",
          BASIC_INFORMATION: "Informazioni Generali",
          SETTINGS: "Impostazioni",
          LANGUAGE: "Seleziona Lingua",
          ACTIVE_LANGUAGE: "Lingua Attiva",
          ADD_SOME_INFO: "Inserisci le informazioni richieste",
          FULL_NAME: "Nome Completo",
          COMPANY_NAME: "Nome Azienda",
          JOB_TITLE: "Titolo Lavorativo",
          DEPARTMENT: "Dipartimento",
          BLOOD_GROUP: "Gruppo Sanguigno",
          FOLDER_NO: "Numero di Cartella",
          DATE_OF_START: "Data di Inizio Lavoro",
          COMPANY: "Azienda",
          MAIL: "E-mail",
          WEBSITE: "Sito Web",
          PHONE: "Telefono",
          ADDRESS: "Indirizzo",
          ENTER_LOCATION: "Seleziona la Posizione",
          PLEASE_CHOOSE_LOCATION: "Seleziona prima la Posizione",
          MANAGER_FULL_NAME: "Nome del Responsabile",
          MANAGER_PHONE: "Telefono del Responsabile",
          MANAGER_JOBTITLE: "Ruolo del Responsabile",
          MANAGER_MAIL: "E-mail del Responsabile",
          ART_NAME: "Nome dell'Opera",
          ART_ACCESSION_NUMBER: "Numero di Inventario",
          ART_ERA: "Epoca",
          ART_MEASUREMENT: "Misure",
          ART_DISCOVERY_LOCATION: "Luogo di Scoperta",
          ART_ACCESSION_DATE: "Data di Acquisizione",
          ART_DESCRIPTION: "Descrizione dell'Opera",
          RESTAURANT_ABOUT_US: "Chi Siamo del Ristorante",
          RESTAURANT_ADDRESS: "Indirizzo del Ristorante",
          RESTAURANT_PHONE: "Telefono del Ristorante",
          RESTAURANT_EMAIL: "E-mail del Ristorante",
          BLOODGROUP: "Gruppo Sanguigno",
          MEDICATION: "Trattamento Farmacologico",
          PLATENUMBER: "Numero di Targa",
          ALLERGICTO: "Allergico a",
          DESCRIPTION: "Descrizione",
          RESPONSIBLESTAFFPHONE: "Telefono del Personale Responsabile",
          CITY: "Città",
          FAX: "Fax",
          ZIP: "CAP",
          ADD_A_NEW_DOCUMENT: "Aggiungi un Nuovo Documento",
          QR_CODE_TITLE: "Titolo del Codice QR",
          general_info: "Informazioni Generali",
          SHOW_CONTACT_FROM: "Mostra il Modulo di Contatto",
          SHOW_GOOGLE_MAPS: "Mostra Google Maps",
          SHOW_ADD_TO_CONTACTS: "Mostra l'opzione Aggiungi ai Contatti",
          SHOW_ADDRESS_FIELD: "Mostra il Campo Indirizzo",
          ADDITIONAL_INFO: "Informazioni Aggiuntive",
          SHOW_PHONE_ICON: "Mostra l'Icona del Telefono",
          SHOW_MAIL_ICON: "Mostra l'Icona dell'E-mail",
          CONTACT_INFO: "Informazioni di Contatto",
          FIELDS: "Campi",
          YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "Devi compilare i campi prima di fare domanda.",
          SELECT_PDF: "Seleziona PDF",
          FDF_MENU_IF_YOU:
            "Se desideri utilizzare menu unici per ogni ristorante, puoi aggiungere di più qui. Ogni ristorante avrà il proprio codice QR.",
          DESCRIPTION_FIELD: "Puoi scrivere la descrizione per questo campo qui",
          CURRENT_RESTAURANTS: "Ristoranti Correnti",
          NO_TITLE: "Nessun Titolo",
          NEW_RESTAURANT: "Nuovo Ristorante",
          BOOK_TITLE: "Titolo del PDF",
          PERSONNEL_INFO: "Informazioni sul Personale",
          COMPANY_INFO: "Informazioni sull'Azienda",
          ENABLE_DOCUMENTS_PAGE: "Abilita la Pagina Documenti",
          ENABLE_REMINDER: "Abilita Promemoria E-mail",
          ENABLE_COUNTER: "Abilita il Contatore",
          CUSTOM_FIELDS: "Campi Personalizzati",
          CALL_LIST: "Lista Chiamate",
          PERSON: "Persona",
          EMERGENCY_CALLS: "Chiamate di Emergenza",
          DOCUMENTS_LIST: "Lista Documenti",
          GROUPS: "Gruppi",
          TITLE: "Titolo",
          SCHEDULE_INFO: "Informazioni sul Programma",
          DAY: "Giorno",
          SHOW_SCHEDULE_CALENDER: "Mostra il Calendario del Programma",
          MANAGER_INFO: "Informazioni sul Responsabile",
          MAPS_STYLE: "Stile della Mappa",
          TEXT_ADDRESS: "Testo",
          TITLE_ADDRESS: "Titolo",
          SONG: "Canzone",
          ADD_MORE: "Aggiungi Altro",
          ARTIST: "Artista",
          NO_RESTRICTION: "Nessuna Restrizione",
          ARTIFACT_INFORMATION: "Informazioni sull'Opera",
          ARTIFACT_INFO: "Informazioni sull'Opera",
          STORE_INFORMATION: "Informazioni sul Negozio",
          PLATFORM: "Piattaforma",
          OTHER_FIELDS: "Altri Campi",
          WIFINAME: "Nome WiFi",
          RANDOM_LINK: "Link Casuale",
          SHOW_RANDOM_LINK: "Mostra un elemento casuale dalla lista.",
          SHOW_ALL_RANDOM_LINK: "Mostra tutti gli elementi dalla lista.",
          MULTIPLE_LINK: "Link Multipli",
          LINKS: "Link",
          PRODUCT_NAME: "Nome del Prodotto",
          GO_TO_WEBSITE: "Vai al Sito",
          SELECT_ICON: "Seleziona Icona",
          brochure_image: "Immagine del Banner",
          SELECT: "Seleziona",
          SELECT_IMAGE: "Seleziona Immagine",
          SELECT_RELATED_CATEGORY: "Seleziona la Categoria Correlata",
          ABOUT_US_PAGE_INFO: "Informazioni sulla Pagina Chi Siamo",
          CONTACT_PAGE_INFO: "Informazioni sulla Pagina Contatti",
          RESERVATION_PAGE_INFO: "Informazioni sulla Pagina Prenotazioni",
          ABOUT_US_PAGE: "Pagina Chi Siamo",
          CONTACT_PAGE: "Pagina Contatti",
          RESERVATION_PAGE: "Pagina Prenotazioni",
          BROCHURES: "Brochure",
          BANNER: "Banner",
          type: "Tipo",
          REDIRECT: "Reindirizzamento",
          SONGURL: "Incolla il link della canzone...",
          isActive: "Attivo/Inattivo",
          proximity: "Prossimità",
          sort_order: "Ordine di Classifica",
          SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Mostra i pulsanti dei social media",
          fileUrl: "URL del file",
          fileFormat: "Formato",
        },
      },
      VALIDATION: {
        VAILD_PHONE_NUMBER: "Per favore, inserisci un numero di telefono valido",
      },
      CUSTOMIZE: {
        TITLE: "Design e Personalizzazione",
        FIELDS: {
          CUSTOMIZE_TEXT: "Personalizza i tuoi testi",
          CUSTOMIZE_YOUR_PAGE: "Personalizza la tua pagina scegliendo il colore",
          SINGLE_COLOR: "Colore Singolo",
          FIRST_COLOR: "Primo Colore",
          SECOND_COLOR: "Secondo Colore",
          BG_COLOR: "Colore di Sfondo",
          GRADIENT: "Colore Gradiente",
          COLOR: "Colore",
          PRIMARY: "Colore Principale",
          TEXT_COLOR: "Colore del Testo",
          BUTTONS: "Pulsanti",
          CHOOSE_BACKGROUND: "Scegli lo sfondo o il colore",
          IMAGE: "Immagine",
          CHOOSE_FILE_DROP: "Scegli un file o trascinalo qui",
          BODY_OPTION: "Opzioni Corpo",
          LINEAR: "Lineare",
          RADIAL: "Radiale",
          TRANSPARENT_BACKGROUND: "Sfondo Trasparente",
          CUSTOMIZE_YOUR_PAGE_LAYOUT:
            "Puoi personalizzare il layout della tua pagina modificando le opzioni di attivazione/disattivazione qui sotto.",
          CONTACT_EMAIL_LABEL: "Indirizzo email a cui inviare il modulo di contatto",
        },
      },
      SOCIAL_MEDIA_CHANNELS: {
        TITLE: "Collegamenti ai Social Media",
        FIELDS: {
          LINK_YOUR_SOCIAL: "Aggiungi i tuoi collegamenti ai social media",
          YOU_CAN_REMOVE: "Puoi rimuovere il collegamento cliccando sulla croce",
          ADD_MORE: "Aggiungi altro",
        },
      },
      MEDIA: {
        TITLE: "Aggiungi Immagini & Video",
        FIELDS: {
          UPLOAD_PROFILE_PHOTO: "Carica una foto del profilo",
          PHOTO: "Foto",
          STORY: "Storia",
          NOTE: "Per ottenere i migliori risultati, scegli una foto con un rapporto 1:1 (es: 300px/300px)",
          CHOOSE_MEDIA: "Seleziona contenuti multimediali dal tuo archivio locale",
          VIDEO: "Video",
          CHOOSE_STORY_VIDEO: "Carica un video da mostrare nella tua storia",
          YOUTUBE_VIDEO: "Incolla il link del video YouTube",
          PHOTOS: "Foto",
          CHOOSE_PHOTO: "Seleziona una o più foto per le tue storie",
        },
      },
      CATEGORY: {
        TITLE: "Categorie & Prodotti",
      },
      WELCOME_SCREEN: {
        TITLE: "Personalizza la Schermata di Benvenuto",
        FIELDS: {
          LOADING_SCREEN_LOGO: "Logo della Schermata di Benvenuto",
          SELECT_FROM_MEDIA: "Clicca per selezionare dai miei media",
          SELECT_FROM_ANIMATIONS: "Aggiungi il tuo logo",
          LOADING_SCREEN_BACKGROUND: "Sfondo della Schermata di Benvenuto",
          SINGLE_COLOR: "Colore Singolo",
          GRADIENT: "Colore Gradiente",
          YOUR_INFORMATION: "Le tue informazioni",
          FIRST_FIELD: "Testo di Caricamento",
          SECOND_FIELD: "Secondo Campo",
        },
      },
      ADVANCED_SETTINGS: {
        TITLE: "Impostazioni Avanzate",
        FIELDS: {
          PASSWORD: "Password",
          AGE_BELOW: "Limite di Età",
          PASS_CODE_HERE: "Incolla il codice qui...",
          TIME_ZONE: "Fuso Orario",
          PICK_START_DATE: "Data di Inizio",
          CANCEL: "Annulla",
          OK: "OK",
          STARTING_DATE: "Ora di Inizio",
          ENDING_DATE: "Ora di Fine",
          PICK_END_DATE: "Data di Fine",
        },
      },
      CREATEQR: "Crea Codice QR",
      UPDATE_CHANGES: "Aggiorna le informazioni",
    },
    FOLLOWALBE: "Tracciabile (A pagamento)",
    NOT_TRACKED: "Non tracciabile (Gratuito per sempre)",
  },
  RESTAURANT: {
    title: "Titolo: ",
    description: "Descrizione: ",
    cover_image: "Immagine di copertina: ",
    category_icon: "Icona della categoria: ",
    categories: "Categorie",
    category: "Categoria",
    product: "Prodotto",
    products: "Prodotti: ",
    product_name: "Nome del prodotto",
    preparation: "Tempo di preparazione: ",
    calorie: "Calorie",
    ingredients: "Ingredienti: ",
    price: "Prezzo: ",
    price_label: "Etichetta prezzo",
    likes: "Mi piace: ",
    current_categories: "Categorie attuali",
    add_new_field: "Aggiungi nuovo campo",
    add_new_category: "Aggiungi nuova categoria",
    add_new_product: "Aggiungi nuovo prodotto",
    add_new_price: "Aggiungi",
    please_add_product: "Aggiungi un prodotto",
    brochure_slider: "Banner",
    delete_product: "ELIMINA PRODOTTO",
    delete_category: "ELIMINA CATEGORIA",
    new_category_title: "Nuova Categoria",
    new_category_description: "Descrizione nuova categoria",
    new_product_title: "Nuovo Prodotto",
    new_product_description: "Descrizione nuovo prodotto",
    status_desc: "Le categorie inattive saranno rimosse dalla pubblicazione.",
    status: "Stato",
    detailed_title: ({ named }) => `Dettagli di ${named("value")}`,
  },
  ERRORS: {
    err_1: "Errore; Ci scusiamo, si è verificato un errore sconosciuto. Per favore, riprova più tardi.",
    err_2:
      "Errore; Ci scusiamo, si è verificato un errore imprevisto durante il caricamento dell'avatar. Per favore, riprova.",
    err_3: "Errore; Ci scusiamo, l'indirizzo del collegamento è errato. Per favore, utilizza un indirizzo valido.",
    err_4:
      "Errore; Ci scusiamo, questa e-mail è già stata confermata. Per favore, prova con un altro indirizzo e-mail.",
    err_6: "Errore; Ci scusiamo, si è verificato un errore. Per favore, riprova più tardi.",
    err_7: "Errore; Ci scusiamo, sono state effettuate troppe richieste. Riprova tra 1 minuto.",
    err_8:
      "Errore; Il tuo account non è stato verificato! Per favore, clicca sul pulsante 'Invia di nuovo' sotto la tua password per ricevere l'e-mail di verifica.",
    err_9: "Errore; Ci scusiamo, la password è errata. Per favore, inserisci la password corretta.",
    err_10: "Errore; Ci scusiamo, nessun utente trovato con questo indirizzo.",
    err_11: "Errore; Ci scusiamo, non hai accettato il contratto utente. Per favore, accetta il contratto.",
    err_12: "Errore; Ci scusiamo, l'utente è stato eliminato. Per favore, contatta il nostro supporto.",
    err_13: "Errore; Ci scusiamo, l'account utente è stato sospeso. Per favore, contatta il nostro supporto.",
    err_14: "Errore; Ci scusiamo, lo stato dell'account è inattivo. Per favore, contatta il nostro supporto.",
    err_15: "Errore; Il tuo piano attuale ha una durata superiore a 1 anno. Per favore, contatta il nostro supporto.",
    err_16:
      "Avviso!!!; Hai una transazione in sospeso. Per effettuare un nuovo ordine, devi prima annullare l'ordine esistente. Vuoi annullarlo?",
    err_17: ({ named }) =>
      `Errore; Ci scusiamo, lo spazio disco di ${named(
        "value"
      )} mb è pieno. Devi liberare spazio per caricare un nuovo file. Puoi contattare il nostro supporto.`,
    err_18: "Errore; Questo codice è già stato utilizzato. Per favore, prova con un altro codice.",
    err_19: "Errore; Codice non valido. Per favore, inserisci un codice valido.",
    err_20: "Errore; Questo codice è scaduto. Per favore, prova a ottenere un nuovo codice.",
    err_21: "Errore; Non è possibile passare al piano inferiore.",
    err_22: "Errore; Codice non valido. Per favore, inserisci un codice valido.",
    err_23: "Errore; Chiave non valida. Per favore, inserisci una chiave valida.",
    err_24:
      "Errore; Nessun utente trovato con questo indirizzo di posta. Per favore, utilizza un indirizzo di posta valido.",
    err_25: "Errore; Il nome del file non può essere vuoto.",
    err_26: "Errore; Hai già usato questo nome di file. Per favore, scegli un altro nome per il file.",
    err_27: ({ named }) =>
      `Errore nel pacchetto; Ci scusiamo, il codice ${named(
        "value"
      )} non è valido nel pacchetto che stai cercando di acquistare.`,
    err_100: "Errore; Il campo e-mail è obbligatorio.",
    err_101: "Errore; Il campo e-mail deve essere di tipo testo.",
    err_102: "Errore; Per favore, inserisci un indirizzo e-mail valido.",
    err_103: "Errore; Il campo e-mail deve essere al massimo di 255 caratteri.",
    err_104: "Errore; Questo indirizzo e-mail è già registrato.",
    err_105: "Errore; L'indirizzo e-mail non è accettato. Per favore, inserisci un dominio di e-mail valido.",
    err_106: "Errore; Il campo nome è obbligatorio.",
    err_107: "Errore; Il campo nome deve essere di tipo testo.",
    err_108: "Errore; Il campo nome deve contenere al massimo 255 caratteri.",
    err_109: "Errore; Il campo password è obbligatorio.",
    err_110: "Errore; La password deve contenere almeno 6 caratteri.",
    err_111: "Errore; La password deve contenere almeno una lettera.",
    err_112: "Errore; La password deve contenere almeno un numero.",
    err_113: "Errore; Il campo paese è obbligatorio.",
    err_114: "Errore; Hai selezionato un paese non valido.",
    err_115: "Errore; Il campo organizzazione è obbligatorio.",
    err_116: "Errore; Devi accettare i termini di utilizzo.",
    err_404:
      "Errore; La pagina o il contenuto che stai cercando non è stato trovato. Per favore, inserisci un nome di pagina o contenuto valido.",
    err_500:
      "Errore; Ci scusiamo, si è verificato un errore sconosciuto. Per favore, ricarica la pagina e riprova. Se il problema persiste, contattaci.",
    err_1001: "Errore; Ci scusiamo, non hai il permesso per questa azione. Per favore, contatta un utente autorizzato.",
    err_1002: "Errore; Ci scusiamo, il tuo piano è scaduto. Prova ad acquistare un nuovo piano.",
    err_1003:
      "Errore; Ci scusiamo, hai raggiunto il limite massimo del tuo piano. Non puoi aggiungere ulteriori contenuti.",
    err_1004:
      "Errore; Non possiedi questo tipo di codice QR. Puoi passare ai pacchetti che contengono questo tipo di codice QR.",
    err_1005: "Errore; Hai superato il limite massimo di 100. Non è possibile aggiungere altri elementi.",
    err_1006: "Errore; La verifica del collegamento è fallita. Per favore, usa informazioni di collegamento corrette.",
    err_1007: ({ named }) =>
      `Errore; Hai superato il limite valido di (${named("value")}). Pertanto, non puoi creare codici QR statici.`,
    err_1008: ({ named }) => `Errore; Il tuo piano è scaduto, puoi creare al massimo ${named("value")} cartelle.`,
    err_2001: "Errore; Carattere non valido. Per favore, usa un carattere valido.",
    err_2002: "Errore; Questo URL esiste già. Per favore, prova con un URL diverso.",
    err_2003: "Errore; Si è verificato un errore sconosciuto. Per favore, riprova più tardi.",
    err_2004: "Errore; I valori non possono essere vuoti. Per favore, inserisci un valore predefinito valido.",
    err_2005:
      "Errore; Il valore predefinito deve essere lungo almeno 3 caratteri. Per favore, inserisci un valore più lungo.",
    err_2006:
      "Errore; Il valore predefinito deve essere lungo al massimo 30 caratteri. Per favore, inserisci un valore più corto.",
    err_2007: "Errore; Devi selezionare un'immagine. Per favore, seleziona un'immagine.",
    err_2008: "Errore; I caratteri speciali non sono accettati. Puoi usare solo numeri e lettere.",
    err_2009: "Errore; Devi selezionare un file. Per favore, seleziona un file.",
    err_2010: "Errore; Sono accettati solo file. Per favore, carica un file nel formato corretto.",
    err_2011: "Errore; Puoi caricare un file di massimo 25 MB. Per favore, carica un file più piccolo.",
    err_2012: "Errore; Hai già caricato un file con questo nome. Per favore, scegli un altro nome per il file.",
    err_2013:
      "Errore; Hai un'operazione in corso. Riceverai una notifica via e-mail quando l'operazione sarà completata. Puoi riprovare più tardi.",
    err_2014:
      "Errore; Con il piano di prova puoi caricare un massimo di 10 codici QR. Per caricare più, aggiorna il tuo piano.",
    err_2015:
      "Errore; Il tuo file Excel deve contenere al massimo 400 righe per il caricamento. Per favore, aggiorna il file e riprova.",
    err_2016: "Errore; Non ci sono file di download di massa. Per favore, riprova dopo aver caricato i file.",
    err_2017: "Errore; Hai superato il limite di caricamento di massa. Per favore, contattaci.",
    err_2018: "Errore; Per caricare un nuovo file Excel, per favore elimina almeno uno dei file esistenti.",
    err_2019:
      "Errore; Sono stati trovati link non validi o di reindirizzamento nei tuoi dati. Per favore, modificali e riprova.",
    err_2020:
      "Errore; Possono essere caricati solo file Excel con estensione .xlsx. Per favore, controlla il tipo di file e riprova.",
    err_2021: "Errore; I file Excel devono essere al massimo di 2 MB. Per favore, carica un file più piccolo.",
    err_2022: "Errore; Per effettuare questa operazione, è necessario avere almeno 2 codici QR.",
    err_2023: "Errore; Per favore, seleziona almeno 1 codice QR secondario.",
    err_2024:
      "Errore; Per effettuare questa operazione, è necessario avere almeno 1 codice QR principale. Per favore, seleziona almeno 1 codice QR secondario.",
    err_2025: ({ named }) =>
      `Errore; Puoi caricare un file di massimo ${named("value")} MB. Per favore, carica un file più piccolo.`,
    err_2026:
      "Errore; Puoi caricare un'immagine con larghezza massima di 1000 px. Per favore, ridimensiona l'immagine affinché la larghezza o l'altezza non superi i 1000 px.",
    err_2027: "Errore; Il formato del dominio è errato.",
    err_2028: "Errore; Questo dominio è già stato aggiunto.",
    err_2029: "Errore; La registrazione CNAME non può essere verificata.",
    err_2030: "Errore; Questo dominio non può essere aggiunto, la registrazione CNAME non può essere verificata.",
    validation: {
      credit_card: {
        card_invalid: "Carta non valida; Assicurati di aver inserito correttamente il numero della carta!",
        card_pattern_invalid: "Carta non valida; Assicurati di aver inserito correttamente il numero della carta!",
        card_checksum_invalid: "Carta non valida; Assicurati di aver inserito correttamente il numero della carta!",
        card_length_invalid: "Carta non valida; Assicurati di aver inserito correttamente il numero della carta!",
        card_cvc_invalid: "CVC non valido; Assicurati di aver inserito correttamente il CVC!",
        card_expiration_month_invalid:
          "Mese non valido; Assicurati di aver inserito correttamente il mese di scadenza!",
        card_expiration_year_invalid: "Anno non valido; Assicurati di aver inserito correttamente l'anno di scadenza!",
      },
    },
  },
  PAYMENT: {
    unknown_reason: "Motivo sconosciuto",
    payment: "Pagamento",
    successful: "SUCCESSO",
    failed: "FALLITO",
    awaiting: "IN ATTESA",
    reason: "Motivo",
    payment_successful_desc: `Gentile cliente,
    Il suo ordine è stato ricevuto con successo e il suo pacchetto è stato accreditato sul suo account.
    Se ha domande o preoccupazioni, non esiti a contattarci. Saremo felici di aiutarla.
    Grazie per il suo interesse e la sua fiducia!`,
    payment_failed_desc: `Gentile cliente,
    Siamo spiacenti, abbiamo rilevato un errore nel suo pagamento e può ricevere supporto da parte nostra riguardo al problema.
    Saremo felici di aiutarla.`,
    payment_awaiting_desc: `Gentile cliente,
    La ringraziamo per il suo ordine! L'ultimo passo necessario per elaborare il suo ordine è il completamento del pagamento.
    Tuttavia, durante il controllo del sistema, abbiamo riscontrato che il pagamento non è stato ancora completato.
    La preghiamo di completare il pagamento attraverso i nostri numeri di conto bancario.`,
    title_success: () => `<strong>Grazie</strong>, il suo ordine è stato ricevuto!`,
    title_fail: () => `<strong>Ci scusiamo</strong>, il pagamento è fallito!`,
    title_awaiting: () => `<strong>Grazie</strong>, il suo ordine è stato ricevuto!`,
    err_105: "Il pagamento è stato annullato dall'utente",
    err_000: "Il processo di pagamento è fallito",
  },
  SNACKBAR: {
    qrcode_updated: "Codice QR aggiornato!",
    qrcode_created: "Codice QR creato!",
    verify_email: "L'email deve essere verificata",
    login_successful: "Accesso riuscito",
    logged_out: "Uscita effettuata!",
    registeration_successful: "Registrazione riuscita",
  },
  DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Benvenuto nel tour passo dopo passo",
    STEP_BY_STEP_TOUR_DESC:
      "Ciao utente! Abbiamo capito che è la tua prima volta qui. Questo tour ti guiderà tra le sezioni e ti spiegherà come funzionano le cose. Se desideri saltare il tour, puoi farlo cliccando sul pulsante in basso.",
    STEP_BY_STEP_TOUR_SKIP: "Salta",
    STEP_BY_STEP_TOUR_START: "Inizia",
    STEP_BY_STEP_TOUR_CREATE_QR: "Crea Codice QR",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "Puoi usare il pulsante sopra per creare un codice QR.",
  },
  POPUP: {
    error_title: "Errore!",
    CHOOSE_ONE: "Seleziona il tipo di codice QR",
    TRANSPARENT_QR_CODE: "Codice QR Trasparente",
    STANDART_QR_CODE: "Codice QR Standard",
    BANNER_QR_CODE: "Codice QR con Banner",
    GO_BACK: "Indietro",
    QR_CODE_BACKGROUND_PICTURE: "Voglio inserire l'immagine di sfondo che desidero nel mio codice QR",
    QR_CODE_COLOR: "Voglio personalizzare la forma e i colori del codice QR",
    QR_CODE_BANNER: "Voglio creare un banner e personalizzare le dimensioni del codice QR",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
  <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
  <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Per creare di più</span> <span style="font-weight: 200; color:#919191">aggiorna</span> <strong class="text-h4 button_green--text">il tuo piano</strong></h1>
  <p style="font-size: 1rem; max-width: 360px; text-align: justify;">Hai raggiunto il limite del tuo piano di <strong class="orange--text">${named(
    "total"
  )}</strong> codici QR</strong>. Per creare più codici QR, dai un'occhiata ai nostri piani premium</p>
  <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Aggiorna <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
</div>`,
    packageController: ({ named }) => `
<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
  <h1 class="text-h5">Il tuo piano è <strong style="color: tomato">scaduto!</strong></h1>
  <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Acquista un piano</strong> e continua a usare QR Code Matic.</p>
  <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Aggiorna <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
</div>
`,
    upgradeToPro: ({ named }) => `
<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
  <h1 class="text-h5">Questa funzionalità è disponibile solo per <strong style="color: tomato">PRO</strong></h1>
  <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Acquista un piano</strong> e continua a usare QR Code Matic.</p>
  <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Aggiorna <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
</div>
`,
    allowedTypeController: ({ named }) => `
<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
  <h1 class="text-h5"><strong class="orange--text">${named(
    "typeName"
  )}</strong> per continuare a utilizzarlo, aggiorna il tuo piano</h1>
  <p>Il piano che stai utilizzando non include questo tipo di codice QR.<strong>Passando al piano Pro</strong>, potrai utilizzare questo e altri codici QR premium senza limiti.</p>
  <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Aggiorna <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
</div>
`,
    getMoreInformation: ({ named }) => `
<div>
 <p>La configurazione della lingua ti consente di inserire informazioni diverse per ogni lingua che scegli. L'utente finale del codice QR vedrà un menu a discesa nell'angolo in alto a destra nella versione live se aggiungi più lingue.</p>
 <p>Funziona come segue;</p>
 <ol>
  <li>Prima clicca sul pulsante <span class="font-weight-bold">"+" </span></li>
  <li>Scegli le lingue che desideri aggiungere. Puoi selezionare più lingue. Quindi seleziona una lingua per la quale il contenuto verrà copiato. (Questa opzione potrebbe non essere disponibile per questo tipo di codice QR.) Quindi clicca su <span class="font-weight-bold">"OK"</span></li>
  <li>Ora puoi fare clic sulle bandiere delle lingue aggiunte e premere il pulsante <span class="font-weight-bold">"Attiva"</span>. In questo modo, puoi modificare le informazioni del contenuto per questa lingua specifica.</li>
  <li>Per rimuovere una lingua aggiunta, fai clic sul pulsante <span class="font-weight-bold">"Elimina"</span>. Ricorda, è necessario avere almeno una lingua.</li>
  <li>Cliccare sul pulsante <span class="font-weight-bold">"Imposta come predefinito"</span> ti permette di salvare questa lingua come predefinita, in modo che gli utenti vedano questa lingua quando visitano la tua pagina.</li>
 </ol>
</div>
`,
    getInfoAboutTitle: ({ named }) => `
<div>
<p class="text--justify" style="font-size: 14px">Il titolo del tuo codice QR verrà visualizzato sotto l'immagine nella pagina della lista dei codici QR come mostrato di seguito. Dopo aver completato la creazione del codice QR, verrai indirizzato a questa pagina.<br>
Puoi modificare il titolo del codice QR in seguito.</p>
<div class="d-flex justify-content-center" style="width: 100%;">
  <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
</div>
</div>
`,
  },
  BULK: {
    create_new_child: "Voglio creare nuovi codici QR secondari",
    update_childs_data: "Voglio modificare i dettagli dei miei codici QR secondari",
    update_data: "Voglio aggiornare le informazioni generali di tutti i miei codici QR secondari",
    zip_request: "Voglio scaricare tutti i miei codici QR in formato SVG",
    zip_downloaded: "Il file zip è stato scaricato!",
    download_master: "Scarica il file Excel principale",
    update_config_button_text:
      "Voglio aggiornare il colore, il design e altre impostazioni di tutti i miei codici QR secondari.",
    update_data_description: () => `<p style="text-align: center">
    Seleziona i campi che desideri aggiornare per i codici QR sotto il master dai box sottostanti.
    Tutti i codici QR sotto il master verranno automaticamente aggiornati secondo le impostazioni nel file Excel (vcard-bulk-update) che scegli.
  </p>
  <p style="text-align: center">
    Ad esempio, se selezioni la <strong>casella avatar</strong> e clicchi su <strong>Aggiorna</strong>, il valore di <strong>avatar</strong> che hai scritto nel file Excel (vcard-bulk-update) verrà applicato a tutti i codici QR sotto il master.
  </p>`,
    update_config_description: () => `<p>
  Questa operazione serve per applicare le modifiche fatte al <strong>master</strong> a tutti i codici QR secondari contemporaneamente. Tutte le impostazioni indicate nell'elenco del master verranno applicate ai codici QR secondari.
  <ul>
    <li>Tutte le impostazioni di colore dei codici QR</li>
    <li>Tutti i design dei codici QR</li>
  </ul>
</p>
<p>
  Se esegui l'aggiornamento, il design di tutti i codici QR secondari sarà lo stesso del master.
</p>`,
    create_description:
      () => `Per creare codici QR in bulk, trascina il file Excel che hai preparato nell'area di caricamento sottostante
e clicca sul pulsante <strong class="green--text">CARICA</strong>.`,
    update_options_title: "Opzioni di aggiornamento",
    avatar: "Avatar",
    are_you_sure: "Sei sicuro?",
    see_qr_codes: "Vedi i tuoi codici QR",
    see_qr_codes_tooltip: "Puoi vedere i codici QR sottostanti e riportarli alla versione master.",
    background: "Sfondo",
    settings: "Impostazioni",
    advanced_settings: "Impostazioni avanzate",
    update_data_button_text: "Aggiorna",
    update_config_button_text: "Aggiorna il design e i colori dei codici QR secondari",
    show_previously_uploaded: "Mostra i miei caricamenti precedenti di Excel",
    reached_upload_limit:
      "Hai raggiunto il limite di caricamento. Devi eliminare uno o più file dalla lista prima di poter caricare altro.",
    upload_error_text:
      "I dati nel file Excel che hai caricato non sono nel formato corretto. Controlla i campi errati indicati qui sotto e riprova a caricare il file.",
    back_to_upload: "Torna al caricamento",
    select_from_list: "Seleziona l'operazione che desideri eseguire dalla lista sottostante",
  },
  NEWS: {
    firstVisitMessage: ({ named }) =>
      `<p>Oggi vi accogliamo con una notizia entusiasmante. Abbiamo rinnovato il nostro sito per la creazione di codici QR! Abbiamo apportato una serie di miglioramenti per offrirvi un'esperienza migliore.</p>
    <p>I codici QR sono diventati uno strumento indispensabile per la condivisione rapida e facile delle informazioni. Anche noi stiamo sviluppando questa tecnologia per offrirvi il miglior servizio possibile.</p>`,

    firstVisitFullMessage: ({ named }) =>
      `<p>Oggi vi accogliamo con una notizia entusiasmante. Abbiamo rinnovato il nostro sito per la creazione di codici QR! Abbiamo apportato una serie di miglioramenti per offrirvi un'esperienza migliore.</p>

    <p>I codici QR sono diventati uno strumento indispensabile per la condivisione rapida e facile delle informazioni. Anche noi stiamo sviluppando questa tecnologia per offrirvi il miglior servizio possibile.</p>

    <p>Il nostro nuovo sito di codici QR ha un design più user-friendly. Ora, per accedere al nostro sito e creare un codice QR, vi basteranno meno passaggi. 
    Abbiamo semplificato l'interfaccia utente, eliminando i clic inutili. Abbiamo riorganizzato il design per offrirvi un'esperienza più veloce, fluida e senza intoppi.</p>

    <p>Inoltre, abbiamo aggiunto nuove funzionalità per rendere i nostri codici QR ancora più versatili ed efficaci. Ora, potete personalizzare i vostri codici QR con più dati. 
    Potete aggiungere i link al vostro sito web o ai vostri profili sui social media, creare menù con i vostri piatti preferiti, oppure creare vCard con il vostro indirizzo e-mail o numero di telefono. Offriamo molte opzioni per ampliare l'utilizzo dei nostri codici QR.</p>

    <p>La vostra sicurezza in questo ampio campo di utilizzo è la nostra priorità. Nel nostro nuovo sito di codici QR, abbiamo adottato misure di sicurezza aggiuntive per proteggere i vostri dati e informazioni. 
    Abbiamo creato una base più solida per garantire la sicurezza delle vostre informazioni personali e sensibili.</p>

    <p>I nostri codici QR sono anche molto più veloci. Grazie agli algoritmi migliorati e ai sistemi ottimizzati, potrete scansionare i vostri codici QR più rapidamente e completare le operazioni in minor tempo. Abbiamo aggiornato la nostra infrastruttura tecnologica per consentirvi di utilizzare il tempo in modo più efficiente.</p>    

    <p>Le vostre opinioni e necessità sono sempre importanti per noi. Per questo motivo, stiamo lavorando continuamente per offrirvi una migliore esperienza sul nostro sito di codici QR rinnovato.
    Se avete domande, suggerimenti o feedback sull'utilizzo del nostro sito, non esitate a contattarci. La vostra soddisfazione è il nostro successo. Benvenuti!</p>`,

    HELLO: "Ciao, siamo rinnovati!",
    we_are_improving_to_provide_you_with_better_service: "Ci stiamo migliorando per offrirti un servizio migliore",
  },
  TERMS: {
    terms_text: ({ named }) =>
      `
    <h1>Termini di utilizzo del sito QR Code Matic</h1>
    <hr>
    <p>Gli utenti che utilizzano questo sito per creare codici QR accettano i seguenti termini. Inoltre, le pagine web del nostro sito e tutte le pagine ad esse collegate (‘Sito’) sono di proprietà della Medyax Bilişim Hizmetleri, situata in FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa e gestita dalla stessa. Accedendo ai servizi offerti sul sito, accettate di essere vincolati ai termini descritti di seguito, dichiarando di avere la capacità legale di stipulare un contratto in base alla legge applicabile e di essere maggiorenni. Leggendo e comprendendo questo contratto, accettate di essere vincolati dalle condizioni indicate nel contratto. Questo contratto impone diritti e obblighi relativi all'uso del sito, e le parti si impegnano a rispettarli correttamente, tempestivamente e secondo le condizioni richieste dal contratto.</p>
    <p>Questo contratto impone diritti e obblighi relativi all'uso del sito, e le parti si impegnano a rispettarli correttamente, tempestivamente e secondo le condizioni richieste dal contratto.</p>
    <p>
    <h2>1. RESPONSABILITÀ</h2>
      <p> a. La società si riserva il diritto di modificare in qualsiasi momento i prezzi e i prodotti o servizi offerti.</p>
      <p> b. La società accetta e si impegna a garantire che l'utente possa usufruire dei servizi del sito, ad eccezione di guasti tecnici.</p>
      <p> c. L'utente accetta di non fare reverse engineering sul sito, né di cercare o ottenere il codice sorgente per scopi simili. In caso contrario, sarà responsabile dei danni derivanti a terzi, e sarà soggetto a sanzioni legali e penali.</p>
      <p> d. L'utente accetta di non produrre, condividere o pubblicare contenuti che siano contrari alla morale generale, illegali, lesivi dei diritti di terzi, ingannevoli, offensivi, osceni, pornografici, lesivi dei diritti di privacy o violino i diritti d'autore, o che incoraggino attività illegali. In caso contrario, l'utente sarà interamente responsabile dei danni derivanti e la società si riserva il diritto di sospendere o terminare i relativi account e avviare procedimenti legali.</p>
      <p> e. Le relazioni tra gli utenti del sito o tra l'utente e terzi sono di esclusiva responsabilità dell'utente.</p>
    </p>

    <h2>2. Diritti di Proprietà Intellettuale</h2>
    <p> 2.1. Tutti i diritti di proprietà intellettuale, inclusi marchi, brevetti, design, codici di sistema, informazioni e metodi presenti in questo sito, sono di proprietà della società che gestisce il sito o dei suoi licenziatari e sono protetti dalla legge nazionale e internazionale. La visita al sito o l'utilizzo dei servizi del sito non conferisce alcun diritto su questi diritti di proprietà intellettuale.</p>
    <p>2.2. Le informazioni presenti sul sito non possono essere riprodotte, pubblicate, copiate, presentate e/o trasferite in alcun modo. L'intero sito o parti di esso non possono essere utilizzati senza autorizzazione su un altro sito web.</p>

    <h2>3. Informazioni Riservate</h2>
    <p> 3.1. La società non divulgherà le informazioni personali fornite dagli utenti tramite il sito a terzi. Queste informazioni personali includono nome, indirizzo, numero di telefono, cellulare, email e altre informazioni che identificano l'utente e sono definite come ‘Informazioni Riservate’.</p>
    <p> 3.2. L'utente accetta che le informazioni di contatto, il profilo e le informazioni demografiche possono essere condivisi dalla società con le sue affiliate o con altre società del gruppo per attività di marketing, promozioni, campagne o statistiche. Le informazioni personali possono essere utilizzate per creare profili dei clienti e per offrire promozioni o campagne adatte al profilo dell'utente.</p>
    <p>3.3. Le Informazioni Riservate possono essere divulgate solo se richiesto da un'autorità ufficiale o se necessario per rispettare le disposizioni di legge in vigore.</p>

    <h2>4. Esclusione di Garanzia</h2>
    <p>I servizi offerti dalla società sono forniti "così come sono" e "nel miglior modo possibile", senza garanzie implicite di commerciabilità, idoneità a uno scopo particolare o non violazione. La società non fornisce alcuna garanzia espressa o implicita in relazione all'uso dei servizi, comprese tutte le informazioni contenute in essi.</p>

    <h2>5. Registrazione e Sicurezza</h2>
    <p>L'utente è obbligato a fornire informazioni accurate, complete e aggiornate durante la registrazione. In caso contrario, il contratto sarà considerato violato e l'account dell'utente potrà essere chiuso senza preavviso. L'utente è responsabile della sicurezza della password e dell'account su questo sito e su siti di terzi. La società non sarà responsabile per la perdita di dati, violazioni della sicurezza o danni ai dispositivi causati da una mancata protezione dell'account.</p>

    <h2>6. Forza Maggiore</h2>
    <p>Se eventi al di fuori del controllo delle parti, come disastri naturali, incendi, esplosioni, guerre, sommosse, scioperi o malattie, rendono impossibile adempiere agli obblighi contrattuali, le parti non saranno responsabili. Durante questo periodo, gli obblighi derivanti dal contratto saranno sospesi.</p>

    <h2>7. Integrità del Contratto e Applicabilità</h2>
    <p>Se una delle clausole di questo contratto dovesse risultare parzialmente o completamente invalida, il resto del contratto rimarrà valido.</p>

    <h2>8. Modifiche al Contratto</h2>
    <p>La società si riserva il diritto di modificare in qualsiasi momento i servizi offerti e i termini di questo contratto. Le modifiche saranno effettive dalla data di pubblicazione sul sito. È responsabilità dell'utente monitorare le modifiche. Continuando a utilizzare i servizi, l'utente accetta le modifiche.</p>

    <h2>9. Notifiche</h2>
    <p>Tutte le notifiche relative a questo contratto saranno inviate via email agli indirizzi noti della società e dell'utente. L'utente accetta che l'indirizzo email fornito al momento della registrazione sia l'indirizzo valido per le notifiche e si impegna a informare la società di eventuali modifiche entro 5 giorni.</p>

    <h2>10. Contratto di Prova</h2>
    <p>In caso di controversie relative a questo contratto, i registri contabili, le registrazioni informatiche e i fax saranno considerati come prova legale ai sensi del Codice di Procedura Civile 6100, e l'utente accetta di non contestare tali prove.</p>

    <h2>11. Risoluzione delle Controversie</h2>
    <p>Per qualsiasi controversia derivante dall'applicazione o interpretazione di questo contratto, il tribunale competente sarà quello di Bursa (Centro), Turchia.</p>

    <h2>12. Tariffe</h2>
    <p>All'atto della registrazione, sarà assegnato un pacchetto di prova per testare i servizi. Il pacchetto di prova ha una durata di 5 giorni e consente di generare file in formato .png. Gli utenti possono selezionare il tipo di codice QR, statico o dinamico, al momento della creazione. I pacchetti aziendali offrono uscite vettoriali. La società si riserva il diritto di modificare le durate dei pacchetti di prova e gli utenti possono continuare a utilizzare i codici QR selezionando un pacchetto a pagamento al termine del periodo di prova. I codici QR creati saranno cancellati se non si effettua l'upgrade a un pacchetto a pagamento.</p>

    <h2>13. Eliminazione dell'Account</h2>
    <p>Per eliminare il proprio account, l'utente può farlo tramite il menu del sito, selezionando l'opzione "Elimina account" o inviando una richiesta via email a info@qrcodematic.com. Una volta eliminato l'account, tutti i contenuti e i codici QR creati verranno persi irreversibilmente. Si raccomanda di testare i codici QR su più dispositivi prima di inviarli per la stampa.</p>
    `,
  },
  CROPPER: {
    confirm_selected_area: "Applica l'immagine",
    confirm_selection_tooltip: "Conferma l'area selezionata",
    cropper_success: "Applicato con successo",
  },
  STATISTICS: {
    show_all: "Mostra tutto",
    last_x_days: ({ named }) => `Ultimi ${named("days")} giorni`,
    trial_warning:
      "<strong>DEMO: </strong> Le statistiche sottostanti sono mostrate come esempio su un <strong>account di prova</strong>.",
  },
  HELP: {},
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Invia",
      NO_ACCOUNT: "Non hai un account?",
      SIGNUP_BUTTON: "Registrati",
      FORGOT_BUTTON: "Hai dimenticato la password?",
      BACK_BUTTON: "Indietro",
      PRIVACY: "Privacy",
      LEGAL: "Legale",
      CONTACT: "Contatto",
    },
    LOGIN: {
      TITLE: "Accedi al tuo account",
      BUTTON: "Registrati",
    },
    FORGOT: {
      TITLE: "Hai dimenticato la tua password?",
      DESC: "Inserisci la tua email per reimpostare la password",
      SUCCESS: "Il tuo account è stato reimpostato con successo.",
    },
    REGISTER: {
      TITLE: "Registrati",
      DESC: "Inserisci le tue informazioni per creare un account",
      SUCCESS: "Il tuo account è stato creato con successo.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Nome Completo",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Conferma Password",
      USERNAME: "Nome Utente",
    },
    VALIDATION: {
      INVALID: "{{name}} non valido",
      REQUIRED: "{{name}} è richiesto",
      MIN_LENGTH: "{{name}} lunghezza minima {{min}}",
      AGREEMENT_REQUIRED: "I termini e le condizioni accettate sono obbligatori",
      NOT_FOUND: "{{name}} richiesto non trovato",
      INVALID_LOGIN: "Le credenziali di accesso sono errate",
      REQUIRED_FIELD: "Campo richiesto",
      MIN_LENGTH_FIELD: "Lunghezza minima del campo:",
      MAX_LENGTH_FIELD: "Lunghezza massima del campo:",
      INVALID_FIELD: "Campo non valido",
    },
  },
  ICONSELECT: {
    animals: "Animali",
    landscape: "Paesaggio",
    people: "Persone",
    vehicles: "Veicoli",
    communication: "Comunicazione",
    technology: "Tecnologia",
    business: "Catalogo / Menu",
    food: "Cibo",
  },
};
