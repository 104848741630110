// TURQUIE
export const locale = {
  greet: ({ named }) => `Bonjour <span class="font-weight-bold">${named("userName")}!</span>`,
  spanish: "Espagnol",
  german: "Allemand",
  turkish: "Turc",
  english: "Anglais",
  italian: "Italien",
  true: "Oui",
  false: "Non",
  date: "Date",
  time: "Heure",
  dynamic: "Dynamique",
  static: "Statique",
  hi: "Bonjour",
  create: "Créer",
  update: "Mettre à jour",
  change: "Modifier",
  template: "Modèle",
  qr_list: "Liste QR",
  QR_TITLE: "Titre du QR Code",
  qr_code_list: "Liste de codes QR",
  qrcode_document: "Document",
  qrcode_document_subtext: "Partagez vos documents",
  qrcode_website: "Site Web (Lien)",
  qrcode_website_subtext: "Redirigez vos liens",
  qrcode_vcard: "VCard Plus",
  qrcode_vcard_subtext: "Créez une v-card avec des informations de contact",
  qrcode_smsdynamic: "SMS",
  qrcode_smsdynamic_subtext: "Créer un expéditeur SMS",
  qrcode_sms: "SMS",
  qrcode_sms_subtext: "Créer un message SMS",
  qrcode_business: "Carte d'entreprise",
  qrcode_business_subtext: "Créez votre page d'entreprise",
  qrcode_socialmedia: "Réseaux sociaux",
  qrcode_socialmedia_subtext: "Redirigez vers vos comptes de réseaux sociaux",
  qrcode_pdf: "PDF",
  qrcode_pdf_subtext: "Créer un visualiseur PDF",
  qrcode_restaurant: "Menu restaurant",
  qrcode_restaurant_subtext: "Créez un menu personnalisé avancé pour votre restaurant !",
  qrcode_pdfmenu: "Catalogue PDF / Menu",
  qrcode_pdfmenu_subtext: "Créez un catalogue ou menu en téléchargeant plusieurs PDF",
  qrcode_catalog: "Catalogue en ligne / Menu",
  qrcode_catalog_subtext: "Créez un catalogue à partir du PDF sélectionné",
  qrcode_equipment: "Carte d'équipement",
  qrcode_equipment_subtext: "Suivez l'état de vos équipements",
  qrcode_staffcard: "Carte de personnel",
  qrcode_staffcard_subtext: "Carte de personnel pour vos employés",
  qrcode_emergency: "Urgence",
  qrcode_emergency_subtext: "Carte d'information pour les urgences",
  qrcode_event: "Événement",
  qrcode_event_subtext: "Application d'invitation pour concerts, salons, etc.",
  qrcode_location: "Localisation",
  qrcode_location_subtext: "Créez des indications pour les adresses de votre organisation",
  qrcode_music: "Musique",
  qrcode_music_subtext: "Téléchargez et jouez une chanson ou un enregistrement audio",
  qrcode_pet: "Animal domestique",
  qrcode_pet_subtext: "Créez une carte de suivi pour votre animal domestique",
  qrcode_museum: "Musée",
  qrcode_museum_subtext: "Créez une carte d'information pour des artefacts ou lieux historiques",
  qrcode_coupon: "Coupon",
  qrcode_coupon_subtext: "Application de distribution de coupons pour vos promotions",
  qrcode_rate: "Évaluation",
  qrcode_rate_subtext: "Application permettant aux utilisateurs de noter et commenter vos produits",
  qrcode_store: "Applications",
  qrcode_store_subtext: "Créez des liens vers Apple Store, Google Play pour vos applications",
  qrcode_call: "Appel",
  qrcode_call_subtext: "Créez une carte d'appel téléphonique",
  qrcode_text: "Texte",
  qrcode_text_subtext: "Carte partageant un texte pouvant être copié",
  qrcode_mail: "E-mail",
  qrcode_mail_subtext: "Créez une carte de contact par e-mail",
  qrcode_whatsapp: "WhatsApp",
  qrcode_whatsapp_subtext: "Créez un lien de contact WhatsApp",
  qrcode_instagram: "Instagram",
  qrcode_instagram_subtext: "Créez un lien de contact Instagram",
  qrcode_facebook: "Facebook",
  qrcode_facebook_subtext: "Créez un lien de contact Facebook",
  qrcode_twitter: "X / Twitter",
  qrcode_twitter_subtext: "Créez un lien de contact X / Twitter",
  qrcode_linkedin: "LinkedIn",
  qrcode_linkedin_subtext: "Créez un lien de contact LinkedIn",
  qrcode_youtube: "YouTube",
  qrcode_youtube_subtext: "Partagez une vidéo YouTube",
  qrcode_pinterest: "Pinterest",
  qrcode_pinterest_subtext: "Créez un lien de contact Pinterest",
  qrcode_paypal: "PayPal",
  qrcode_paypal_subtext: "Créez un lien de paiement ou de don PayPal",
  qrcode_wifidynamic: "Wi-Fi",
  qrcode_wifidynamic_subtext: "Partagez les informations de connexion Wi-Fi",
  qrcode_websitestatic: "Site web (statique)",
  qrcode_websitestatic_subtext: "QR code statique contenant l'URL de votre site",
  qrcode_vcardstatic: "VCard (statique)",
  qrcode_vcardstatic_subtext: "QR code pouvant être enregistré en .vcf sur un téléphone",
  qrcode_phone: "Téléphone",
  qrcode_phonestatic: "Téléphone (statique)",
  qrcode_phone_subtext: "Téléphone",
  qrcode_textstatic: "Texte (statique)",
  qrcode_textstatic_subtext: "Texte (statique)",
  qrcode_smsstatic: "SMS (statique)",
  qrcode_smsstatic_subtext: "SMS (statique)",
  qrcode_wifistatic: "Wi-Fi (statique)",
  qrcode_wifistatic_subtext: "Wi-Fi (statique)",
  qrcode_multiplelink: "Liens multiples",
  qrcode_multiplelink_subtext: "Ajoutez plusieurs liens avec une redirection aléatoire",
  qrcode_gallery: "Galerie d'images",
  qrcode_gallery_subtext: "Galerie d'images",
  qrcode_customerservice: "Contact du département",
  qrcode_customerservice_subtext: "Créez des liens de contact pour les départements",
  qrcode_title_wifi: "Wi-Fi",
  qrcode_title_vcard: "VCard Plus",
  qrcode_title_sms: "SMS",
  qrcode_title_gallery: "Galerie",
  qrcode_title_multiplelink: "Liens multiples",
  qrcode_title_music: "Musique",
  qrcode_title_website: "Site web",
  qrcode_title_whatsapp: "WhatsApp",
  qrcode_title_email: "E-mail",
  qrcode_title_pdfmenu: "Catalogue PDF / Menu",
  qrcode_title_catalog: "Catalogue en ligne / Menu",
  qrcode_title_text: "Texte",
  qrcode_title_coupon: "Coupon",
  qrcode_title_pinterest: "Pinterest",
  qrcode_title_instagram: "Instagram",
  qrcode_title_twitter: "Twitter",
  qrcode_title_rate: "Évaluation",
  qrcode_title_business: "Carte d'entreprise",
  qrcode_title_phone: "Téléphone",
  qrcode_title_staffcard: "Carte de personnel",
  qrcode_title_paypal: "PayPal",
  qrcode_title_store: "Applications",
  qrcode_title_call: "Appel",
  qrcode_title_location: "Localisation",
  qrcode_title_pdf: "PDF",
  qrcode_title_museum: "Musée",
  qrcode_title_linkedin: "LinkedIn",
  qrcode_title_youtube: "YouTube",
  qrcode_title_pet: "Animal domestique",
  qrcode_title_facebook: "Facebook",
  qrcode_title_event: "Événement",
  qrcode_title_equipment: "Carte d'équipement",
  qrcode_title_emergency: "Carte d'urgence",
  qrcode_title_restaurant: "Restaurant",
  qrcode_title_socialmedia: "Réseaux sociaux",
  qrcode_all: "- Tout afficher -",
  page_not_found_text: "Oups ! Le contenu que vous recherchez est introuvable",
  go_home: "Accueil",
  link_is_copied_to_clipboard: "Lien copié !",
  select_pdf_by_clicking: "Cliquez sur le fichier PDF pour l'activer",
  select_content_by_clicking: "Cliquez sur le fichier pour l'activer",
  clear_all_fields: "Vider le formulaire",
  search_type: "Sélectionner le type de carte",
  create_quickly: "Créer rapidement",
  empty: "Vide",
  click_lock_to_unlock: "Cliquez sur l'icône de cadenas pour modifier ce champ",
  user_folder: "Dossier utilisateur",
  this_field_is_required: "Ce champ est obligatoire",
  you_dont_have_any_saved_design: "Enregistrez votre design pour l'utiliser dans les futurs QR codes",
  you_can_save_your_current_design_by_clicking_button_below:
    "Vous pourrez appliquer ce design (couleurs, formes) aux futurs QR codes en un clic.",
  you_have_to_update_save_this_qrcode_to_be_able_to_use_this_feature:
    "Vous devez enregistrer ce QR code pour utiliser cette fonctionnalité.",
  SAVE_CURRENT_DESIGN: " + ENREGISTRER LE DESIGN ACTUEL",
  you_have_to_update_current_changes_first: "Vous devez d'abord sauvegarder les modifications actuelles",
  scan_result_good: "Lecture bonne",
  scan_result_bad: "Lecture faible",
  LANGUAGE: "Choisir la langue",
  YES: "OUI",
  ACTIVE_LANGUAGE: "Langue active",
  ADD_SOME_INFO: "Entrez les informations",
  CONTACT_INFO: "Informations de contact",
  FULL_NAME: "Nom complet",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom de famille",
  COMPANY_NAME: "Entreprise",
  COMPANY_ADDRESS: "Adresse de l'entreprise",
  JOB_TITLE: "Poste",
  JOBTITLE: "Poste",
  DEPARTMENT: "Département",
  BLOOD_GROUP: "Groupe sanguin",
  FOLDER_NO: "Numéro de dossier",
  DATE_OF_START: "Date de début",
  COMPANY: "Entreprise",
  COUNTRY: "Pays",
  STATE: "État",
  MAIL: "E-mail",
  WEBSITE: "Lien",
  PHONE: "Téléphone",
  SMS: "SMS",
  HOME: "Accueil",
  WHO_ARE_WE: "Qui sommes-nous",
  icon: "Icône",
  show_more: "Voir plus",
  remove_this_song: "Supprimer cette chanson",
  select_cover_image: "Sélectionner une image de couverture",
  select_audio_file: "Sélectionner un fichier audio",
  delete_all: "Tout supprimer",
  spotify_settings: "Paramètres Spotify",
  light_theme: "Thème clair",
  dark_theme: "Thème sombre",
  you_can_paste_single_song_or_playlist:
    "Vous pouvez coller une seule chanson ou une playlist. Le lien correct doit ressembler à ceci :",
  add_songs_from_local_files: "Ajouter des chansons à partir de fichiers locaux",
  add_songs_from_spotify: "Ajouter des chansons à partir de Spotify",
  SETTINGS: "Paramètres",
  APPLY: "APPLIQUER",
  crops_image: "Transférer l'image vers la carte sélectionnée",
  rotate_right: "Tourner à droite",
  rotate_left: "Tourner à gauche",
  upload_image: "Télécharger une image depuis votre ordinateur",
  reset: "Effacer le formulaire",
  upload: "Télécharger depuis l'ordinateur",
  transfer: "Afficher l'image",
  adress_icon: "Icône d'adresse",
  area_icon: "Icône de zone",
  card_owner: "Propriétaire de la carte",
  expires_at: "Date",
  mounth: "MOIS",
  years: "AA",
  full_name: "Nom complet",
  type_city_name: "Entrez le nom de la ville et de la région",
  your_zip_code: "Code postal",
  id_number: "Numéro d'identification",
  tax_number: "Numéro de taxe",
  tax_office: "Bureau des impôts",
  qr_code_type: "Vous pouvez changer le type de code QR. (Important !)",
  WHO_ARE_WE_1: `Notre entreprise de technologies logicielles, entièrement équipée, a été fondée en 1997 avec la conviction qu'il y a beaucoup de travail à faire et beaucoup de code à écrire. Notre fondateur a imaginé une entreprise de logiciels qui pourrait offrir des services à ses clients, fournir des valeurs réelles et des résultats mesurables. Aujourd'hui, nous continuons à vous servir en réalisant des projets bien au-delà de nos rêves.`,
  WHO_ARE_WE_2: `Nous avons parcouru un long chemin jusqu'à présent. Au fil des ans, nous avons ouvert nos portes à des génies du logiciel talentueux et nous nous sommes renforcés ensemble. Nous continuons ce voyage en combinant les connaissances que nous avons accumulées avec la technologie d'aujourd'hui, en faisant ce que nous aimons et en profitant des avantages que cela nous apporte.`,
  WHO_ARE_WE_3: `Avec notre connaissance et notre expérience dans le secteur, nous adoptons une approche holistique des processus et renforçons votre puissance dans le numérique avec des technologies logicielles durables. Nous nous engageons dans tous les processus des entreprises avec lesquelles nous travaillons dans le processus de création de marque, de la production à la vente, de la finance au marketing, de la R&D aux achats, avec nos services de gestion de marque, de conception et de développement web, de stratégies de marketing numérique, de conceptions de médias sociaux, de SEM, de SEO, de systèmes de commerce électronique et de projets de logiciels personnalisés.`,
  WHO_ARE_WE_4: `Au-delà des services que nous offrons, l'attention et le soin que nous portons à chaque projet, quelle que soit sa taille ou sa valeur, sont les mêmes. Notre réputation est construite sur des clients satisfaits dont nous avons vu les entreprises grandir grâce aux projets que nous avons achevés.`,
  WHO_ARE_WE_5: `Nous savons que la première étape de tout nouveau projet est d'écouter. Nous comprenons parfaitement ce dont vous avez besoin, les problèmes auxquels vous êtes confronté et vos plans pour l'avenir. C'est ainsi que nous planifions des stratégies complètes qui fonctionnent.`,
  WHO_ARE_WE_6: `Avec notre expérience de nombreuses années, nous savons que nous pouvons vous aider, quelle que soit l'ampleur de votre projet ou la taille de votre entreprise. Nos principes directeurs sont l'amélioration continue, l'empathie, l'attention aux détails, l'honnêteté et l'inspiration. C'est pourquoi nos clients continuent de travailler avec nous depuis notre création.`,
  WHO_ARE_WE_7: `Mais assez parlé de nous ! Maintenant, nous voulons entendre vos rêves, vos objectifs, vos projets et ce que vous voulez faire. Si vous souhaitez discuter en ligne de votre entreprise avec nous, toute l'équipe vous attend et déborde de bonnes idées. Nous aimons peut-être la communication numérique, mais nous n'aimons pas moins les conversations téléphoniques. Vous pouvez envoyer un e-mail à info@medyax.com.`,
  PHONEHOME: "Téléphone fixe",
  PHONEMOBILE: "Téléphone mobile",
  PHONEOFFICE: "Téléphone de bureau",
  PHONEHOMECOUNTRYCODE: "Indicatif pays téléphone fixe",
  PHONEMOBILECOUNTRYCODE: "Indicatif pays téléphone mobile",
  PHONEOFFICECOUNTRYCODE: "Indicatif pays téléphone bureau",
  ABOUT: "À propos",
  ABOUTUS: "À propos",
  TEAM: "Équipe",
  CONTACT: "Contact",
  PREVIEW: "Aperçu du code QR",
  SUPPORT: "Aide",
  ADDRESS: "Adresse",
  ENTER_LOCATION: "+ Veuillez saisir votre adresse (vous pouvez ajouter plusieurs adresses)",
  ENTER_LOCATION_HELP:
    "Veuillez saisir votre adresse ci-dessous. Ensuite, sélectionnez votre adresse dans la fenêtre qui s'ouvre. Ensuite, déplacez l'icône rouge pour marquer votre emplacement plus en détail.",
  PLEASE_CHOOSE_LOCATION: "Veuillez d'abord choisir un emplacement",
  MANAGER_FULL_NAME: "Nom du directeur",
  MANAGER_PHONE: "Téléphone du directeur",
  MANAGER_JOBTITLE: "Poste du directeur",
  MANAGER_MAIL: "E-mail du directeur",
  ART_NAME: "Nom de l'œuvre",
  ART_ACCESSION_NUMBER: "Numéro d'inventaire",
  ART_ERA: "Époque",
  ART_MEASUREMENT: "Dimensions",
  ART_DISCOVERY_LOCATION: "Lieu de découverte",
  ART_ACCESSION_DATE: "Date de découverte",
  ART_DESCRIPTION: "Description de l'œuvre",
  RESTAURANT_ABOUT_US: "",
  RESTAURANT_ADDRESS: "",
  RESTAURANT_PHONE: "",
  RESTAURANT_EMAIL: "",
  NAME: "Nom et prénom",
  BLOODGROUP: "Groupe sanguin",
  MEDICATION: "Médicaments utilisés",
  PLATENUMBER: "Numéro de plaque",
  ALLERGICTO: "Allergie",
  DESCRIPTION: "Description",
  MEDICALRECORD: "Dossier médical",
  NUMBER: "Numéro",
  SERIALNUMBER: "Numéro de série",
  NEXTMAINTENANCE: "Date du prochain entretien",
  LASTMAINTENANCE: "Date du dernier entretien",
  REPAIRCOMPANY: "Entreprise de maintenance/calibration",
  LOCATION: "Emplacement",
  SHOWREMAINING: "Afficher les jours restants",
  FREQUENCY: "Période de maintenance/calibration",
  STARTHOUR: "Heure de début",
  ENDHOUR: "Heure de fin",
  ENDDATE: "Date de fin",
  STARTDATE: "Date de début",
  TITLE: "Titre",
  TITLE1: "Premier titre",
  TITLE2: "Deuxième titre",
  SUBTITLE: "Sous-titre",
  EMAIL: "E-mail",
  YOUTUBE: "Lien YouTube",
  GMAP: "Google Maps",
  URL: "Lien",
  TEXT2: "Deuxième texte",
  TEXT1: "Texte",
  TEXT: "Texte",
  BUTTONTEXT: "Texte du bouton",
  NOPERIOD: "Aucune période",
  LAST_30_DAYS: "30 derniers jours",
  LAST_90_DAYS: "90 derniers jours",
  LAST_180_DAYS: "180 derniers jours",
  SELECTED_TIME: "Toutes les périodes",
  OWNER: "Propriétaire",
  VETADDRESS: "Adresse du vétérinaire",
  VETPHONE: "Téléphone du vétérinaire",
  VETEMAIL: "E-mail du vétérinaire",
  VETNAME: "Nom du vétérinaire",
  GOOGLELINK: "Lien Google",
  VIDEOLIST: "Liste YouTube multiple",
  IOSLINK: "Lien App Store",
  HUAWEILINK: "Lien Huawei Gallery",
  COUPONDISCOUNT: "Code de réduction",
  COUPONTITLE: "Titre du coupon",
  COUPONCODE: "Code du coupon",
  PRODUCT: "Produit",
  WIFINAME: "Nom du WiFi",
  CONTENTLIST: "Liste de contenu",
  CONTENTIMAGES: "Images du contenu",
  PASSWORD: "Mot de passe",
  USER: "Utilisateurs",
  BTNTEXT: "Texte du bouton",
  FILE: "Fichier",
  "AD SOYAD": "Nom et prénom",
  BÖLÜMÜ: "Département",
  GÖREVI: "Fonction",
  TELEFONU: "Téléphone",
  "KAN GRUBU": "Groupe sanguin",
  "İŞE GIRIŞ TARIHI": "Date d'entrée en service",
  "SICIL NO": "Numéro de dossier",
  RESPONSIBLESTAFFPHONE: "Personnel responsable",
  CITY: "Ville et district",
  FAX: "Fax",
  ZIP: "Code postal",
  ZIPCODE: "Code postal",
  FAXCOUNTRYCODE: "Indicatif pays du fax",
  MESSAGE: "Message",
  SSID: "SSID",
  ISHIDDEN: "Caché",
  NETWORKTYPE: "Type de réseau",
  PW: "Mot de passe",
  SECURITY: "Confidentialité",
  TOPICON: "Icône du haut",
  MIDDLEICON: "Icône du milieu",
  GOOGLE: "Lien Google Play",
  APPLE: "Lien App Store",
  HUAWEI: "Lien Huawei Gallery",
  COUNTRYCODE: "Code pays",
  ISWIFIHIDDEN: "Visibilité WiFi",
  NEW: "Nouveau!",
  NO_TITLE: "Pas de titre",
  ACTIVE: "Actif",
  active: "Actif",
  PASSIVE: "Passif",
  passive: "Passif",
  status: "Statut",
  UNIQUE_SCAN: "Scan unique",
  DOWNLOAD: "Téléchargements",
  SCAN: "Total des scans",
  PRINT: "Impression",
  VIEW: "Affichage",
  ISTATISTICS: "Vous pouvez obtenir des informations statistiques détaillées en activant les statistiques.",
  DELETE: "Supprimer",
  RENAME: "Renommer",
  SEE_PACKAGE: "Détail du paquet",
  CANCEL: "Annuler",
  LIVE: "En ligne",
  OK: "D'accord",
  ARE_YOU_SURE: "Es-tu sûr?",
  ERROR: "Erreur",
  ATTENTION: "Attention!",
  SUCCESS: "Opération réussie!",
  DOWNLOAD_ERROR: "Erreur lors du téléchargement",
  CLOSE: "Fermer",
  SORT_BY: "Trier par",
  MOST_RECENT: "Le plus récent",
  LEAST_RECENT: "Le moins récent",
  RECENTLY_UPDATE: "Dernière mise à jour",
  LEAST_SCANNED: "Le moins scanné",
  MOST_SCANNED: "Le plus scanné",
  SEARCH: "Recherche",
  UPLOAD: "Téléchargement de fichier",
  BACK: "Retour",
  NEW_FOLDER: "Nouveau dossier",
  IMAGE_QUALITY: "Qualité de lecture du QR Code",
  LOW: "Faible",
  MEDIUM: "Moyen",
  GOOD: "Bon",
  HIGH: "Élevé",
  BACK_TO_DEFAULTS: "Revenir au QR Code classique",
  SAVE_QR_CODE: "Enregistrer le QR Code",
  SAVE: "Enregistrer",
  RESET: "Réinitialiser",
  SHORT: "Court",
  URL: "Lien",
  SEND: "Envoyer",
  SCAN_QR: "Tester la lecture",
  SAVED_DESIGNS: "Enregistrer en tant que modèle",
  LOGOS: "Logos",
  SHOW_ALL: "Tout afficher",
  ANIMATION: "Animé",
  STATIC: "Statique",
  EYE_BALL: "Référence interne",
  BODY: "Formes du corps",
  EYE_FRAME: "Référence externe",
  FRAME: "Cadre",
  TEMPLATES: "Conceptions de QR Code",
  ADVENCED_LOGO_SETTING: "Paramètres avancés du logo",
  GO_BACK: "Revenir",
  SELECT: "Sélectionner",
  SINGLE_COLOR: "Couleur unique",
  CUSTOMIZE_INDIVIDUALLY: "Personnaliser",
  CHANGE_REFERANCE_POINT: "Changer les points de référence",
  NEED_HELP: "Besoin d'aide?",
  FRAME_SETTINGS: "Paramètres du cadre",
  DEFAULT_LANGUAGE: "Langue par défaut",
  DELETE_ALL: "Tout supprimer",
  ACTIVATE_ALL: "Tout activer",
  DE_ACTIVATE_ALL: "Tout désactiver",
  OPTION: "Options",
  DISAGREE: "Rester sur la page",
  AGREE: "Accepter",
  FIRSTSECTIONTITLE: "Titre de la section",
  field_name: "Nom du champ",
  field_value: "Valeur du champ",
  field_type: "Type de champ",
  DYNAMIC_FIELDS: "Champs dynamiques",
  DYNAMIC_FIELDS_DESC:
    "Votre champ dynamique semble vide. Veuillez cliquer sur le bouton ci-dessous pour ajouter un nouveau champ.",
  you_have_unsaved_changes: "Vous avez des modifications non enregistrées",
  you_have_unsaved_changes_desc: "Voulez-vous toujours quitter la page?",
  approve_comment: "Approuver le commentaire",
  disapprove_comment: "Rejeter le commentaire",
  warning: "Avertissement!",
  add_new_languages: "Ajouter une nouvelle langue",
  get_more_information_about: "Obtenez plus d'informations sur les paramètres de langue",
  copy_content_from_description: "Le contenu de la langue sélectionnée sera appliqué aux nouvelles langues ajoutées.",
  copy_content_from: "Copier le contenu de",
  you_are_about_to_delete_the_category: "Vous êtes sur le point de supprimer la catégorie",
  are_you_sure_you_want_to_continue:
    "Si vous continuez, vous perdrez également les données de cette catégorie avec le PDF sélectionné. Êtes-vous sûr de vouloir continuer?",
  you_at_least_need_to_have_1_category: "Vous devez avoir au moins une catégorie",
  dark_mode: "Mode sombre",
  pdf_is_processing: "Traitement du PDF en cours..",
  image: "Image",
  browse_my_media: "Parcourir mes médias",
  my_media: "Mes médias",
  used: " Utilisé",
  my_media_subtext: "Gérer vos médias",
  my_domains: "Mes domaines",
  my_domains_subtext: "Gérer vos domaines",
  default_domain: "DOMAINE PAR DÉFAUT",
  not_verify_domain: "DOMAINE NON VÉRIFIÉ",
  verify_domain: "VÉRIFIER LE DOMAINE",
  make_deafult_domain: "DÉFINIR COMME PAR DÉFAUT",
  default_status: "Statut par défaut",
  domain_add: "AJOUTER UN DOMAINE",
  animation: "Animation",
  background_color: "Couleur de fond",
  choose_design: "Choisir un design",
  select_from_my_media: "Sélectionner une image/logo de mes médias",
  select_from_animation: "Sélectionner une animation",
  age_restriction: "Restriction d'âge",
  age_restriction_text: "Restriction d'âge",
  age_restriction_tooltip: "Avertissement pour les utilisateurs en dessous d'un certain âge",
  analytics: "Analyses",
  expiration_date: "Date d'expiration",
  device: "Appareil",
  personal_info: "Informations personnelles",
  REMINDER: "Rappel",
  WEBURL: "Site web",
  NONE: "Aucun",
  MINUTES: "minutes avant",
  HOURS: "heures avant",
  DAYS: "jours avant",
  WEEKS: "semaines avant",
  COMPLETE: "Compléter",
  type_your_first_name: "Saisissez votre prénom",
  type_your_last_name: "Saisissez votre nom",
  type_your_address: "Saisissez votre adresse",
  your_company_info: "Informations de votre entreprise",
  order_summary: "Résumé de la commande",
  order_desc: "Résumé des informations du forfait sélectionné.",
  annual: "Annuel",
  subtotal: "Sous-total",
  tax: "Taxe",
  coupon_discount: "Remise du coupon",
  total: "Montant total",
  package_price: "Prix du forfait",
  package_discount: "Remise sur le forfait",
  type_your_discount_code: "Entrez votre code de réduction",
  coupon_code: "Code de réduction",
  use_coupon_code: "Utiliser le code",
  cancel_coupon: "Annuler le code",
  i_agree_to: "J'ai lu et j'accepte les conditions",
  go_back: "RETOURNER",
  month: "Mois",
  year: "Année",
  day: "Jour",
  creditcard_number: "Numéro de carte",
  creditcard_name: "Nom sur la carte",
  creditcard_expiration_date: "Date d'expiration",
  creditcard_cvv: "CVC",
  terms_of_use: "Conditions d'utilisation",
  current_plan: "Plan actuel",
  active_package: "Forfait actif",
  started_at: "Commencé le",
  ends_at: "Se termine le",
  remaining_time: "Temps restant",
  package_content: "Contenu du forfait",
  newspaper: "Journal",
  campaign_start: "Début",
  campaign: "Informations sur l'impression",
  campaign_end: "Fin",
  start_date: "Date de début",
  end_date: "Date de fin",
  are_you_sure: "Êtes-vous sûr?",
  campaign_info: "Informations sur la campagne",
  print_style: "Type d'impression",
  print_amount: "Nombre d'impressions",
  advanced_statistics: "Statistiques avancées",
  advanced_statistics_desc: "Les statistiques avancées ne sont pas activées. Voulez-vous les activer?",
  change_range: "Filtrer",
  select_start_and_end_dates: "Sélectionnez les dates de début et de fin",
  language_settings: "Paramètres linguistiques",
  pdf_info:
    "Lorsque cette option est activée, les utilisateurs ne verront aucune page et seront redirigés vers un fichier PDF.",
  pdf_download: "Vous devez télécharger un PDF pour activer cette option.",
  general_settings_link: "Cliquez ici pour plus d'informations.",
  email_address_to_be_sent: "Adresse e-mail pour l'envoi du formulaire de contact",
  general_settings_desc:
    "Le titre du QR code est uniquement affiché pour vous différencier des autres QR codes que vous possédez. Les utilisateurs ne le verront pas et le modifier ne changera pas votre page.",
  submit: "Envoyer",
  invalid_email: "E-mail invalide",
  new_qr_code: "Créer un nouveau QR Code",
  days_remaining: "RESTANT :",
  days: "JOURS",
  the_last_day_of_your_package: "Dernier jour de votre forfait",
  full_name: "Nom et prénom",
  phone: "Téléphone",
  email: "E-mail",
  text: "Texte",
  link: "Lien",
  desc_message: "Veuillez écrire une description...",
  how_many_qrcodes_you_need: "Combien de QR codes avez-vous besoin?",
  request_custom_package: "Demande de forfait personnalisé",
  communication: "Communication",
  account_privacy: "Suppression du compte",
  deactivate_your_account: "Désactiver votre compte",
  default_language: "Ce sera votre langue par défaut",
  save_changes: "Enregistrer",
  cancel: "Annuler",
  avatar: "Photo de profil",
  update_your_personal_information: "Mettez à jour vos informations personnelles",
  allowed_file_types: "Types de fichiers acceptés",
  change_password: "Changer le mot de passe",
  account_holder: "Titulaire du compte",
  account_information: "Informations du compte",
  FIRSTBTNTEXT: "Premier bouton",
  SECONDBTNTEXT: "Deuxième bouton",
  request_price: "Demander un devis personnalisé",
  make_request: "DEMANDER UN DEVIS",
  gender: "Genre",
  male: "Homme",
  female: "Femme",
  cell_phone: "Téléphone portable",
  invalid_phone: "Numéro de téléphone invalide",
  invalid_mail: "Adresse e-mail invalide",
  invalid_website: "Adresse invalide",
  invalid_name: "Nom invalide",
  dinamik_qr_kod_fiyatları: "Tarifs QR Code dynamiques",
  restaurant_menü_qr_kod_fiyatları: "Tarifs QR Code pour menus de restaurant",
  note: "Note",
  user_profile: "Profil utilisateur",
  sign_out: "Se déconnecter",
  signing_out: "Déconnexion en cours",
  account_settings_and_more: "Paramètres du compte et plus",
  my_profile: "Mon profil",
  active_subscriptions: "Mes abonnements actifs",
  active_subscriptions_subtext: "Abonnements actifs en cours d'utilisation",
  SELECT_FROM_THE_LIST: "Sélectionnez un type de carte dans la liste",
  package_name: "Nom du forfait",
  device_list: "Liste des appareils",
  details: "Détails",
  detailed_chart: "Graphique détaillé",
  browser_list: "Liste des navigateurs",
  city_list: "Liste des villes",
  country_list: "Liste des pays",
  os_list: "Systèmes d'exploitation",
  age_profile: "Répartition par âge",
  ip_list: "Liste des IP",
  gender_profile: "Répartition par genre",
  device_resolution_profile: "Résolution des appareils",
  last_day: "Quotidien",
  last_week: "Hebdomadaire",
  last_month: "Mensuel",
  last_year: "Annuel",
  page_view: "Vues de page",
  unique_visitor: "Visiteur unique",
  total_view: "Total des vues",
  total_visit: "Total des visites",
  delete_staticstics: "Êtes-vous sûr de vouloir supprimer les données statistiques?",
  avarage_duration_visitors_stay: "Durée moyenne de visite",
  total_number: "Statistiques totales",
  sorry_im_leaving: "Désolé, je pars!",
  sorry_im_leaving_desc:
    "Vous êtes sur le point de supprimer votre compte. Si vous continuez, vos données seront définitivement supprimées et ne pourront pas être récupérées. Voulez-vous toujours continuer?",
  last_30_days: "Les 30 derniers jours",
  loading: "Chargement",
  klasor_name: "Nom du dossier",
  delete_comment: "Supprimer le commentaire",
  are_you_sure_you_want_to_delete_this_comment: "Êtes-vous sûr de vouloir supprimer ce commentaire?",
  if_you_delete_this_comment: "Si vous supprimez ce commentaire, vous ne pourrez pas le récupérer.",
  CREATE: "Créer",
  Upgrade: "Mettre à niveau",
  no_content: "Créez un 'NOUVEAU DOSSIER' ou téléchargez un 'FICHIER'.",
  size: "Taille",
  scan: "Analyse",
  limit: "Limite",
  subject: "Sujet",
  message: "Message",
  qrcode: "Code QR",
  allow_this_to_be_shared: "J'autorise le partage avec d'autres utilisateurs",
  package_limit: "Limite du forfait",
  rows_per_page: "Lignes par page",
  your_subscription_has_expired: "Votre abonnement a expiré",
  two_ways_to_keep_using: "Deux moyens de continuer",
  contact_us: "Contactez-nous",
  upgrade_now: "Mettre à niveau maintenant",
  or: "OU",
  no_data: "Aucune donnée disponible",
  list_is_empty: "La liste est vide",
  recaptcha_error: "Veuillez prouver que vous n'êtes pas un robot",
  if_you_delete_this_qr_code: "Si vous supprimez ce QR code, vous perdrez également ses données.",
  delete_all_childs_alert_text: ({ named }) =>
    `Cette action est irréversible et supprimera ${named(
      "amount"
    )} QR codes sous-jacents. Veuillez confirmer avant de continuer.`,
  qr_codes_that_are_not_recovered: "Les QR codes non récupérés seront complètement supprimés après 20 jours.",
  changing_URL_will_create_a_new_qr_code:
    "Modifier l'URL créera un nouveau QR code et la version imprimée ne sera plus accessible.",
  attention: "Important! Attention!",
  valid_in_dynamic: "Valide en mode dynamique",
  you_can_not_add_more_than_connections: ({ named }) =>
    `Vous ne pouvez pas ajouter plus de ${named("amount")} connexions.`,
  If_you_delete_this_language_you_will_lose_all_the:
    "Si vous supprimez cette langue, toutes les données associées seront perdues. Êtes-vous sûr de vouloir continuer?",
  loading_items: "Chargement des données",
  your_profile_data_has_been_successfuly_updated: "Vos informations de profil ont été mises à jour avec succès!",
  your_package_will_be_processed_after_your_payment_has_been_received:
    "Votre forfait sera traité après réception de votre paiement.",
  verify_password_does_not_match: "Le mot de passe ne correspond pas, veuillez vérifier.",
  please_fill_all_fields: "Veuillez remplir tous les champs.",
  text_must_be_less_than_2500_characters: "Le texte doit contenir moins de 2500 caractères.",
  title_must_be_less_than_80_characters: "Le titre doit contenir moins de 80 caractères.",
  must_be_less_than_200_characters: "Doit contenir moins de 200 caractères.",
  must_be_less_than_100_characters: "Doit contenir moins de 100 caractères.",
  you_need_to_chose_a_logo_or_toggle_off_in_media_settings:
    "Vous devez choisir un logo ou désactiver l'affichage des médias dans les paramètres.",
  is_required: "est requis",
  btnText: "Texte du bouton",
  title_is_required: "Le titre est requis",
  this_is_required: "Ce champ est requis",
  phone_is_required: "Le numéro de téléphone est requis",
  this_url_is_invalid: "Cette URL est invalide",
  please_input_a_valid_phone_number: "Veuillez saisir un numéro de téléphone valide",
  you_need_to_chose_start_or_end_date: "Vous devez choisir une date de début ou de fin",
  you_need_to_fill_the_required_fields: "Vous devez remplir les champs obligatoires",
  selected_file: "Fichier PDF sélectionné",
  no_file: "Aucun fichier",
  no_link: "Aucun lien associé",
  add_more_button_text: "Ajouter un nouveau champ",
  go_to_main_page: "Retourner à l'accueil",
  new_address: "Nouvelle adresse",
  information: "Informations",
  description_for_the_address: "Description de l'adresse",
  you_can_not_add_more_than_10_emergency_number: "Vous ne pouvez pas ajouter plus de 10 numéros d'urgence.",
  invalid_url: "URL invalide",
  continue: "CRÉER MAINTENANT",
  direct_download:
    "Téléchargement direct (le code QR téléchargera automatiquement le fichier sur le téléphone de l'utilisateur)",
  REMAININGCOUNTERVISIBLEFORLASTAMOUNTDAYS: "Le compteur sera visible à partir de __ jours",
  select_pdf_for_each_language:
    "Cliquez sur 'Sélectionner un PDF'. Créez un nouveau dossier et chargez votre fichier. Ensuite, cliquez sur le fichier pour l'attribuer. Vous devez ajouter un fichier PDF pour chaque langue. Les fichiers PDF manquent pour les langues suivantes:",
  missing_locations_for_languages:
    "Veuillez ne pas copier-coller votre ADRESSE! Pour sélectionner correctement une adresse, saisissez-la manuellement et sélectionnez-la dans le menu déroulant.",
  drag_drop: "Glisser-déposer pour réorganiser",
  change_order: "Modifier l'ordre",
  caption: "Légende",
  image: "Photo",
  add_new: "Ajouter nouveau",
  delete_selected: "Supprimer la sélection",
  your_own: "Votre propre",
  logo: "Logo",
  add: "Ajouter",
  qrcode_static_subtext: "Sélectionnez pour créer votre code QR statique.",
  type_something_here: "Écrivez une description ici",
  start_program: "Début du programme",
  end_program: "Fin du programme",
  billed_annually: "Facturé annuellement",
  no_folder: "Aucun dossier",
  I_WANT_TO_GET_INFORMATION_ABOUT_THE_PRODUCTS: "JE VEUX DES INFORMATIONS SUR LES PRODUITS",
  keep_track:
    "Suivez combien de fois ce QR code a été scanné et utilisez les <strong>Statistiques avancées</strong></br></br> pour comparer les performances de votre site. <strong>Cliquez ici</strong> après avoir débloqué les statistiques avancées pour y accéder.</br></br><img src='https://i.imgur.com/fOk5J81.png' />",
  view_user_ratings: "Voir, modifier, supprimer les évaluations des utilisateurs",
  not_a_valid_linkedin_link: "Lien LinkedIn invalide",
  not_a_valid_facebook_link: "Lien Facebook invalide",
  not_a_valid_twitter_link: "Lien Twitter invalide",
  not_a_valid_instagram_link: "Lien Instagram invalide",
  not_a_valid_whatsapp_link: "Lien WhatsApp invalide",
  not_a_valid_youtube_link: "Lien YouTube invalide",
  not_a_valid_reddit_link: "Lien Reddit invalide",
  not_a_valid_telegram_link: "Lien Telegram invalide",
  not_a_valid_snapchat_link: "Lien Snapchat invalide",
  not_a_valid_pinterest_link: "Lien Pinterest invalide",
  not_a_valid_yelp_link: "Lien Yelp invalide",
  not_a_valid_flicker_link: "Lien Flicker invalide",
  not_a_valid_vimeo_link: "Lien Vimeo invalide",
  not_a_valid_vk_link: "Lien VK invalide",
  not_a_valid_soundcloud_link: "Lien SoundCloud invalide",
  not_a_valid_github_link: "Lien GitHub invalide",
  not_a_valid_tumblr_link: "Lien Tumblr invalide",
  not_a_valid_skype_link: "Lien Skype invalide",
  give_us_feedback: "Veuillez nous faire part de vos commentaires",
  please_do_let_us_know: "Faites-le nous savoir:",
  your_feedback_is_important_to_us: "Vos avis sont importants pour nous!",
  if_you_would_like_to_let_us_know:
    "Si vous souhaitez partager un avis ou une suggestion pour améliorer nos produits et services, nous serions ravis de vous entendre!",
  how_you_use_our_products: "Comment utilisez-vous nos produits?",
  how_we_can_improve_our_products_or_service: "Comment pouvons-nous améliorer nos produits ou services?",
  we_look_forward_to_hearing_from_you: "Nous attendons votre retour avec impatience!",
  feedback_form: "Formulaire de retour",
  thank_you: "Merci!",
  rate_us: "Évaluez-nous!",
  we_have_recieved_your_feedback: "Votre message a été reçu!",
  i_allow_for_this_message_to_be_shared_the_other_users:
    "J'autorise ce message à être partagé avec d'autres utilisateurs",
  if_you_delete_this_file:
    "Si vous supprimez ce fichier, les QR codes qui l'utilisent activement pourraient être affectés. Êtes-vous sûr de vouloir continuer?",
  if_you_delete_selected_files:
    "Si vous supprimez ces fichiers sélectionnés, les QR codes qui les utilisent activement pourraient être affectés. Êtes-vous sûr de vouloir continuer?",
  if_you_delete_this_folder:
    "Si vous supprimez ce dossier, les QR codes qui l'utilisent activement pourraient être affectés. Êtes-vous sûr de vouloir continuer?",
  you_need_to_select_pdf_for_each_language_missing_in: "Vous devez ajouter un PDF pour chaque langue. Manquant:",
  you_can_not_delete_default_language: "Vous ne pouvez pas supprimer la langue par défaut",
  you_can_not_delete_active_language: "Vous ne pouvez pas supprimer la langue active",
  "Set as a default": "Définir comme langue par défaut",
  Delete: "Supprimer",
  Activate: "Activer la langue",
  a_new_verification_mail_has_been_sent_to_your_email_address:
    "Un nouvel e-mail de vérification a été envoyé à votre adresse e-mail.",
  email_verification: "Vérification de l'e-mail",
  did_not_recieve_an_email: "Vous n'avez pas reçu d'e-mail?",
  re_send_verification_mail: "Renvoyer l'e-mail de vérification",
  just_for_you_to_join_us_one_step_left: "Il ne vous reste plus qu'une étape pour nous rejoindre",
  an_activation_email_has_been_sent:
    "Un e-mail d'activation a été envoyé à votre adresse e-mail. Cliquez sur le lien pour activer votre compte.",
  locks_your_app_and_asks_users_to_enter_this_password:
    "Un système de mot de passe est mis en place pour sécuriser l'accès à votre application. Les utilisateurs doivent entrer le mot de passe pour accéder au contenu privé.",
  paste_given_code_if_you_would_like_to_track_your_statictics:
    "Si vous souhaitez suivre vos statistiques, collez ici votre code Google Analytics ou Yandex Metrika.",
  if_you_fill_out_the_below_fiels_qr_code_will_be_unavailable_before_start_date_and_after_end_date:
    "Si vous remplissez les champs ci-dessous, le QR code ne sera pas disponible avant la date de début et après la date de fin.",
  the_content_will_only_be_avialable_to_the_selected_deviceList:
    "Le contenu ne sera disponible que pour la liste des appareils sélectionnée.",
  this_must_be_less_than_5_characters: "Cela doit contenir moins de 5 caractères",
  click_here_to_save_qrcode:
    "<strong>NON ENREGISTRÉ!</strong> Accédez à la page d'édition et enregistrez vos modifications",
  type_changer_disclaimer: ({ named }) =>
    `Vous êtes sur le point de passer de <strong>${named(
      "currentType"
    )}</strong> à un autre type de QR code. Toutes les données existantes seront supprimées. Cette action est irréversible.`,
  your_short_url_will_look_like_this:
    "Votre URL courte ressemblera à ceci (si ce n'est pas votre domaine personnel): q-r.cc/adc256",
  qr_code_warning_text:
    "Note: Si vous modifiez le domaine ou la valeur, le contenu du QR code changera. Assurez-vous de ne pas compromettre un QR code imprimé.",
  your_profile_photo_has_been_successfuly_updated: "Votre photo de profil a été mise à jour avec succès!",
  your_profile_photo_has_been_successfuly_removed: "Votre photo de profil a été supprimée avec succès!",
  you_at_least_need_to_have_1_address: "Vous devez avoir au moins une adresse!",
  pricing_guide: `
  <div style="padding:30px;background:#ffffff">
  <h1 class="text-center">Guide des prix</h1>
  <ul>
  <li>Nous proposons des solutions adaptées aux besoins de nos clients avec une politique de tarification transparente et équitable. Nous offrons différents forfaits: Starter, Advanced et Business. Des offres personnalisées sont également disponibles.</li><li>Nos forfaits sont vendus par QR code et offrent différents niveaux de services et de fonctionnalités.</li><li>Le forfait Starter est idéal pour les petites entreprises et les particuliers. Le forfait Advanced offre plus de fonctionnalités pour les entreprises de taille moyenne. Le forfait Business est conçu pour les grandes entreprises ayant des besoins avancés.</li>
  <li>Nous proposons également des solutions sur mesure pour des besoins spécifiques.</li>
  <li>Pour plus d'informations, veuillez nous contacter.</li>
  </ul><p class="text-end">QR Code Matic</p>
  </div>`,
  how_it_works: "Comment ça marche",
  how_it_works_button_text: "Comment ça marche",
  your_subscription_expired: "Votre abonnement a expiré. Vous pouvez créer un QR code statique gratuitement.",
  qrcode_will_be_deleted_in_24: "Ce QR code sera supprimé dans 24 heures. Cliquez ici pour en savoir plus.",
  manage_your_own_domains: "Gérez vos propres domaines",
  domain_list: "Liste des domaines",
  domain_name: "Nom du domaine",
  short_link: "Lien court",
  options: "Options",
  delete: "Supprimer",
  domain_delete: "Supprimer le domaine",
  domain_delete_desc: "Vous êtes sur le point de supprimer ce domaine.",
  domain_delete_desc_2: "Êtes-vous sûr de vouloir continuer?",
  verified_status: "Statut de vérification",
  how_to_setup: "Comment configurer?",
  how_to_setup_desc:
    "Pour activer votre domaine court, configurez un sous-domaine qui pointe vers q-r.cc via un enregistrement CNAME. Cela peut prendre jusqu'à 24 heures pour être reconnu.",
  what_is_a_short_URL: "Qu'est-ce qu'une URL courte?",
  what_is_a_short_URL_desc:
    "Les QR codes dynamiques utilisent des URL courtes pour rediriger vers une page cible, permettant ainsi le suivi et la modification des liens sans changer le QR code imprimé.",
  create_template_button_text: ({ named }) =>
    `<span class="font-weight-bold mr-1">Conception</span><span class="font-weight-light">Créer</span>`,
  queue_processing_title: ({ named }) => `Votre processus ${named("type")} est en cours!`,
  queue_done_title: ({ named }) => `Votre processus ${named("type")} est terminé!`,
  queue_done_text: ({ named }) =>
    `Votre traitement de masse ${named(
      "type"
    )} est terminé. Vous pouvez maintenant modifier vos QR codes maîtres et leurs QR codes secondaires.`,
  queue_processing_text: ({ named }) =>
    `Votre demande de traitement en masse pour ${named(
      "type"
    )} a été mise en file d'attente. Vous recevrez un e-mail une fois que votre demande sera terminée.`,
  waiting_for_process: "En attente de finalisation de votre demande...",
  select_all: "Tout sélectionner",
  deselect_all: "Tout désélectionner",
  add_more_child_from_previous_qrcodes: "+ Ajouter d'autres QR Codes secondaires",
  add_more_child_from_previous_qrcodes_tooltip:
    "Ajoutez ou transférez d'autres QR Codes sous ce QR Code pour les mettre à jour en masse rapidement.",
  convert_to_master: "Convertir en maître",
  convert_to_master_tooltip: "Convertissez ce QR Code en maître pour y ajouter ou transférer d'autres QR Codes.",
  add_more_child_dialog_title: "Ajouter des QR Codes existants sous ce maître",
  add_more_child_dialog_button_text: "Effectuer le transfert",
  add_more_child_is_successful: "Opération réussie",
  add_more_child_dialog_p1: () =>
    `Ce menu vous permet d'incorporer vos QR Codes existants sous un QR Code maître afin d'effectuer des modifications groupées.`,
  add_more_child_dialog_p2: ({ named }) =>
    `Les QR Codes sélectionnés ci-dessous seront placés sous le QR Code maître <strong>${named("title")}</strong>.`,
  add_more_child_dialog_p3: () => `Les icônes d'avertissement affichées à côté des QR Codes indiquent:
  <ul>
    <li><span style="background-color:#1abcb5;padding:2px 5px;border-radius:2px;color:#ffffff;">1 QR Code secondaire</span> Ce QR Code a déjà des QR Codes secondaires. En sélectionnant cette option, vous transférez aussi ses secondaires.</li>
    <li><i class="fa-solid fa-circle-info orange--text mr-2"></i> Ce QR Code est déjà un maître. S'il est sélectionné, il perdra son statut de maître et sera transféré sous un autre QR Code.</li>
  </ul>`,
  are_you_sure_convert_child_to_master: ({ named }) =>
    `Vous êtes sur le point de retirer le QR Code intitulé ${named(
      "title"
    )} de son maître et de le convertir en QR Code maître. Voulez-vous continuer?`,
  add_more_child_count: ({ named }) => `Il y a ${named("count")} QR Codes secondaires`,
  gradient_on_eyes: "Dégradé sur les yeux",
  update_prices: "Mettre à jour les prix du menu",
  confirm_delete_all: "Êtes-vous sûr de vouloir supprimer tous ces QR Codes?",
  unsaved_changes_will_be_lost: "Les modifications non enregistrées seront perdues. Voulez-vous quitter cette page?",
  show_media: "Afficher les médias à copier",
  $LOGIN: {
    welcome_to: "Bienvenue sur QR Code Matic!",
    just_one_step: "Il ne vous reste plus qu'une étape pour créer un QR Code dynamique ou statique!",
    you_are_almost_done: "Vous y êtes presque!",
    please_choose_appropriate_options: "Veuillez sélectionner l'option qui vous convient!",
    please_chose_one_of_the_options: "Veuillez faire un choix",
    disclaimer: "Aucune information de carte bancaire ne sera demandée durant la période d'essai.",
    new_here: "Nouveau ici?",
    create_an_account: "Créer un compte",
    password: "Mot de passe",
    password_re: "Confirmer le mot de passe",
    forgot_password: "Mot de passe oublié",
    sign_in: "Se connecter",
    sign_in_with_google: "Se connecter avec Google",
    sign_in_with_facebook: "Se connecter avec Facebook",
    sign_in_with_twitter: "Se connecter avec Twitter",
    sign_up: "S'inscrire",
    subheader: "Remplissez les informations requises pour l'inscription",
    your_organization: "Votre secteur",
    your_country: "Votre pays",
    fullname: "Nom complet",
    confirm_password: "Confirmer le mot de passe",
    agree_terms: "J'accepte les conditions d'utilisation et la politique de confidentialité",
    did_you_forget_password: "Mot de passe oublié?",
    forgot_password_subheader: "Saisissez votre adresse e-mail pour réinitialiser votre mot de passe",
    submit: "Envoyer",
    cancel: "Annuler",
    terms: "Conditions générales et politique de confidentialité",
    contact_us: "Contactez-nous",
    enter_new_password: "Saisissez un nouveau mot de passe",
    your_password_has_been_reset: "Votre mot de passe a été réinitialisé",
    you_will_be_redirected_to_login: "Vous serez redirigé vers la page de connexion...",
    reset_link_is_sent_to_your_mail: "Un lien de réinitialisation a été envoyé à votre adresse e-mail.",
    check_your_mail_box: "Vérifiez votre boîte mail",
    login: "CONNEXION",
    your_country: "Sélectionnez votre pays",
    your_language: "Sélectionnez votre langue",
  },
  $ORDERS: {
    order_number: "Numéro de commande",
    order_status: "Statut de la commande",
    product_name: "Nom du produit",
    currency: "Devise",
    order_date: "Date de commande",
    active_package: "Forfait actif",
    started_at: "Date de début",
    expires_at: "Date d'expiration",
    remaining_days: "Jours restants",
    actions: "Actions",
    order_title: "COMMANDES",
    payment_confirmed: "Confirmé",
    incorrect_payment: "Paiement incorrect",
    awaiting_payment: "En attente de paiement",
    order_canceled: "Annulé",
    cancel_order: "Annuler la commande",
    status: "Statut de la commande",
    invalid_package: "Forfait invalide",
    payment_type: "Type de paiement",
    account_number: "Numéro de compte",
    payment_amount: "Montant du paiement",
    payment_desc: "Description du paiement",
    bank_transfer: "Virement bancaire",
    creditcard: "Carte de crédit",
  },
  $SELECT_SECTOR: {
    personal_use: "Usage personnel",
    tourism: "Tourisme",
    restaurants: "Restaurants",
    software: "Logiciel",
    photo_video: "Photo et Vidéo",
    cities_culture: "Villes et Culture",
    event_management: "Gestion d'événements",
    home_repair: "Maison et Réparations",
    finance: "Finance",
    publisher: "Édition",
    nonprofit: "Organisme à but non lucratif",
    retail: "Vente au détail",
    real_estate: "Immobilier",
    gym: "Salles de sport et bien-être",
    hotel: "Hôtels et Resorts",
    products: "Produits",
    education: "Éducation",
    goverment: "Gouvernement",
    doctors: "Médecins et Services de santé",
    agency: "Agences",
    electronics: "Électronique",
    paper: "Meubles, Papier et Produits en papier",
    glass: "Verre, Ciment et Terre",
    environment: "Environnement",
    energy: "Énergie",
    foods: "Alimentation",
    construction: "Construction",
    business: "Affaires et Gestion",
    chemistry: "Chimie, Pétrole, Caoutchouc et Plastique",
    culture: "Culture, Art et Design",
    mines: "Mines",
    machines: "Machines",
    media: "Médias, Communication et Édition",
    metal: "Métallurgie",
    automotive: "Automobile",
    social: "Services sociaux",
    sports: "Sport et Loisirs",
    agriculture: "Agriculture, Chasse et Pêche",
    textile: "Textile, Habillement, Cuir",
    trade: "Commerce (Ventes et Marketing)",
    community: "Communauté et Services Personnels",
    beverage: "Restauration et Boissons",
    transport: "Transport et Logistique",
    communications: "Communications",
    other: "Autres",
  },
  $ORDERS: {
    order_number: "Numéro de commande",
    order_status: "Statut de la commande",
    product_name: "Nom du produit",
    currency: "Devise",
    order_date: "Date de commande",
    active_package: "Forfait actif",
    started_at: "Date de début",
    expires_at: "Date d'expiration",
    remaining_days: "Jours restants",
    actions: "Actions",
    order_title: "COMMANDES",
    payment_confirmed: "Confirmé",
    incorrect_payment: "Paiement incorrect",
    awaiting_payment: "En attente de paiement",
    order_canceled: "Annulé",
    cancel_order: "Annuler la commande",
    status: "Statut de la commande",
    invalid_package: "Forfait invalide",
    payment_type: "Type de paiement",
    account_number: "Numéro de compte",
    payment_amount: "Montant du paiement",
    payment_desc: "Description du paiement",
    bank_transfer: "Virement bancaire",
    creditcard: "Carte de crédit",
  },
  $SELECT_SECTOR: {
    personal_use: "Usage personnel",
    tourism: "Tourisme",
    restaurants: "Restaurants",
    software: "Logiciel",
    photo_video: "Photo et Vidéo",
    cities_culture: "Villes et Culture",
    event_management: "Gestion d'événements",
    home_repair: "Maison et Réparations",
    finance: "Finance",
    publisher: "Édition",
    nonprofit: "Organisme à but non lucratif",
    retail: "Vente au détail",
    real_estate: "Immobilier",
    gym: "Salles de sport et bien-être",
    hotel: "Hôtels et Resorts",
    products: "Produits",
    education: "Éducation",
    goverment: "Gouvernement",
    doctors: "Médecins et Services de santé",
    agency: "Agences",
    electronics: "Électronique",
    paper: "Meubles, Papier et Produits en papier",
    glass: "Verre, Ciment et Terre",
    environment: "Environnement",
    energy: "Énergie",
    foods: "Alimentation",
    construction: "Construction",
    business: "Affaires et Gestion",
    chemistry: "Chimie, Pétrole, Caoutchouc et Plastique",
    culture: "Culture, Art et Design",
    mines: "Mines",
    machines: "Machines",
    media: "Médias, Communication et Édition",
    metal: "Métallurgie",
    automotive: "Automobile",
    social: "Services sociaux",
    sports: "Sport et Loisirs",
    agriculture: "Agriculture, Chasse et Pêche",
    textile: "Textile, Habillement, Cuir",
    trade: "Commerce (Ventes et Marketing)",
    community: "Communauté et Services Personnels",
    beverage: "Restauration et Boissons",
    transport: "Transport et Logistique",
    communications: "Communications",
    other: "Autres",
  },
  $SUBSCRIPTION: {
    selected_package_name: "Forfait sélectionné",
    choose_payment_method: "Choisissez une méthode de paiement",
    choose_payment_method_description: "Sélectionnez le mode de paiement qui vous convient le mieux",
    add_payment_info: "Informations de paiement",
    payment_is_safe: "Le paiement est sécurisé",
    billing_info: "Informations de facturation",
    complete_payment: "Finaliser le paiement",
    bank_transfer_info: "Informations sur le virement bancaire",
    about_pricing: "Pour en savoir plus sur nos tarifs, veuillez consulter",
    choose_your_plan: "Choisissez votre plan",
    pricing_policy: "Politique de tarification",
    private_or_business: "Veuillez sélectionner s'il s'agit d'un achat personnel ou professionnel",
    company_invoice: "Facture d'entreprise",
    individual_invoice: "Facture individuelle",
    select: "ACHETER",
    discountMessage: ({ named }) => `Une réduction de ${named("amount")}$ a été appliquée`,
  },
  validations: {
    field_required: "Ce champ est requis",
    email_required: "L'e-mail est requis",
    password_required: "Le mot de passe est requis",
    agree_terms: "Vous devez accepter les conditions",
    invalid_zip: "Code postal invalide",
    invalid_email: "E-mail invalide",
    invalid_password: "Mot de passe invalide",
    invalid_youtube: "Lien YouTube invalide",
    not_a_valid_email: "Ce n'est pas un e-mail valide",
    not_a_valid_phone_number: "Ce n'est pas un numéro de téléphone valide",
    you_must_accept_the_terms: "Vous devez accepter les conditions",
    your_password_does_not_match: "Votre mot de passe ne correspond pas",
    you_must_enter_valid_email: "Vous devez entrer une adresse e-mail valide",
    you_must_enter_password: "Vous devez entrer un mot de passe",
    you_must_enter_your_name: "Vous devez entrer votre nom",
    you_must_choose_country: "Vous devez choisir un pays",
    you_must_choose_organization: "Vous devez choisir une organisation",
    must_contain_numbers: "Doit contenir au moins un chiffre",
    must_contain_letters: "Doit contenir au moins une lettre",
    short_link_is_required: "Champ requis",
    short_link_special_char: "Ne doit contenir que des chiffres et des lettres",
    short_link_between: "Doit contenir entre 3 et 30 caractères",
    less_than: ({ named }) => `Doit contenir moins de ${named("amount")} caractères`,
    more_than: ({ named }) => `Doit contenir au moins ${named("amount")} caractères`,
  },
  FAQ: {
    static_dynamic_title: "Quelle est la différence entre un QR Code statique et dynamique ?",
    static_dynamic: `Les QR Codes statiques ne peuvent pas être modifiés après leur création et impression. Si le lien devient invalide, il faudra réimprimer un nouveau code. En revanche, les QR Codes dynamiques peuvent être modifiés en temps réel. Ils contiennent une URL de redirection, permettant de réutiliser le même QR Code plusieurs fois, économisant ainsi du temps et des coûts.`,
    better_static_dynamic_title: "Lequel est le meilleur : QR Code statique ou dynamique ?",
    better_static_dynamic: `Si vous avez une campagne unique sans besoin de modifications ultérieures, un QR Code statique peut suffire. En revanche, si vous souhaitez suivre les analyses des scans, rediriger les utilisateurs vers différentes pages et apporter des modifications à la volée, le QR Code dynamique est fortement recommandé.`,
    how_change_dynamic_title: "Comment convertir un QR Code statique en un QR Code dynamique ?",
    how_change_dynamic:
      "Vous ne pouvez pas convertir un QR Code statique en un QR Code dynamique, car les QR Codes statiques ne sont pas modifiables après leur création.",
    how_qrcode_free_title: "Comment créer un QR Code gratuit ?",
    how_qrcode_free: `Rendez-vous sur le générateur de QR Code de QR Code Matic et commencez à créer votre QR Code gratuitement. Vous pouvez le télécharger dans le format souhaité et le personnaliser. Note : Les QR Codes statiques sont gratuits à vie. Cependant, pour créer des QR Codes dynamiques, vous devez démarrer un essai gratuit de 7 jours ou acheter un abonnement.`,
    what_is_qr_code_title: "Qu'est-ce qu'un QR Code ?",
    what_is_qr_code: `Un QR Code (Quick Response Code) est un type de code-barres bidimensionnel développé par la société Denso Wave. Il permet de stocker des centaines de caractères sous forme cryptée. Il est généralement composé de pixels noirs et blancs et peut être rapidement lu et interprété par un smartphone ou une tablette.`,
    how_can_i_generate_qr_code_title: "Comment générer un QR Code ?",
    how_can_i_generate_qr_code: `Un QR Code peut être généré en utilisant un générateur de QR Code en ligne. Une fois créé, il peut être téléchargé sous différents formats et imprimé pour être utilisé sur divers supports tels que des brochures, des affiches, des cartes de visite, etc.`,
    what_should_I_consider_when_printing_title: "À quoi faut-il faire attention lors de l'impression d'un QR Code ?",
    what_should_I_consider_when_printing: `Si vous imprimez un QR Code sur des documents, il est essentiel d'utiliser des formats haute résolution. QR Code Matic permet de télécharger des QR Codes en PNG, AI, EPS et SVG, garantissant une qualité optimale même pour les impressions en grand format. Il est également recommandé de tester le QR Code avec différents smartphones avant la publication.`,
    how_can_QR_codes_be_scanned_title: "Comment scanner un QR Code ?",
    how_can_QR_codes_be_scanned: `Les QR Codes peuvent être scannés à l'aide de l'appareil photo d'un smartphone ou d'une application dédiée. De nombreux appareils modernes reconnaissent les QR Codes directement sans avoir besoin d'une application tierce.`,
    how_to_use_QR_codes_title: "Comment utiliser les QR Codes ?",
    how_to_use_QR_codes: `Avec la popularité croissante des smartphones, les QR Codes sont devenus incontournables dans le marketing et la communication. Ils sont utilisés sur des affiches, des flyers, des cartes de visite, et même sur des produits pour rediriger vers des sites web, des vidéos, des fichiers PDF ou des coordonnées de contact.`,
    can_I_customize_QR_codes_visually_title: "Puis-je personnaliser visuellement mes QR Codes ?",
    can_I_customize_QR_codes_visually: `Grâce à une tolérance élevée aux erreurs, il est possible de modifier les QR Codes tout en conservant leur lisibilité. Vous pouvez changer les couleurs de premier plan et d'arrière-plan, ajouter un logo et modifier les motifs des yeux et du corps. Cependant, il est toujours recommandé de tester la lisibilité après toute personnalisation.`,
    can_I_view_scan_statistics_of_QR_codes_title: "Puis-je voir les statistiques de scan de mes QR Codes ?",
    can_I_view_scan_statistics_of_QR_codes: `Oui, les QR Codes dynamiques permettent de suivre des statistiques détaillées en temps réel, y compris le nombre de scans, les localisations, les appareils utilisés, les navigateurs, les genres, et bien plus encore.`,
    what_file_formats_and_size_should_i_use_for_printing_title:
      "Quels formats et tailles de fichiers devrais-je utiliser pour l'impression ?",
    what_file_formats_and_size_should_i_use_for_printing: `Pour des impressions de grande taille, il est recommandé d'utiliser des fichiers vectoriels (AI, EPS, SVG) afin d'éviter toute perte de qualité. De plus, la taille du QR Code doit être adaptée à sa distance de lecture. Par exemple, un QR Code de 2 cm x 2 cm est lisible à une distance de 20 cm, tandis qu'un QR Code de 1 mètre doit être utilisé pour une lecture à 10 mètres.`,
    where_and_how_to_use_qr_code_title: "Où et comment utiliser un QR Code ?",
    where_and_how_to_use_qr_code:
      "Les QR Codes peuvent être utilisés dans une multitude d'applications, de la promotion d'entreprise à l'amélioration de l'expérience utilisateur, en passant par la gestion logistique et la communication marketing.",
  },
  FAQ: {
    static_dynamic_title: "Quelle est la différence entre un QR Code statique et dynamique ?",
    static_dynamic: `Les QR Codes statiques ne peuvent pas être modifiés après leur création et impression. Si le lien devient invalide, il faudra réimprimer un nouveau code. En revanche, les QR Codes dynamiques peuvent être modifiés en temps réel. Ils contiennent une URL de redirection, permettant de réutiliser le même QR Code plusieurs fois, économisant ainsi du temps et des coûts.`,
    better_static_dynamic_title: "Lequel est le meilleur : QR Code statique ou dynamique ?",
    better_static_dynamic: `Si vous avez une campagne unique sans besoin de modifications ultérieures, un QR Code statique peut suffire. En revanche, si vous souhaitez suivre les analyses des scans, rediriger les utilisateurs vers différentes pages et apporter des modifications à la volée, le QR Code dynamique est fortement recommandé.`,
    how_change_dynamic_title: "Comment convertir un QR Code statique en un QR Code dynamique ?",
    how_change_dynamic:
      "Vous ne pouvez pas convertir un QR Code statique en un QR Code dynamique, car les QR Codes statiques ne sont pas modifiables après leur création.",
    how_qrcode_free_title: "Comment créer un QR Code gratuit ?",
    how_qrcode_free: `Rendez-vous sur le générateur de QR Code de QR Code Matic et commencez à créer votre QR Code gratuitement. Vous pouvez le télécharger dans le format souhaité et le personnaliser. Note : Les QR Codes statiques sont gratuits à vie. Cependant, pour créer des QR Codes dynamiques, vous devez démarrer un essai gratuit de 14 jours ou acheter un abonnement.`,
    what_is_qr_code_title: "Qu'est-ce qu'un QR Code ?",
    what_is_qr_code: `Un QR Code (Quick Response Code) est un type de code-barres bidimensionnel développé par la société Denso Wave. Il permet de stocker des centaines de caractères sous forme cryptée. Il est généralement composé de pixels noirs et blancs et peut être rapidement lu et interprété par un smartphone ou une tablette.`,
    how_can_i_generate_qr_code_title: "Comment générer un QR Code ?",
    how_can_i_generate_qr_code: `Un QR Code peut être généré en utilisant un générateur de QR Code en ligne. Une fois créé, il peut être téléchargé sous différents formats et imprimé pour être utilisé sur divers supports tels que des brochures, des affiches, des cartes de visite, etc.`,
    what_should_I_consider_when_printing_title: "À quoi faut-il faire attention lors de l'impression d'un QR Code ?",
    what_should_I_consider_when_printing: `Si vous imprimez un QR Code sur des documents, il est essentiel d'utiliser des formats haute résolution. QR Code Matic permet de télécharger des QR Codes en PNG, AI, EPS et SVG, garantissant une qualité optimale même pour les impressions en grand format. Il est également recommandé de tester le QR Code avec différents smartphones avant la publication.`,
    how_can_QR_codes_be_scanned_title: "Comment scanner un QR Code ?",
    how_can_QR_codes_be_scanned: `Les QR Codes peuvent être scannés à l'aide de l'appareil photo d'un smartphone ou d'une application dédiée. De nombreux appareils modernes reconnaissent les QR Codes directement sans avoir besoin d'une application tierce.`,
    how_to_use_QR_codes_title: "Comment utiliser les QR Codes ?",
    how_to_use_QR_codes: `Avec la popularité croissante des smartphones, les QR Codes sont devenus incontournables dans le marketing et la communication. Ils sont utilisés sur des affiches, des flyers, des cartes de visite, et même sur des produits pour rediriger vers des sites web, des vidéos, des fichiers PDF ou des coordonnées de contact.`,
    can_I_customize_QR_codes_visually_title: "Puis-je personnaliser visuellement mes QR Codes ?",
    can_I_customize_QR_codes_visually: `Grâce à une tolérance élevée aux erreurs, il est possible de modifier les QR Codes tout en conservant leur lisibilité. Vous pouvez changer les couleurs de premier plan et d'arrière-plan, ajouter un logo et modifier les motifs des yeux et du corps. Cependant, il est toujours recommandé de tester la lisibilité après toute personnalisation.`,
    can_I_view_scan_statistics_of_QR_codes_title: "Puis-je voir les statistiques de scan de mes QR Codes ?",
    can_I_view_scan_statistics_of_QR_codes: `Oui, les QR Codes dynamiques permettent de suivre des statistiques détaillées en temps réel, y compris le nombre de scans, les localisations, les appareils utilisés, les navigateurs, les genres, et bien plus encore.`,
    what_file_formats_and_size_should_i_use_for_printing_title:
      "Quels formats et tailles de fichiers devrais-je utiliser pour l'impression ?",
    what_file_formats_and_size_should_i_use_for_printing: `Pour des impressions de grande taille, il est recommandé d'utiliser des fichiers vectoriels (AI, EPS, SVG) afin d'éviter toute perte de qualité. De plus, la taille du QR Code doit être adaptée à sa distance de lecture. Par exemple, un QR Code de 2 cm x 2 cm est lisible à une distance de 20 cm, tandis qu'un QR Code de 1 mètre doit être utilisé pour une lecture à 10 mètres.`,
    where_and_how_to_use_qr_code_title: "Où et comment utiliser un QR Code ?",
    where_and_how_to_use_qr_code:
      "Les QR Codes peuvent être utilisés dans une multitude d'applications, de la promotion d'entreprise à l'amélioration de l'expérience utilisateur, en passant par la gestion logistique et la communication marketing.",
  },
  WHY_QR_CODE: {
    unlimited_title: "Illimité",
    unlimited:
      "Avec QRCodeMatic.com, offrez un accès illimité aux vidéos, PDF, messages, informations et tout autre contenu via vos QR Codes.",
    attractive_title: "Attrayant",
    attractive:
      "Les QR Codes suscitent naturellement la curiosité des utilisateurs. Avec une mise en œuvre efficace, ils peuvent accroître l'engagement et la satisfaction client.",
    everywhere_is_a_showcase_for_you_title: "Votre vitrine est partout",
    everywhere_is_a_showcase_for_you:
      "Chaque QR Code est une vitrine de votre marque, que ce soit sur des brochures, des cartes de visite ou des supports publicitaires.",
    add_on_your_product_title: "Ajoutez des brochures numériques à vos produits",
    add_on_your_product:
      "Utilisez des QR Codes pour fournir des manuels d'utilisation, des vidéos explicatives ou des certificats de conformité directement sur vos produits.",
    your_successful_sales_representative_title: "Votre commercial 24/7",
    your_successful_sales_representative:
      "Les QR Codes facilitent l'accès à vos produits et services à tout moment, améliorant ainsi l'expérience utilisateur.",
    makes_you_easy_to_reach_title: "Un accès simplifié",
    makes_you_easy_to_reach:
      "Grâce aux QR Codes, vos clients peuvent vous appeler, envoyer un e-mail, vous suivre sur les réseaux sociaux ou obtenir votre localisation en un seul scan.",
    it_is_social_title: "Connecté aux réseaux sociaux",
    it_is_social:
      "Les QR Codes permettent d'accéder instantanément à vos réseaux sociaux, d'encourager les abonnements et d'améliorer l'interaction avec votre audience.",
    low_cost_title: "Coût réduit",
    low_cost: "Les QR Codes sont une solution marketing efficace et abordable, idéale pour toute entreprise.",
    durable_title: "Durable",
    durable:
      "Les QR Codes restent actifs et fonctionnels aussi longtemps que vous le souhaitez, garantissant une solution durable pour le marketing et la communication.",
  },
  BILLING: {
    when_can_i_use_my_package_title: "Quand puis-je utiliser mon forfait ?",
    when_can_i_use_my_package:
      "Dès que votre paiement est effectué et votre commande passée, toutes les fonctionnalités de votre plan sont immédiatement débloquées. Vous pouvez directement commencer à créer de nouveaux QR Codes ou gérer ceux de votre essai gratuit.",
    can_i_upgrade_to_a_higher_plan_title: "Puis-je passer à un forfait supérieur ?",
    can_i_upgrade_to_a_higher_plan:
      "Oui. Si vous atteignez la limite de QR Codes dynamiques ou souhaitez débloquer plus de fonctionnalités, vous pouvez passer à un forfait supérieur à tout moment. Nous vous accorderons alors un remboursement au prorata.",
    how_long_is_the_contract_period_title: "Quelle est la durée du contrat ?",
    how_long_is_the_contract_period:
      "Le contrat est automatiquement renouvelé pour la période sélectionnée, sauf en cas d’annulation.",
    how_do_i_cancel_my_subscription_title: "Comment annuler mon abonnement ?",
    how_do_i_cancel_my_subscription:
      "Si vous ne souhaitez pas renouveler votre abonnement, un simple e-mail suffit. Votre demande doit être reçue au moins 30 jours avant la fin de votre période d’engagement initiale.",
  },
  ANALYTIC: {
    what_kind_of_analytics_reporting_do_you_offer_title: "Quels types de rapports analytiques proposez-vous ?",
    what_kind_of_analytics_reporting_do_you_offer:
      "Nos services de raccourcissement d’URL offrent des données analytiques accessibles depuis votre tableau de bord. Vous pouvez voir en temps réel chaque événement de scan avec la date, l’heure, le type d’appareil et la localisation. Les données sont disponibles sous forme de tableaux et de graphiques, et peuvent être téléchargées en format Excel ou PDF. Chaque QR Code dynamique dispose de son propre rapport analytique détaillé.",
    can_analytics_be_used_with_all_your_QR_codes_title: "Les analyses sont-elles disponibles pour tous les QR Codes ?",
    can_analytics_be_used_with_all_your_QR_codes:
      "Non. Les données analytiques ne peuvent être collectées que pour les QR Codes dynamiques, où l’utilisateur passe par une URL raccourcie hébergée sur notre site, nous permettant ainsi de recueillir les données. Pour les QR Codes statiques, il est impossible de suivre les statistiques, car ils dirigent les utilisateurs directement vers la destination sans passer par notre serveur.",
    how_can_I_access_my_analytics_reports_title: "Comment accéder à mes rapports analytiques ?",
    how_can_I_access_my_analytics_reports:
      "Une fois connecté à votre compte, vous pouvez accéder au tableau de bord via le lien en haut à droite de la page. Dans l’historique des rapports, l’onglet ‘Gérer’ à côté de chaque enregistrement QR Code permet d’afficher des détails supplémentaires. Si un QR Code est dynamique, un bouton ‘Analytics’ apparaît à droite, offrant un accès direct aux rapports détaillés.",
    are_phone_numbers_and_email_addresses_included_in_analytics_reports_title:
      "Les rapports analytiques incluent-ils des numéros de téléphone et des adresses e-mail ?",
    are_phone_numbers_and_email_addresses_included_in_analytics_reports:
      "Non. Pour des raisons de confidentialité et de sécurité, les téléphones ne transmettent aucune donnée identifiable lors du scan d’un QR Code. Nous n’avons donc aucun accès aux numéros de téléphone ou adresses e-mail des utilisateurs.",
  },
  login_note: `<div style="font-weight: 900">IMPORTANT !</div> <span class="demo-time"> À la fin de la période d’essai</span>, vous pourrez continuer à utiliser votre <span class="font-weight-bolder">QR Code Statique GRATUIT</span> et votre <span class="font-weight-bolder">ABONNEMENT</span>.`,
  MENU: {
    NEW: "Nouveau",
    ACTIONS: "Actions",
    CREATE_POST: "Créer une publication",
    PAGES: "Pages",
    FEATURES: "Fonctionnalités",
    APPS: "Applications",
    DASHBOARD: "Tableau de bord",
    MARKET: "Marché",
    MYQRCODES: "Mes QR Codes",
    ALLQRCODES: "Tous les QR Codes",
    ACTIVEQRCODES: "QR Codes Actifs",
    PASSIVEQRCODES: "QR Codes Passifs",
    DELETEDQRCODES: "QR Codes Supprimés",
    MASTERQRCODES: "QR Codes Groupés",
    SUBSCRIPTIONS: "Tarifs et Forfaits",
    MYFOLDERS: "Mes dossiers",
    QRFOLDERS: "Dossiers QR Code",
    MYMEDIA: "Mes médias",
    ADDNEWFOLDER: "Ajouter un nouveau dossier",
    CREATENEWQRCODE: "Créer un nouveau QR Code",
    MYACCOUNT: "Mon compte",
    PAIDPLAN: "Plan de paiement",
    ORDERHISTORY: "Historique des commandes",
    SUBMITTICKET: "Support",
    HELP: "Aide",
    PACKAGE_HISTORY: "Historique des forfaits",
    DAY_REMANING: "Jours restants",
    SCAN: "Scan",
    STATIC: "Statique",
    DYNAMIC: "Dynamique",
    PACKAGE_TITLE: "DÉTAIL DU FORFAIT",
    CORPARATE: "Entreprise",
    TITLE: "Menu",
    BULKPRICE: "Mise à jour rapide des prix du menu",
  },
  QRLIST: {
    STATIC: "Statique",
    PREVIEW: "Aperçu de vos QR Codes créés.",
    DYNAMIC: "Dynamique",
    MULTIPLY_QRCODE: "Multiplier le QR Code",
    BULK_CREATE: "Ajoutez de nouveaux QR Codes sous ce QR Code ou mettez à jour leur contenu et conception en masse.",
    BULK_UPLOAD: "Téléchargement en masse",
    BULK_UPDATE: "Mise à jour en masse",
    BULK_SETTINGS: "Paramètres de téléchargement/mise à jour en masse",
    CLICK_TO_DOWNLOAD: "Cliquez pour télécharger",
    CLICK_TO_UPLOAD: "Cliquez pour télécharger votre fichier Excel",
    CLICK_TO_UPDATE: "Cliquez pour modifier",
    SELECT_FILE: "Sélectionnez un fichier",
    DOWNLOAD_SAMPLE_FILE: "Télécharger un fichier exemple",
    DOWNLOAD_SUCCESSFUL: "Téléchargement réussi !",
    CLICK_HERE_TO_CUSTOMIZE: "Cliquez ici pour personnaliser la couleur et la conception de votre QR Code !",
    CLICK_HERE_TO_FOLDER_CUSTOMIZE:
      "Ajoutez un nouveau dossier depuis le menu de gauche pour filtrer vos QR Codes par campagne.",
    CLICK_HERE_TO_EDIT: "Cliquez sur le titre pour modifier le contenu de votre carte !",
    NON_TRACKABLE: "Statique / Non traçable",
    MY_QR_CODE: "Vous êtes sur le point de supprimer votre My Qr Card",
    DOWNLOAD_IN_PDF_FORMAT: "Télécharger en format PDF",
    DOWNLOAD_IN_PNG_FORMAT: "Télécharger en format PNG",
    DOWNLOAD_IN_SVG_FORMAT: "Télécharger en format SVG",
    DOWNLOAD_IN_EPS_FORMAT: "Télécharger en format EPS",
    DESC: "Testez votre QR Code sur différents appareils avant de le télécharger. Utilisez .svg, .eps, .pdf pour l’impression et .png pour les sites web.",
    NOTE: "Remarque : les QR Codes Radial et Gradient doivent être téléchargés au format .svg.",
    BULK_UPLOAD_DESC:
      "Téléchargez un exemple Excel et remplissez-le correctement pour créer plusieurs QR Codes à la fois.",
    BULK_CREATE_DESC: "Téléchargez et modifiez tous vos QR Codes en une seule fois.",
    BULK_UPDATE_DESC: "Téléchargez et mettez à jour tous vos QR Codes en une seule fois.",
    UPLOAD_YOUR_FILE: "Téléchargez votre fichier",
  },
  WIZARD: {
    BASIC_INFO: {
      TITLE: "Ajoutez vos informations de contenu",
      CATEGORIES: "Catégories",
      CATEGORY_IMAGE: "Image de la catégorie",
      SELECT_IMAGE: "Sélectionnez une image",
      LOADER_TEXT: "",
      THIS_URL_IS_INVALID: "Cette URL est invalide",
      FIELDS: {
        key: "Champ",
        value: "Valeur",
        start: "Début",
        end: "Fin",
        video: "Vidéo",
        title: "Titre",
        image: "Image",
        text: "Texte",
        color: "Couleur",
        description: "Description",
        name: "Nom",
        number: "Numéro",
        phone: "Téléphone",
        buttonText: "Texte du bouton",
        url: "URL",
        BASIC_INFORMATION: "Informations générales",
        SETTINGS: "Paramètres",
        LANGUAGE: "Choisir la langue",
        ACTIVE_LANGUAGE: "Langue active",
        ADD_SOME_INFO: "Entrez les informations requises",
        FULL_NAME: "Nom complet",
        COMPANY_NAME: "Nom de l'entreprise",
        JOB_TITLE: "Titre du poste",
        DEPARTMENT: "Département",
        BLOOD_GROUP: "Groupe sanguin",
        FOLDER_NO: "Numéro d'enregistrement",
        DATE_OF_START: "Date de début",
        COMPANY: "Entreprise",
        MAIL: "E-mail",
        WEBSITE: "Site Web",
        PHONE: "Téléphone",
        ADDRESS: "Adresse",
        ENTER_LOCATION: "Sélectionnez un emplacement",
        PLEASE_CHOOSE_LOCATION: "Veuillez choisir un emplacement",
        MANAGER_FULL_NAME: "Nom du responsable",
        MANAGER_PHONE: "Téléphone du responsable",
        MANAGER_JOBTITLE: "Poste du responsable",
        MANAGER_MAIL: "E-mail du responsable",
        ART_NAME: "Nom de l'œuvre",
        ART_ACCESSION_NUMBER: "Numéro d'inventaire",
        ART_ERA: "Époque",
        ART_MEASUREMENT: "Dimensions",
        ART_DISCOVERY_LOCATION: "Lieu de découverte",
        ART_ACCESSION_DATE: "Date d'entrée en inventaire",
        ART_DESCRIPTION: "Description de l'œuvre",
        BLOODGROUP: "Groupe sanguin",
        MEDICATION: "Traitement médical",
        PLATENUMBER: "Numéro de plaque",
        ALLERGICTO: "Allergie à",
        DESCRIPTION: "Description",
        RESPONSIBLESTAFFPHONE: "Responsable",
        CITY: "Ville/Commune",
        FAX: "Fax",
        ZIP: "Code postal",
        ADD_A_NEW_DOCUMENT: "Ajouter un nouveau document",
        QR_CODE_TITLE: "Titre du QR Code",
        general_info: "Informations générales",
        SHOW_CONTACT_FROM: "Afficher le formulaire de contact",
        SHOW_GOOGLE_MAPS: "Afficher Google Maps",
        SHOW_ADD_TO_CONTACTS: "Afficher l'option Ajouter aux contacts",
        SHOW_ADDRESS_FIELD: "Afficher le champ Adresse",
        ADDITIONAL_INFO: "Informations supplémentaires",
        SHOW_PHONE_ICON: "Afficher l'icône Téléphone",
        SHOW_MAIL_ICON: "Afficher l'icône E-mail",
        CONTACT_INFO: "Informations de contact",
        FIELDS: "Champs",
        YOU_HAVE_TO_FILL_THE_FIELDS_BEFORE_APPLY: "Vous devez remplir les champs avant d'appliquer.",
        SELECT_PDF: "Sélectionnez un PDF.",
        DESCRIPTION_FIELD: "Ajoutez une description pour ce champ",
        CURRENT_RESTAURANTS: "Restaurants actuels",
        NO_TITLE: "Pas de titre",
        NEW_RESTAURANT: "Nouveau restaurant",
        BOOK_TITLE: "Titre du PDF",
        PERSONNEL_INFO: "Informations du personnel",
        COMPANY_INFO: "Informations sur l'entreprise",
        ENABLE_DOCUMENTS_PAGE: "Activer la page des documents",
        ENABLE_REMINDER: "Activer/Désactiver le rappel par e-mail",
        ENABLE_COUNTER: "Activer/Désactiver le compteur",
        CUSTOM_FIELDS: "Champs personnalisés",
        CALL_LIST: "Liste d'appels",
        PERSON: "Personnel",
        EMERGENCY_CALLS: "Appels d'urgence",
        DOCUMENTS_LIST: "Liste des documents",
        GROUPS: "Groupe",
        TITLE: "Titre",
        SCHEDULE_INFO: "Informations sur le programme",
        DAY: "Jour",
        SHOW_SCHEDULE_CALENDER: "Afficher le calendrier du programme",
        MANAGER_INFO: "Informations du responsable",
        MAPS_STYLE: "Style de carte",
        TEXT_ADDRESS: "Texte",
        TITLE_ADDRESS: "Titre",
        SONG: "Musique",
        ADD_MORE: "Ajouter plus",
        ARTIST: "Artiste",
        NO_RESTRICTION: "Aucune restriction",
        ARTIFACT_INFORMATION: "Informations sur l'œuvre",
        ARTIFACT_INFO: "Informations sur le bâtiment",
        STORE_INFORMATION: "Informations sur le magasin",
        PLATFORM: "Plateforme",
        OTHER_FIELDS: "Autres champs",
        WIFINAME: "Nom du Wifi",
        RANDOM_LINK: "Lien aléatoire",
        SHOW_RANDOM_LINK: "Afficher un élément aléatoire de la liste.",
        SHOW_ALL_RANDOM_LINK: "Afficher tous les éléments de la liste.",
        MULTIPLE_LINK: "Liens multiples",
        LINKS: "Liens",
        PRODUCT_NAME: "Nom du produit",
        GO_TO_WEBSITE: "Aller sur le site",
        SELECT_ICON: "Sélectionnez une icône",
        brochure_image: "Image de la bannière",
        SELECT: "Sélectionner",
        SELECT_IMAGE: "Sélectionnez une image",
        ABOUT_US_PAGE_INFO: "Informations sur la page À propos",
        CONTACT_PAGE_INFO: "Informations sur la page Contact",
        RESERVATION_PAGE_INFO: "Informations sur la page de réservation",
        ABOUT_US_PAGE: "Page À propos",
        CONTACT_PAGE: "Page Contact",
        RESERVATION_PAGE: "Page de réservation",
        BROCHURES: "Brochures",
        BANNER: "Bannière",
        REDIRECT: "Redirection",
        SONGURL: "Collez le lien de la chanson...",
        isActive: "Actif/Inactif",
        proximity: "Proximité",
        sort_order: "Ordre de tri",
        SHOW_MIDDLE_SOCIAL_MEDIA_BUTTONS: "Afficher les boutons des réseaux sociaux",
        fileUrl: "URL du fichier",
        fileFormat: "Format du fichier",
      },
    },
    CREATEQR: "Créer un QR Code",
    UPDATE_CHANGES: "Mettre à jour les informations",
  },
  FOLLOWALBE: "Suivi possible (Payant)",
  NOT_TRACKED: "Non suivi (Gratuit à vie)",
  RESTAURANT: {
    title: "Titre: ",
    description: "Description: ",
    cover_image: "Image: ",
    category_icon: "Icône de catégorie: ",
    categories: "Catégories",
    category: "Catégorie",
    product: "Produit",
    products: "Produits: ",
    product_name: "Nom du produit",
    preparation: "Temps de préparation: ",
    calorie: "Calories",
    ingredients: "Ingrédients: ",
    price: "Prix: ",
    price_label: "Étiquette de prix",
    likes: "J'aime: ",
    current_categories: "Catégories Actuelles",
    add_new_field: "Ajouter un Nouveau Champ",
    add_new_category: "Ajouter une Nouvelle Catégorie",
    add_new_product: "Ajouter un Nouveau Produit",
    add_new_price: "Ajouter",
    please_add_product: "Veuillez ajouter un produit",
    brochure_slider: "Bannières",
    delete_product: "SUPPRIMER LE PRODUIT",
    delete_category: "SUPPRIMER LA CATÉGORIE",
    new_category_title: "Nouvelle Catégorie",
    new_category_description: "Description de la nouvelle catégorie",
    new_product_title: "Nouveau Produit",
    new_product_description: "Description du nouveau produit",
    status_desc: "Les catégories inactives seront retirées de la publication.",
    status: "Statut",
    detailed_title: ({ named }) => `Détail de ${named("value")}`,
  },

  ERRORS: {
    err_1: "Erreur; Désolé, une erreur inconnue s'est produite. Veuillez réessayer plus tard.",
    err_2:
      "Erreur; Désolé, une erreur inattendue s'est produite lors du téléchargement de l'avatar. Veuillez réessayer.",
    err_3: "Erreur; Désolé, l'adresse de connexion est incorrecte. Veuillez utiliser une adresse valide.",
    err_4: "Erreur; Désolé, cet e-mail a déjà été confirmé. Essayez un autre e-mail.",
    err_6: "Erreur; Désolé, une erreur s'est produite. Veuillez réessayer plus tard.",
    err_7: "Erreur; Désolé, trop de requêtes ont été envoyées. Réessayez dans 1 minute.",
    err_8:
      "Erreur; Votre compte n'est pas vérifié ! Cliquez sur le bouton 'Renvoyer' sous votre mot de passe pour recevoir un nouvel e-mail de vérification.",
    err_9: "Erreur; Désolé, mot de passe incorrect. Veuillez entrer le bon mot de passe.",
    err_10: "Erreur; Désolé, aucun utilisateur enregistré avec cette adresse.",
    err_11: "Erreur; Désolé, vous n'avez pas accepté les conditions d'utilisation. Veuillez les accepter.",
    err_12: "Erreur; Désolé, l'utilisateur a été supprimé. Veuillez contacter notre support.",
    err_13: "Erreur; Désolé, le compte utilisateur est suspendu. Veuillez contacter notre support.",
    err_14: "Erreur; Désolé, le compte est inactif. Veuillez contacter notre support.",
    err_15: "Erreur; Votre abonnement a plus d'un an avant son expiration. Veuillez contacter notre support.",
    err_16:
      "Avertissement!!! Une transaction en attente existe. Vous devez annuler votre commande actuelle avant d'en passer une nouvelle. Voulez-vous l'annuler ?",
    err_17: ({ named }) =>
      `Erreur; Désolé, l'espace disque de ${named(
        "value"
      )} Mo est plein. Libérez de l'espace avant de télécharger un nouveau fichier.`,
    err_18: "Erreur; Ce code a déjà été utilisé. Veuillez essayer un autre code.",
    err_19: "Erreur; Code invalide. Veuillez entrer un code valide.",
    err_20: "Erreur; Ce code a expiré. Veuillez obtenir un nouveau code.",
    err_21: "Erreur; Impossible de passer à un sous-abonnement.",
    err_22: "Erreur; Code invalide. Veuillez entrer un code valide.",
    err_23: "Erreur; Clé invalide. Veuillez entrer une clé valide.",
    err_24: "Erreur; Aucun utilisateur enregistré avec cette adresse e-mail. Veuillez utiliser une adresse valide.",
    err_25: "Erreur; Le nom du fichier ne peut pas être vide.",
    err_26: "Erreur; Vous avez déjà utilisé ce nom de fichier. Veuillez choisir un autre nom.",
    err_100: "Erreur; Le champ e-mail est obligatoire.",
    err_101: "Erreur; Le champ e-mail doit être de type texte.",
    err_102: "Erreur; Veuillez entrer une adresse e-mail valide.",
    err_103: "Erreur; Le champ e-mail ne doit pas dépasser 255 caractères.",
    err_104: "Erreur; Cette adresse e-mail est déjà enregistrée.",
    err_105: "Erreur; L'adresse e-mail n'est pas acceptée. Veuillez entrer un domaine e-mail autorisé.",
    err_106: "Erreur; Le champ nom est obligatoire.",
    err_107: "Erreur; Le champ nom doit être de type texte.",
    err_108: "Erreur; Le champ nom ne doit pas dépasser 255 caractères.",
    err_109: "Erreur; Le champ mot de passe est obligatoire.",
    err_110: "Erreur; Le mot de passe doit contenir au moins 6 caractères.",
    err_111: "Erreur; Le mot de passe doit contenir au moins une lettre.",
    err_112: "Erreur; Le mot de passe doit contenir au moins un chiffre.",
    err_113: "Erreur; Le champ pays est obligatoire.",
    err_114: "Erreur; Pays invalide sélectionné.",
    err_115: "Erreur; Le champ organisation est obligatoire.",
    err_116: "Erreur; Vous devez accepter les conditions d'utilisation.",
    err_404: "Erreur; Contenu introuvable. Veuillez entrer un nom de page ou de contenu valide.",
    err_500:
      "Erreur; Désolé, une erreur inconnue est survenue. Veuillez actualiser la page et réessayer. Si l'erreur persiste, contactez-nous.",
    err_1001:
      "Erreur; Désolé, vous n'avez pas l'autorisation d'effectuer cette action. Veuillez contacter un administrateur.",
    err_1002: "Erreur; Désolé, votre abonnement a expiré. Veuillez acheter un nouveau forfait.",
    err_1003:
      "Erreur; Désolé, vous avez atteint la limite maximale de votre forfait. Impossible d'ajouter plus de contenu.",
    err_1004: "Erreur; Vous ne possédez pas ce type de QR code. Passez à un forfait incluant ce type.",
    err_1005: "Erreur; Vous avez dépassé la limite maximale de 100. Impossible d'ajouter davantage.",
    err_1006: "Erreur; La vérification des liens a échoué. Veuillez utiliser des informations de connexion valides.",
    err_1007: ({ named }) =>
      `Erreur: Vous avez dépassé votre limite de ${named("value")}, vous ne pouvez pas générer de code QR statique.`,
    err_1008: ({ named }) =>
      `Erreur; Votre forfait étant expiré, vous pouvez créer au maximum ${named("value")} dossiers.`,
  },
  PAYMENT: {
    unknown_reason: "Raison inconnue",
    payment: "Paiement",
    successful: "RÉUSSI",
    failed: "ÉCHOUÉ",
    awaiting: "EN ATTENTE",
    reason: "Raison",
    payment_successful_desc: `Cher Client,
    Votre commande a été reçue avec succès et votre forfait a été crédité sur votre compte.    
    Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter. Nous serons heureux de vous aider.
    Merci pour votre intérêt et votre confiance !`,
    payment_failed_desc: `Cher Client,
    Nous sommes désolés, nous avons détecté une erreur dans votre paiement et vous pouvez nous contacter pour obtenir de l'aide.
    Nous serons heureux de vous assister.`,
    payment_awaiting_desc: `Cher Client,
    Merci pour votre commande ! La dernière étape pour traiter votre commande est de finaliser votre paiement. 
    Cependant, notre système indique que votre paiement n'a pas encore été complété.
    Veuillez effectuer votre paiement sur nos comptes bancaires pour finaliser la transaction.`,
    title_success: () => `<strong>Merci</strong>, Votre commande a été reçue !`,
    title_fail: () => `<strong>Désolé</strong>, Le paiement a échoué !`,
    title_awaiting: () => `<strong>Merci</strong>, Votre commande a été reçue !`,
    err_105: "Le paiement a été annulé par l'utilisateur",
    err_000: "La transaction de paiement a échoué",
  },

  SNACKBAR: {
    qrcode_updated: "QR code mis à jour !",
    qrcode_created: "QR code créé !",
    verify_email: "Votre e-mail doit être vérifié",
    login_successful: "Connexion réussie",
    logged_out: "Déconnexion réussie !",
    registeration_successful: "Inscription réussie",
  },

  DIALOG: {
    STEP_BY_STEP_TOUR_TITLE: "Bienvenue dans la visite guidée",
    STEP_BY_STEP_TOUR_DESC:
      "Bonjour utilisateur ! Nous avons remarqué que c'est votre première visite ici. Cette visite vous guidera à travers les différentes sections et expliquera leur fonctionnement. Si vous souhaitez ignorer, cliquez simplement sur le bouton ci-dessous.",
    STEP_BY_STEP_TOUR_SKIP: "Passer",
    STEP_BY_STEP_TOUR_START: "Démarrer",
    STEP_BY_STEP_TOUR_CREATE_QR: "Créer un QR Code",
    STEP_BY_STEP_TOUR_GET_STARTED_CREATE_QR_CODE: "Utilisez le bouton ci-dessus pour créer un QR code.",
  },
  POPUP: {
    error_title: "Erreur !",
    CHOOSE_ONE: "Choisissez un type de QR Code",
    TRANSPARENT_QR_CODE: "QR Code Transparent",
    STANDART_QR_CODE: "QR Code Standard",
    BANNER_QR_CODE: "QR Code Bannière",
    GO_BACK: "Retour",
    QR_CODE_BACKGROUND_PICTURE: "Je veux pouvoir mettre l'image d'arrière-plan que je veux sur mon QR code",
    QR_CODE_COLOR: "Je veux personnaliser la forme et les couleurs de mon QR Code",
    QR_CODE_BANNER: "Je veux créer une bannière et personnaliser la taille du QR Code",
    upgradeContent: ({ named }) =>
      `<div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <img width="200px" src="https://vue.qrcodematic.com/backgroundImages/popup01/istockphoto-1176277785-612x612_preview-1686842223.jpg">
      <h1 class="text-h5"><span style="font-weight: 900;" class="button_green--text text-h4">Plus</span> <span style="font-weight: 200; color:#919191">pour créer</span> <strong class="text-h4 button_green--text">Mettre à niveau</strong></h1>
      <p style="font-size: 1rem; max-width: 360px; text-align: justify;">Vous avez atteint la limite de ${named(
        "total"
      )} codes QR de votre forfait</strong>. Pour créer plus de codes QR, consultez nos forfaits premium</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Mettre à niveau <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>`,
    packageController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Votre abonnement a <strong style="color: tomato">expiré!</strong></h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Abonnement</strong> Achetez et continuez à utiliser QR Code Matic.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Mettre à niveau <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    upgradeToPro: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Cette fonctionnalité est réservée aux utilisateurs <strong style="color: tomato">PRO</strong></h1>
      <p style="width: 300px; text-align: justify"><strong class="button_green--text"><i class="fa-solid fa-rectangle-pro fa-lg"></i> Abonnement</strong> Achetez et continuez à utiliser QR Code Matic.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Mettre à niveau <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    allowedTypeController: ({ named }) => `
    <div class="illustrated-bg d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-h5">Pour continuer à utiliser <strong class="orange--text">${named(
        "typeName"
      )}</strong>, mettez à niveau</h1>
      <p>Votre forfait actuel ne couvre pas ce type de code QR. En passant au plan Pro, vous pourrez utiliser ce code QR et d'autres codes QR premium sans limite.</p>
      <button id="alert-callback" style="width: 180px; border-radius: 5px; background: var(--v-button_green-base)" class="mt-5 pa-2 white--text d-flex justify-content-center align-items-center">Mettre à niveau <i class="fa-solid fa-rectangle-pro ml-2"></i></button>
    </div>
    `,
    getMoreInformation: ({ named }) => `
    <div>
     <p>Le paramètre de langue vous permet d'entrer des informations différentes pour chaque langue que vous choisissez. L'utilisateur final du code QR aura un menu de langue dans le coin supérieur droit de la version live s'il ajoute plusieurs langues.</p>
     <p>L'utilisation est la suivante :</p>
     <ol>
      <li>Cliquez d'abord sur le bouton <span class="font-weight-bold">"+"</span></li>
      <li>Sélectionnez les langues que vous souhaitez ajouter. Vous pouvez sélectionner plusieurs langues. Ensuite, sélectionnez une langue pour copier le contenu. (Cette option peut ne pas être disponible pour ce type de QR code.) Cliquez ensuite sur <span class="font-weight-bold">"OK"</span></li>
      <li>Maintenant, vous pouvez cliquer sur les nouveaux symboles de drapeau de langue ajoutés et cliquer sur le bouton <span class="font-weight-bold">"Actif"</span>. En faisant cela, vous pouvez modifier les informations de contenu pour cette langue spécifique.</li>
      <li>Pour supprimer toute langue ajoutée, cliquez sur le bouton <span class="font-weight-bold">"Supprimer"</span>. N'oubliez pas que vous devez avoir au moins une langue.</li>
      <li>En cliquant sur le bouton <span class="font-weight-bold">"Définir comme par défaut"</span>, vous enregistrez cette langue comme langue par défaut et les utilisateurs verront cette langue lorsqu'ils visiteront votre page.</li>
     </ol>
    </div>
    `,
    getInfoAboutTitle: ({ named }) => `
    <div>
   <p class="text--justify" style="font-size: 14px">Votre titre de code QR apparaîtra sous l'image dans la liste des codes QR comme indiqué ci-dessous. Vous serez redirigé vers cette page une fois que vous aurez terminé la création de votre code QR.<br>
   Vous pouvez modifier le titre du code QR plus tard
   </p>
   <div class="d-flex justify-content-center" style="width: 100%;">
      <img src="/media/tutorials/qrcode-title.png" style="max-width: 400px; object-fit: cover; border-radius: 5px; "/>
   </div>
  </div>
    `,
  },
  BULK: {
    create_new_child: "Je veux créer de nouveaux sous QR Codes",
    update_childs_data: "Je veux modifier les informations détaillées de mes sous QR Codes",
    update_data: "Je veux mettre à jour les informations générales de tous mes sous QR Codes",
    zip_request: "Je veux télécharger tous mes QR Codes au format SVG",
    zip_downloaded: "Le fichier zip a été téléchargé !",
    download_master: "Télécharger le fichier Excel maître",
    update_config_button_text:
      "Je veux mettre à jour la couleur, le design et d'autres paramètres de tous mes sous QR Codes.",
    update_data_description: () => `<p style="text-align: center">
    Sélectionnez les champs que vous souhaitez mettre à jour pour les QR Codes sous le maître dans les cases ci-dessous.
    Tous les QR Codes sous le maître seront automatiquement mis à jour avec les paramètres du fichier Excel sélectionné (vcard-bulk-update).
  </p>
  <p style="text-align: center">
    Par exemple, si vous sélectionnez <strong>la case avatar</strong> et cliquez sur le bouton <strong>Mettre à jour</strong>,
    la valeur d'avatar que vous avez saisie dans l'Excel (vcard-bulk-update) sera appliquée à tous les QR Codes sous le maître,
    et la mise à jour sera planifiée.
  </p>`,
    update_config_description: () => `<p>
  Cette action applique en une seule fois les modifications effectuées sur le <strong>maître</strong> aux sous QR Codes.
  Toutes les fonctionnalités indiquées dans la liste du maître seront appliquées aux sous QR Codes.
  <ul>
    <li>Tous les paramètres de couleur des QR Codes</li>
    <li>Tous les designs des QR Codes</li>
  </ul>
</p>
<p>
  Si vous effectuez la mise à jour, tous les sous QR Codes auront le même design que le maître.
</p>`,
    create_description:
      () => `Pour créer des QR Codes en masse, glissez votre fichier Excel modifié dans la zone de téléchargement ci-dessous
et cliquez sur le bouton <strong class="green--text">TÉLÉCHARGER</strong>.`,
    update_options_title: "Options de mise à jour",
    avatar: "Avatar",
    are_you_sure: "Êtes-vous sûr ?",
    see_qr_codes: "Voir vos QR Codes",
    see_qr_codes_tooltip: "Vous pouvez voir les QR Codes sous-jacents et les transformer en maître à nouveau.",
    background: "Arrière-plan",
    settings: "Paramètres",
    advanced_settings: "Paramètres avancés",
    update_data_button_text: "Mettre à jour",
    update_config_button_text: "Mettre à jour le design et les couleurs des sous QR Codes",
    show_previously_uploaded: "Afficher mes téléchargements Excel précédents",
    reached_upload_limit:
      "Votre limite de téléchargement est atteinte. Vous devez d'abord supprimer un ou plusieurs fichiers de la liste.",
    upload_error_text:
      "Les données saisies dans le fichier Excel téléchargé ne sont pas dans le bon format. Veuillez vérifier les champs erronés ci-dessous et réessayer de télécharger.",
    back_to_upload: "Retour au téléchargement",
    select_from_list: "Sélectionnez l'action que vous souhaitez effectuer ci-dessous",
  },
  NEWS: {
    firstVisitMessage: ({ named }) => `
    <p> Aujourd'hui, nous vous apportons une nouvelle excitante. Nous avons renouvelé notre site QR Code ! Nous avons apporté une série d'améliorations pour vous offrir une meilleure expérience.</p>
    <p>Les QR Codes sont devenus un outil indispensable pour un partage d'informations rapide et facile. Nous avons donc développé cette technologie pour vous offrir le meilleur service possible.</p>
    `,
    firstVisitFullMessage: ({ named }) => `
    <p> Aujourd'hui, nous vous apportons une nouvelle excitante. Nous avons renouvelé notre site QR Code ! Nous avons apporté une série d'améliorations pour vous offrir une meilleure expérience.</p>

    <p>Les QR Codes sont devenus un outil indispensable pour un partage d'informations rapide et facile. Nous avons donc développé cette technologie pour vous offrir le meilleur service possible.</p>

    <p> Notre nouveau site QR Code est conçu pour être convivial. Désormais, il faudra moins d'étapes pour se connecter et créer un QR Code.
    Nous avons simplifié l'interface utilisateur et éliminé les clics inutiles. Notre design a été repensé pour une expérience fluide, rapide et sans problème.</p>

    <p>De plus, nous avons ajouté de nouvelles fonctionnalités pour rendre nos QR Codes plus fonctionnels et flexibles. Vous pouvez désormais personnaliser vos QR Codes avec plus de données,
    ajouter des liens vers votre site Web ou vos profils de médias sociaux, créer des menus pour vos délicieux plats, ou encore générer une vCard contenant votre adresse e-mail ou votre numéro de téléphone.
    Nous offrons de nombreuses options pour étendre les utilisations des QR Codes.</p>

    <p>Votre sécurité est notre priorité dans ce large domaine d'utilisation. Sur notre nouveau site QR Code, nous avons pris des mesures de sécurité supplémentaires pour protéger vos données et vos informations.
    Nous avons mis en place une meilleure infrastructure pour assurer la protection de vos informations personnelles et sensibles.</p>

    <p> La vitesse de nos QR Codes a également été améliorée. Grâce à un algorithme optimisé et des systèmes améliorés, vous pourrez scanner vos QR Codes plus rapidement et finaliser vos actions en moins de temps.
        Nous avons mis à jour notre infrastructure technologique pour vous permettre d'utiliser votre temps plus efficacement.</p>

    <p> Vos retours et besoins sont toujours importants pour nous. C'est pourquoi nous travaillons en permanence à améliorer notre site QR Code renouvelé pour vous offrir une meilleure expérience.
        Si vous avez des questions, suggestions ou retours concernant l'utilisation du site, n'hésitez pas à nous en faire part. Votre satisfaction est notre succès. Bienvenue !</p>
    `,

    HELLO: "Bonjour, nous avons été renouvelés !",
    we_are_improving_to_provide_you_with_better_service: "Nous nous améliorons pour vous offrir un meilleur service",
  },
  TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>Conditions d'utilisation du site QR Code Matic</h1>
      <hr>
      <p>Les clients qui utilisent ce site de création de QR Code et génèrent des QR Codes sont réputés accepter les conditions suivantes. De plus, toutes les pages Web et les pages associées du site (‘Site’) sont la propriété de Medyax Bilişim Hizmetleri, située à FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa, et sont exploitées par cette dernière.</p>
      <p>En utilisant les services proposés sur le site, vous acceptez d'être soumis aux conditions suivantes ; vous déclarez avoir le droit, l'autorité et la capacité juridique de conclure un contrat en vertu des lois applicables et d'avoir plus de 18 ans, et vous acceptez être lié par ces conditions.</p>
      <h2>1. RESPONSABILITÉS</h2>
        <p> a. L'entreprise se réserve le droit de modifier les prix et les services proposés à tout moment.</p>
        <p> b. L'entreprise garantit que l'utilisateur pourra bénéficier des services proposés, sauf en cas de problèmes techniques.</p>
        <p> c. L'utilisateur s'engage à ne pas effectuer de rétro-ingénierie sur le site, ni à tenter d'obtenir son code source. En cas de violation, il sera responsable des dommages et fera l'objet de poursuites judiciaires.</p>
        <p> d. L'utilisateur s'engage à ne pas produire ou partager du contenu contraire à l'éthique, à la loi, trompeur, offensant, obscène, diffamatoire, violant les droits d'autrui ou encourageant des activités illégales. L'entreprise se réserve le droit de suspendre ou de résilier ces comptes et d'engager des poursuites judiciaires.</p>
        <p> e. Les relations entre les membres du site et les tiers relèvent de leur propre responsabilité.</p>
      <h2>2. DROITS DE PROPRIÉTÉ INTELLECTUELLE</h2>
    <p> Toutes les marques, brevets, conceptions, informations et méthodes présentes sur le site appartiennent à l'exploitant du site ou à leurs propriétaires respectifs et sont protégés par la loi.</p>
    <p> Aucune information du site ne peut être reproduite, publiée, copiée ou transférée sans autorisation.</p>

    <h2>3. INFORMATIONS CONFIDENTIELLES</h2>
    <p> L'entreprise ne divulguera pas les informations personnelles des utilisateurs à des tiers sans leur consentement.</p>

  <h2>4. ABSENCE DE GARANTIE</h2>
    <p> Les services sont fournis "tels quels", sans aucune garantie expresse ou implicite.</p>

  <h2>5. ENREGISTREMENT ET SÉCURITÉ</h2>
    <p> L'utilisateur est responsable de fournir des informations exactes et de protéger ses identifiants et mots de passe.</p>

  <h2>6. FORCE MAJEURE</h2>
    <p> En cas d'événements hors du contrôle des parties, les obligations sont suspendues.</p>

  <h2>7. INTÉGRALITÉ DU CONTRAT</h2>
    <p> Si une disposition du contrat devient invalide, le reste demeure en vigueur.</p>
  `,
  },
  TERMS: {
    terms_text: ({ named }) =>
      `
      <h1>Conditions d'utilisation du site QR Code Matic</h1>
      <hr>
      <p>Les clients qui utilisent ce site de création de QR Code et qui créent des QR Codes sont réputés accepter les conditions suivantes. De plus, les pages Web de notre site et toutes les pages qui lui sont associées (« Site ») appartiennent à la société Medyax Bilişim Hizmetleri située à FSM Bulvarı Fethiye Mah. Fazıl Sk. No:8 D:2 Nilüfer Bursa et sont exploitées par elle. En tant qu’« Utilisateur », en utilisant tous les services proposés sur le site, vous acceptez d’être soumis aux conditions suivantes, de bénéficier des services du site et de continuer à l’utiliser ; vous déclarez avoir le droit, l'autorité et la capacité juridique de conclure un contrat conformément aux lois auxquelles vous êtes soumis et être âgé de plus de 18 ans ; vous avez lu, compris et acceptez d’être lié par les conditions de ce contrat. Le présent contrat impose aux parties des droits et obligations liés au site objet du contrat, et en acceptant ce contrat, les parties déclarent qu'elles rempliront ces droits et obligations de manière complète, correcte, en temps voulu et dans les conditions requises par le présent contrat.</p>

      <h2>1. Responsabilités</h2>
      <p> a. L’entreprise se réserve le droit de modifier à tout moment les prix et les produits et services proposés.</p>
      <p> b. L’entreprise s'engage à fournir les services convenus, sauf en cas de panne technique.</p>
      <p> c. L’utilisateur s'engage à ne pas pratiquer d'ingénierie inverse sur le site ni à tenter d'obtenir le code source par quelque moyen que ce soit. Toute violation entraînera des poursuites judiciaires et pénales.</p>
      <p> d. L'utilisateur s'engage à ne pas publier ni partager de contenus contraires à la morale, aux lois en vigueur, portant atteinte aux droits des tiers ou incitant à des activités illégales. Toute infraction entraînera la suspension ou la suppression du compte par l’entreprise.</p>
      <p> e. Les interactions entre les utilisateurs et les tiers sont sous leur propre responsabilité.</p>

      <h2>2. Droits de propriété intellectuelle</h2>
      <p> 2.1. Tous les droits de propriété intellectuelle du site (noms commerciaux, marques, brevets, conceptions, codes systèmes, méthodes) appartiennent à l’entreprise ou aux titulaires désignés et sont protégés par les lois nationales et internationales.</p>
      <p> 2.2. Les informations du site ne peuvent être copiées, reproduites, publiées ou transférées sans autorisation.</p>

      <h2>3. Informations confidentielles</h2>
      <p> 3.1. L’entreprise ne divulguera pas les informations personnelles des utilisateurs à des tiers sans consentement.</p>
      <p> 3.2. L'utilisateur accepte que certaines informations puissent être utilisées à des fins de marketing et partagées avec les sociétés affiliées.</p>
      <p> 3.3. Les informations personnelles peuvent être divulguées aux autorités compétentes en cas d’obligation légale.</p>

      <h2>4. Absence de garantie</h2>
      <p> Les services sont fournis "en l’état" et sans garantie implicite ou explicite quant à leur adéquation à un usage particulier.</p>

      <h2>5. Enregistrement et sécurité</h2>
      <p> L'utilisateur doit fournir des informations correctes et à jour. Toute violation entraînera la suppression du compte. L’utilisateur est responsable de la sécurité de son compte et de ses mots de passe.</p>

      <h2>6. Force majeure</h2>
      <p> En cas d’événements imprévus indépendants de la volonté des parties (catastrophes naturelles, guerres, grèves, pannes de réseau), les obligations contractuelles seront suspendues sans responsabilité des parties.</p>

      <h2>7. Intégralité et applicabilité du contrat</h2>
      <p> Si une clause du présent contrat devient invalide, le reste du contrat reste en vigueur.</p>

      <h2>8. Modifications du contrat</h2>
      <p> L’entreprise peut modifier les services et les conditions du contrat à tout moment. Ces modifications seront effectives dès leur publication sur le site.</p>

      <h2>9. Notifications</h2>
      <p> Toute notification relative au contrat sera envoyée aux adresses e-mail fournies par les parties.</p>

      <h2>10. Convention de preuve</h2>
      <p> En cas de litige, les enregistrements informatiques et documents de l’entreprise feront foi conformément au Code de procédure civile turc.</p>

      <h2>11. Règlement des litiges</h2>
      <p> Tout litige lié au contrat sera soumis aux tribunaux et aux bureaux d'exécution de Bursa (Centre).</p>

      <h2>12. Tarification</h2>
      <p> Lors de votre première inscription au système, un Pack d'Essai est attribué à votre compte afin que vous puissiez le tester. La durée de validité du Pack d'Essai et des cartes de visite numériques que vous créerez pendant cette période est de 5 jours et peut être exportée au format .png. Lors de la création d’un QR Code, vous pouvez choisir son type dans l’option statique/dynamique qui vous est proposée sur la page finale. La sélection du type et de la catégorie du QR Code relève de la responsabilité de l'utilisateur. Les sorties vectorielles ne sont disponibles que pour nos packs professionnels. QR Code Matic se réserve le droit de modifier les durées des Packs d'Essai. Il peut les modifier à tout moment. À la fin des 5 jours, vous pouvez continuer à utiliser les QR Codes que vous avez créés en sélectionnant l'un des packs payants. Si vous ne mettez pas à niveau votre pack avant l'expiration, les QR Codes que vous avez créés seront supprimés. Si vous ne souhaitez pas que vos QR Codes soient perdus, supprimés ou désactivés, vous devez renouveler votre pack au moins un mois avant son expiration. En cas de non-renouvellement, les QR Codes que vous avez créés seront supprimés et QR Code Matic ne sera en aucun cas responsable de cette suppression. N'oubliez pas que vos QR Codes et vos cartes de visite numériques restent actifs tant que votre abonnement est en cours.</p>

      <h2>13. Suppression de compte</h2>
      <p> Vous pouvez supprimer votre compte en vous connectant et en accédant à la section Mon Compte dans le menu supérieur, puis en sélectionnant Oui dans l’option Supprimer Mon Compte et en cliquant sur Enregistrer. Vous pouvez également demander la suppression de votre compte en envoyant un e-mail à info@qrcodematic.com depuis l'adresse e-mail avec laquelle vous vous êtes inscrit, en expliquant la raison de votre demande. Veuillez noter qu’une fois votre compte supprimé, tout le contenu et tous les QR Codes que vous avez créés seront définitivement perdus et ne pourront pas être récupérés. En confirmant la suppression du compte, vous acceptez toutes ces conditions. Avant d'envoyer vos QR Codes à l'impression, testez-les sur plusieurs appareils pour vous assurer qu'ils fonctionnent. Ne procédez pas à l'impression sans les tester. En vous connectant au site et en créant un compte, vous êtes réputé avoir lu, compris et accepté toutes ces conditions.</p>
      `,
  },
  CROPPER: {
    confirm_selected_area: "Appliquer l'image",
    confirm_selection_tooltip: "Confirme la zone sélectionnée",
    cropper_success: "Appliqué avec succès",
  },
  STATISTICS: {
    show_all: "Afficher tout",
    last_x_days: ({ named }) => `Derniers ${named("days")} jours`,
    trial_warning:
      "<strong>DEMO : </strong> Les statistiques ci-dessous sont affichées à titre d'exemple pour le <strong>compte d'essai</strong>.",
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Envoyer",
      NO_ACCOUNT: "Pas de compte ?",
      SIGNUP_BUTTON: "S'inscrire",
      FORGOT_BUTTON: "Mot de passe oublié ?",
      BACK_BUTTON: "Retour",
      PRIVACY: "Confidentialité",
      LEGAL: "Légal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Compte de connexion",
      BUTTON: "S'inscrire",
    },
    FORGOT: {
      TITLE: "Mot de passe oublié ?",
      DESC: "Entrez votre e-mail pour réinitialiser votre mot de passe",
      SUCCESS: "Votre compte a été réinitialisé avec succès.",
    },
    REGISTER: {
      TITLE: "S'inscrire",
      DESC: "Entrez vos informations pour créer votre compte",
      SUCCESS: "Votre compte a été enregistré avec succès.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Nom et prénom",
      PASSWORD: "Mot de passe",
      CONFIRM_PASSWORD: "Confirmer le mot de passe",
      USERNAME: "Nom d'utilisateur",
    },
    VALIDATION: {
      INVALID: "{{name}} n'est pas valide",
      REQUIRED: "{{name}} est requis",
      MIN_LENGTH: "La longueur minimale de {{name}} est de {{min}}",
      AGREEMENT_REQUIRED: "L'acceptation des termes et conditions est requise",
      NOT_FOUND: "{{name}} demandé non trouvé",
      INVALID_LOGIN: "Les informations de connexion sont incorrectes",
      REQUIRED_FIELD: "Champ requis",
      MIN_LENGTH_FIELD: "Longueur minimale du champ :",
      MAX_LENGTH_FIELD: "Longueur maximale du champ :",
      INVALID_FIELD: "Champ invalide",
    },
  },
  ICONSELECT: {
    animals: "animal",
    landscape: "paysage",
    people: "personne",
    vehicles: "véhicule",
    communication: "communication",
    technology: "technologie",
    business: "catalogue / menu",
    food: "nourriture",
  },
};
